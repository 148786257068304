import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { axiosCacheInstance } from "../../../axiosSetup";
import { RootState } from "../../store";
import { toast } from "react-toastify";
import { response } from "msw";

export const getCompanyDetails = createAsyncThunk(
    'mappingDashboard/getCompanyDetails',
    async (payload:{custId:any})=>{
        try{
            const data =  (await axiosInstance.get(
                `/api/user-management/v1/customer/getCompanyDetailsById?mstPartnerId=${payload.custId}`
            )).data[0]
            return {
                companyName: data.customerName,
                phoneNo:data.phnNo,
                taxId:data.taxId,
                address:data.address,
                country:data.country
            }
        }catch{
            return {
                companyName: '',
                phoneNo:'',
                taxId:'',
                address:''
            }
        }

})


export const getUserDataList = createAsyncThunk(
    'mappingDashboard/getUserDataList',
    async (payload:{custId:any})=>{
        try{
            const response = await axiosInstance.get(
                `/api/user-management/v1/customer/getUserByMstPartnerId?mstPartnerId=${payload.custId}`
            )

            // merging duplicate Ids into a single entry
            const data: any[] = []
            response.data.forEach((i:any) => {
                const row = data.find(x=>x.id == i.mstUsrId)
                if(!row)data.push({
                    id: i.mstUsrId,
                    email: i.email,
                    name: i.userName,
                    group: [i.mstGrpId],
                    status: i.status==1,
                    grpType: [i.grpType],
                    isRootUser: i.isRootUser,
                    title: i.title,
                    userGrpName: [i.userGrpName],
                    mstpartnerId: i.mstpartnerId,
                    phnNo: i.phnNo,
                    country: i.country
                })
                else{
                    row?.group?.push(i.mstGrpId)
                    row?.userGrpName?.push(i.userGrpName)
                    row?.grpType?.push(i.grpType)
                }
            });
            return data
        }catch{
            return []
        }
})

export const getCustomerCodeDataList = createAsyncThunk(
    'mappingDashboard/getCustomerCodeDataList',
    async (payload:{custId:any})=>{
        try{
            const data =  (await axiosInstance.get(
                `/api/user-management/v1/customer/getCustomerCodeByPartnerId?mstPartnerId=${payload.custId}`
            )).data
            return data.map((i:any)=>({
                id: i?.customerMapId,
                liner: i?.linerId,
                customerCode: i?.mapCode,
                matchCode: i?.internalCode,
            }))
        }catch{
            return []
        }
    })

export const getCodeSearchListData = createAsyncThunk(
    'mappingDashboard/getCodeSearchListData',
    async (payload:{liner:any,code:any})=>{

        try{
            const data = (await axiosInstance.get(
                `/api/user-management/v1/customer/findCustomerMapping?linerId=${payload.liner}&code=${payload.code}`
            )).data
            
            return data.map((i:any)=>({
                id: i.customerMapId,
                liner:i.linerId,
                customerCode: i.customerCode,
                matchCode: i.matchCode,
                name: i.customerName,
                address: i.address,
                taxId: i.taxId,
                country: i.country,
                selected: false
            }))

        }catch{
            return []
        }
    }
)

export const getMatchCodeListData = createAsyncThunk(
    'mappingDashboard/getMatchCodeListData',
    async (payload:any,{getState}:any)=>{
        const state:RootState = getState()
        try{
            const data = (await axiosInstance.post(
                `/api/user-management/v1/customer/getUserByMatchCode?mstPartnerId=${payload.custId}`,
                {
                    matchCode: state.mappingDashboard.codeSearchListData?.filter(i=>i.selected == true).map(i=>i.matchCode)      
                }
            )).data
            return data.map((d:any)=>({
                matchCode: d.matchCode,
                customerCode: d.customerCode,
                email: d.email,
                name: d.partnerNm,
            }))
        }catch{
            return []
        }
    }
)

export const getGroupTypes = createAsyncThunk('mappingDashboard/getGroupTypes',
    async (country:any, {getState}:any) => {
        const state:RootState = getState()
        //  debugger
        try {
          const response = await axiosCacheInstance({
            url: `/api/user-management/v1/users/getGroupList?countryCode=${country}`,
            method: 'POST',
            cache:{
                ttl: 10 * 1000 * 60
            }
          });
          return response.data.map((i:any)=>({value:i.mstGrpId,label:i.usrGrpNm}));
        } catch (e) {    
          return [];
        }
      
})

export const mergeMatchCodesToUser = createAsyncThunk('mappingDashboard/mergeMatchCodesToUser',
    async (payload:any, {getState}:any)=>{
        const state:RootState = getState()
        try{
            const response = await axiosInstance.post(
                `/api/user-management/v1/customer/getMatchCodeToMerge?mstPartnerId=${payload.custId}`,
                {
                    matchCode: state.mappingDashboard.codeSearchListData?.filter(i=>i.selected == true).map(i=>i.matchCode)      
                }
            )
            toast.success("Codes mergerd Successfully!")
        }catch{
            console.error('Error: mergeMatchCodesToUser in MappingActions.ts')
            toast.error("Codes merge Failed!")
        }

    }
)


export const saveUserDetails = createAsyncThunk('mappingDashboard/saveEditUserDetails',
    async (payload:any, {getState}:any)=>{
        const state:RootState = getState()
        try{
            const response:any = await axiosInstance.post(
                `/api/user-management/v1/users/saveUserDetails`,
                {
                    mstPartnerId: +payload.custId,
                    mstUsrId: state.mappingDashboard.userEditingState.id,
                    loginId: state.mappingDashboard.userEditingState.email,
                    usrNm: state.mappingDashboard.userEditingState.name,
                    contactNo: state.mappingDashboard.userEditingState.phnNo,
                    title: state.mappingDashboard.userEditingState.title,
                    contactPerson: null,
                    mstGrp: state.mappingDashboard.userEditingState.group.map((g: any, i) => {
                      return {
                        mstGrpId: g,
                        usrGrpNm: state.mappingDashboard.userEditingState.userGrpName[i],
                      };
                    }),
                    status: state.mappingDashboard.userEditingState.status?"1":"2",
                    countryShortCode: state.mappingDashboard.userEditingState.country,
                    isRootUser: state.mappingDashboard.userEditingState.isRootUser,
                    partnerNm: state.mappingDashboard.companyDetails.companyName,
                    //secretKey: (password && password !== '')?password:null
                  }
            )
            if(response.data.responseCode != 'SUCCESS')
            {
                toast.error(response.data.responseMsg)
            }
            else{
                toast.success(response.data.responseMsg)
            }
        }catch(e:any){
        }

    }
)


export const saveEditCompanyDetails = createAsyncThunk('mappingDashboard/saveEditCompanyDetails',
    async (payload:any, {getState}:any)=>{
        const state:RootState = getState()
        try{
            const response = await axiosInstance.post(
                `/api/user-management/v1/customer/editCustomerDetails`,
            {
                mstPartnerId: payload.custId,
                mstUsrId: 1,
                companyName: state.mappingDashboard.companyDetails.companyName,
                phoneNumber: state.mappingDashboard.companyDetails.phoneNo,
                taxId: state.mappingDashboard.companyDetails.taxId,
                address: state.mappingDashboard.companyDetails.address
            }
            )
            toast.success("Customer Details updated successfully!")
        }catch{
            toast.error("Failed to update Customer Details!")
        }

    }
)
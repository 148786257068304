import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { matchPath, Outlet, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { updateBlDetailsRowColumns } from './slices/blDetailsTable';
import { toggleDocumentHistoryColumnShort } from './slices/documentHistorySlice';
import Header from '../../common/components/DetailsViewHeader';
import DocumentHistory from './DocumentHistory';
import OblDetails from '../../common/components/OblDetails';
import MainTable from '../dashboard/components/MainTable';
import queryString from 'query-string';

import dataTableBgImg from '../../../../src/assets/img/illustrations/cornerRight-1.png';
import dataTableBgImgFoBlDetails from '../../../../src/assets/img/illustrations/corner-1.png';
import OdexSpinner from '../../common/components/OdexSpinner';
import { updateDashbaordName, updateSearchInputValueNT } from '../../common/slices/navbarTop';

const BlDetails = () => {
  const location = useLocation();
  const { blNum } = useParams();
  const dispatch = useDispatch();
  const [instructionStatusNew, setInstructionStatusNew] = useState('');
  const paymentStatus = useSelector(
    (state: RootState) => state.dashboard.showPayment
  );
  const releaseStatus = useSelector(
    (state: RootState) => state.dashboard.showRelease
  );
  const instructionStatus = useSelector(
    (state: RootState) => state.dashboard.showInstruction
  );

  useEffect(() => {
    if (instructionStatus !== null) {
      setInstructionStatusNew(instructionStatus.trim());
    }
  }, [instructionStatus]);

  const rowColumns = useSelector(
    (state: RootState) => state.blDetailsTable.rowColumns
  );

  var path = location.pathname;
  var parts = path.split('/');
  var blslider = parts[1];
  
  useEffect(() => {
    dispatch(updateDashbaordName('BL_DASHBOARD'));
    dispatch(updateSearchInputValueNT(''))
  }, []);

  useEffect(() => {
    if (matchPath('/bl/:blNum/unpaid/payment', location.pathname)){
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'd-none',
          documentMainViewClass: 'col-lg-12',
          documentHistoryClass: 'col-lg-6',
          outletClass: 'col-lg-6',
        })
      )
    } else if (matchPath('/bl/:blNum/unpaid', location.pathname)){
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'd-none',
          documentMainViewClass: 'col-lg-12',
          documentHistoryClass: 'col-lg-6',
          outletClass: 'col-lg-6',
        })
      )
    } else if (matchPath('/home', location.pathname)) {
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'col-lg-12',
          documentMainViewClass: 'd-none',
          documentHistoryClass: 'd-none',
          outletClass: 'd-none',
        })
      );
    } else if (
      matchPath('/bl/:blNum', location.pathname) ||
      matchPath('/multiBl/:blNum/instruction/805', location.pathname) ||
      matchPath('/multiBl/:blNum/instruction/814', location.pathname)
    ) {
      setTimeout(() => {
        dispatch(
          updateBlDetailsRowColumns({
            ...rowColumns,
            mainTableClass: 'col-lg-2',
            documentMainViewClass: 'col-lg-10',
            documentHistoryClass: 'col-lg-12',
            outletClass: 'd-none',
          })
        );
      }, 100);
    } else if (
      matchPath('/blslider/:blNum', location.pathname) ||
      matchPath('/blsliderinvoice/:blNum', location.pathname) ||
      matchPath('/blsliderinstruction/:blNum', location.pathname)
    ) {
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'col-lg-2',
          documentMainViewClass: 'col-lg-10',
          documentHistoryClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );
    } else if (
      matchPath('bl/:blNum/snooze/:blEventId',location.pathname)
    ) {
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'd-none',
          documentMainViewClass: 'col-lg-12',
          documentHistoryClass: 'col-lg-6',
          outletClass: 'col-lg-6',
        })
      );
    }else if (
      matchPath('/bl/:blNum/:document', location.pathname) ||
      matchPath('/bl/:blNum/:document/:doc', location.pathname)
    ) {
      dispatch(toggleDocumentHistoryColumnShort(true)); //for history page short
      if (rowColumns.mainTableClass !== 'd-none') {
        dispatch(
          updateBlDetailsRowColumns({
            ...rowColumns,
            mainTableClass: 'col-lg-1',
            documentMainViewClass: 'col-lg-11',
            documentHistoryClass: 'col-lg-3',
            outletClass: 'd-none',
          })
        );
      }

      setTimeout(() => {
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'd-none',
          documentMainViewClass: 'col-lg-12',
          documentHistoryClass: 'col-lg-3',
          outletClass: 'd-none',
        });
      }, 1000);

      setTimeout(() => {
        dispatch(
          updateBlDetailsRowColumns({
            ...rowColumns,
            mainTableClass: 'd-none',
            documentMainViewClass: 'col-lg-12',
            documentHistoryClass: 'col-lg-3',
            outletClass: 'col-lg-9',
          })
        );
      }, 500);
    } else {
      dispatch(toggleDocumentHistoryColumnShort(false)); //revert back once close

      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'col-lg-2',
          documentMainViewClass: 'col-lg-10',
          documentHistoryClass: 'd-none',
          outletClass: 'd-none',
        })
      );

      setTimeout(() => {
        dispatch(
          updateBlDetailsRowColumns({
            ...rowColumns,
            mainTableClass: 'col-lg-2',
            documentMainViewClass: 'col-lg-10',
            documentHistoryClass: 'col-lg-12',
            outletClass: 'd-none',
          })
        );
      }, 1000);
    }
  }, [location.pathname]);

  return (
    <Card className='w-100 h-100'>
      <div
        className='bg-holder bg-card'
        style={{
          backgroundImage: `url(${dataTableBgImg})`,
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
        }}
      />

      <Card.Body className='p-0'>
        <div className='row g-0 w-100 h-100'>
          <div className={`${rowColumns.mainTableClass}`}>
            <div className='d-flex flex-column justify-content-between align-items-start w-100 h-100'>
              <div className='d-flex flex-column justify-content-start align-items-start w-100 h-100'>
                {location.pathname !== '/home' && (
                  <Card.Header className='p-0 ps-2 pe-2 d-flex justify-content-start align-items-center odex-mh-60px' />
                )}

                <MainTable />
              </div>
            </div>
          </div>
          <div
            className={`/*  odex-bg-aliceblue  */ ${rowColumns.documentMainViewClass}`}
          >
            <Card className='overflow-auto' style={{ height: '90vh' }}>
              {rowColumns.outletClass === 'd-none' && (
                <div
                  className='bg-holder bg-card bg-card-blDetails'
                  style={{
                    backgroundImage: `url(${dataTableBgImgFoBlDetails})`,
                    borderTopRightRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem',
                  }}
                />
              )}

              <Card.Body className='p-0 d-flex flex-column'>
                {blslider == 'blslider' ? (
                  <OblDetails blslider={blslider} />
                ) : (
                  <>
                    {blslider != 'multiBl' && (
                      <Header
                        backNavRoute={
                          queryString.parse(window.location.search)?.goto ===
                          'release'
                            ? '/release-dashboard'
                            : '/home'
                        }
                        paymentStatus={paymentStatus}
                        instructionStatus={instructionStatusNew}
                        releaseStatus={releaseStatus}
                      />
                    )}
                  </>
                )}
                <div className='row g-0 flex-1 ' style={{ height: '30vh' }}>
                  {rowColumns.outletClass !== 'd-none' && (
                    <div
                      className='bg-holder bg-card bg-card-blDetails'
                      style={{
                        backgroundImage: `url(${dataTableBgImgFoBlDetails})`,
                        borderTopRightRadius: '0.375rem',
                        borderBottomRightRadius: '0.375rem',
                      }}
                    />
                  )}

                  <div
                    className={`${rowColumns.documentHistoryClass} h-100 zindex5`}
                  >
                    {blslider !== 'blslider' && blslider !== 'multiBl' && (
                      <DocumentHistory />
                    )}
                    {blslider === 'multiBl' && <Outlet />}
                  </div>
                  <Card
                    className={`${rowColumns.outletClass} zindex10 m-0 p-0`}
                  >
                    <div
                      className={`overflow-auto m-0`}
                      style={{ height: '80vh' }}
                    >
                      <Outlet />
                    </div>
                  </Card>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BlDetails;

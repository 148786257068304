import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { getPymtDetailsByPymtNo } from '../actions/payments';
import { cancelPayment } from '../actions/payments';
import { acceptPayment } from '../actions/payments';
import { rejectPayment } from '../actions/payments';
export interface BLPaymentDetails {
  blId: string;
}

interface BLPaymentDetailsList {
  list: BLPaymentDetails[];
  next: string | null;
  previous: string | null;
}

export interface BlPaymentSlice {
  paymentMode: any;
  bankOptions: any;
  pymtModeOptions: any;
  searchFiltter: any;
  dataLoading: boolean;
  data: BLPaymentDetailsList;
  pymtNo: string;
  pymtMode: any;
  bnkNm: string;
  accNo: string;
  pymtAmt: any;
  localamount:any;
  amountDetails: any;
  editPayment: boolean;
  pymtCurr: any;
  localccy:any;
  platformFee: any;
  totalInvAmt: any;
  payorAccountId: any;
  paymentRefNumber: any;
  radioValue: string;
  pmtStatus: string;
  linerRemarks?: any;
  pmtId: any;
  paymentSubmitLoader: any;
  fileName: string;
  cancelPaymentLoader: any;
  acceptPaymentLoader: any;
  rejectPaymentLoader: any;
}

const initialState: BlPaymentSlice = {
  paymentMode: 1,
  amountDetails: '',
  editPayment: false,
  linerRemarks: '',
  bankOptions: [],
  pymtModeOptions: [],
  searchFiltter: [],
  data: {
    list: [],
    next: null,
    previous: null,
  },
  dataLoading: true,
  pymtNo: '',
  pymtMode: 1,
  bnkNm: '',
  accNo: '',
  pymtAmt: '',
  pymtCurr: '',
  localamount:'',
  localccy:'',
  platformFee: '',
  totalInvAmt: '',
  payorAccountId: null,
  paymentRefNumber: '',
  radioValue: '',
  pmtStatus: '',
  pmtId: null,
  paymentSubmitLoader: false,
  fileName: '',
  cancelPaymentLoader: false,
  acceptPaymentLoader: false,
  rejectPaymentLoader: false,
};

export const blPaymentSlice = createSlice({
  name: 'blPayment',
  initialState,
  reducers: {
    updatePaymentMode: (state, action: PayloadAction<string>) => {
      state.paymentMode = action.payload;
    },
    updateAmountMode: (state, action: PayloadAction<string>) => {
      state.amountDetails = action.payload;
    },
    setEditPayment: (state, action: PayloadAction<boolean>) => {
      state.editPayment = action.payload;
    },
    updateBanks: (state, action: PayloadAction<any>) => {
      state.bankOptions = action.payload;
    },
    updatePymtModeOptions: (state, action: PayloadAction<any>) => {
      state.pymtModeOptions = action.payload;
    },
    updatesearchFiltter: (state, action: PayloadAction<any>) => {
      state.searchFiltter = action.payload;
    },
    paymentLoader: (state, action: PayloadAction<any>) => {
      state.paymentSubmitLoader = action.payload;
    },
    updatePymtData: (state, action: PayloadAction<any>) => {
      state.pymtMode = action.payload.pmtMode;
      state.pymtNo =
        action.payload.odexRefNo != ''
          ? action.payload.odexRefNo
          : action.payload.bankRefNo;
      state.bnkNm = action.payload.payorBankName;
      state.accNo = action.payload.payorAccountNo;
      state.pymtAmt = action.payload.paymentAmount;
      state.pymtCurr = action.payload.currency;
      state.payorAccountId = action.payload.payorAccountId;
      state.radioValue = action.payload.refType == 1 ? 'auto' : 'generate';
      state.pmtStatus = action.payload.pmtStatus;
      state.pmtId = action.payload.pmtId;
      state.fileName = action.payload.fileName;
      state.linerRemarks = action.payload.linerRemarks;
      state.platformFee = action.payload.totalPlatformCharges;
      state.totalInvAmt = action.payload.totalInvAmt;
      state.localamount = action.payload.localAmount;
      state.localccy = action.payload.localCcy;
      
    },
    setPaymentStatus: (state, action: PayloadAction<string>) => {
      state.pmtStatus = action.payload;
    },
    updatePymtRefNumber: (state, action: PayloadAction<any>) => {
      state.paymentRefNumber = action.payload;
    },
    resetPaymentLoaders: (state) => {
      state.acceptPaymentLoader = false;
      state.rejectPaymentLoader = false;
      state.cancelPaymentLoader = false;
      state.paymentSubmitLoader = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPymtDetailsByPymtNo.pending, (state, action) => {
      state.dataLoading = true;
    });

    builder.addCase(getPymtDetailsByPymtNo.fulfilled, (state, action) => {
      state.dataLoading = false;
      //state.data = action.payload;
    });
    builder.addCase(cancelPayment.pending, (state, action) => {
      state.cancelPaymentLoader = true;
    });
    builder.addCase(cancelPayment.fulfilled, (state, action) => {
      setTimeout(() => {
        state.cancelPaymentLoader = false;
      }, 2000);
    });
    builder.addCase(cancelPayment.rejected, (state, action) => {
      state.cancelPaymentLoader = false;
    });

    builder.addCase(acceptPayment.pending, (state, action) => {
      state.acceptPaymentLoader = true;
    });

    builder.addCase(acceptPayment.fulfilled, (state, action) => {
      setTimeout(() => {
        state.acceptPaymentLoader = false;
      }, 2000);
    });

    builder.addCase(acceptPayment.rejected, (state, action) => {
      state.acceptPaymentLoader = false;
    });

    builder.addCase(rejectPayment.pending, (state, action) => {
      state.rejectPaymentLoader = true;
    });

    builder.addCase(rejectPayment.fulfilled, (state, action) => {
      setTimeout(() => {
        state.rejectPaymentLoader = false;
      }, 2000);
    });

    builder.addCase(rejectPayment.rejected, (state, action) => {
      state.rejectPaymentLoader = false;
    });
  },
});

export const {
  updatePaymentMode,
  updateBanks,
  updatePymtModeOptions,
  updatesearchFiltter,
  updatePymtData,
  updateAmountMode,
  updatePymtRefNumber,
  paymentLoader,
  setEditPayment,
  setPaymentStatus,
  resetPaymentLoaders,
} = blPaymentSlice.actions;

export default blPaymentSlice.reducer;

import { Card } from 'react-bootstrap';

import {
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../../../store';

import dataTableBgImg from '../../../../assets/img/illustrations/cornerRight-1.png';
import { AESDecrypt } from '../../../../encrypt-util';
import { toggleObldetails } from '../../instructionDashboard/slices/dashboardSlice';
import { updateBlDetailsRowColumns } from '../slices/instructionDetailsTable';
import { toggleDocumentHistoryColumnShort } from '../slices/documentHistorySlice';

import OblDetails from '../../../common/components/OblDetails';
import Header from '../../../common/components/DetailsViewHeader';
import Registration from '../../registration/Registration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import MainTable from './Maintable';
import { updateDashbaordName } from '../../../common/slices/navbarTop';

const RegistrationDetails = () => {
  const location = useLocation();
  const { blNum } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [instructionStatusNew, setInstructionStatusNew] = useState();
  const instructionIdurl = useSelector(
    (state: RootState) => state.documentHistory.instructionIdurl
  );

  const paymentStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showPayment
  );

  const releaseStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showRelease
  );

  const instructionStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showInstruction
  );
  const instId = location.pathname.split('/')[3];
  useEffect(() => {
    if (instructionStatus !== null) {
      setInstructionStatusNew(instructionStatus.trim());
    }
  }, [instructionStatus]);

  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );
  const navigate = useNavigate();

  const _onCloseForm = () => {
    navigate(`/registrationmaster${window.location.search || ''}`);
  };

  const urlparts = location.pathname.split('/');
  const page = urlparts[urlparts.length - 1];
  const decpage = AESDecrypt(page);
  var path = location.pathname;
  var parts = path.split('/');
  var blslider = parts[1];
  /*   useEffect(() => {
    dispatch(updateDashbaordName('REGISTRATION_DASHBOARD'));
  }, []); */
  useEffect(() => {
    if (matchPath('/registrationmaster/:requestId', location.pathname)) {
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'col-lg-3',
          documentMainViewClass: 'col-lg-9',
          documentHistoryClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );
    }
  }, [location.pathname]);

  return (
    <Card className='w-100 h-100'>
      <div
        className='bg-holder bg-card'
        style={{
          backgroundImage: `url(${dataTableBgImg})`,
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
        }}
      />

      <Card.Body className='p-0'>
        <div className='row g-0 w-100 h-100'>
          <div className={`${rowColumns.mainTableClass}`}>
            <div className='d-flex flex-column justify-content-between align-items-start w-100 h-100'>
              <div className='d-flex flex-column justify-content-start align-items-start w-100 h-100'>
                <MainTable />
              </div>
            </div>
          </div>
          <div
            className={`/*  odex-bg-aliceblue  */ ${rowColumns.documentMainViewClass}`}
          >
            <Card className='overflow-auto' style={{ height: '90vh' }}>
              <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
                <FontAwesomeIcon
                  icon={faArrowAltCircleLeft}
                  className='text-warning cursor-pointer fa-lg mb-1 me-1'
                  onClick={_onCloseForm}
                />

                <h3 className='fs-0 text-primary'>
                  {t('resource_1:registration')}
                </h3>
              </Card.Header>
              {rowColumns.outletClass === 'd-none' && (
                <div
                  className='bg-holder bg-card bg-card-blDetails'
                  /*  style={{
                    backgroundImage: `url(${dataTableBgImgFoBlDetails})`,
                    borderTopRightRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem',
                  }} */
                />
              )}

              <Card.Body className='p-3 d-flex flex-column container'>
                {blslider == 'blslider' ? (
                  <OblDetails blslider={blslider} />
                ) : (
                  <>
                    {blslider !== 'registrationmaster' && (
                      <Header
                        backNavRoute={
                          queryString.parse(window.location.search)?.goto ===
                          'release'
                            ? '/release-dashboard'
                            : '/home'
                        }
                        paymentStatus={paymentStatus}
                        instructionStatus={instructionStatusNew}
                        releaseStatus={releaseStatus}
                      />
                    )}
                  </>
                )}
                <div className='row g-0 flex-1 ' style={{ height: '30vh' }}>
                  {rowColumns.outletClass !== 'd-none' && (
                    <div
                      className='bg-holder bg-card bg-card-blDetails'
                      /*   style={{
                        backgroundImage: `url(${dataTableBgImgFoBlDetails})`,
                        borderTopRightRadius: '0.375rem',
                        borderBottomRightRadius: '0.375rem',
                      }} */
                    />
                  )}

                  <div
                    className={`${rowColumns.documentHistoryClass} h-100 zindex5`}
                  >
                    {blslider === 'registrationmaster' && (
                      <div
                        className='row w-100'
                        style={{ margin: '0%', padding: '0%' }}
                      >
                        <div
                          style={{
                            marginRight: '0%',
                            paddingRight: '0%',
                            marginLeft: '0%',
                            paddingLeft: '0%',
                          }}
                          className={`${rowColumns.documentHistoryClass} zindex5 flex-1`}
                        >
                          <Registration />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <Card
                    className={`${rowColumns.outletClass} zindex10 m-0 p-0`}
                  >
                    <div
                      className={`overflow-auto m-0`}
                      style={{ height: '80vh' }}
                    >
                      <Outlet />
                    </div>
                  </Card> */}
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
  //     <Card className='w-100 h-100'>
  //       <div
  //         className='bg-holder bg-card'
  //         style={{
  //           //   backgroundImage: `url(${dataTableBgImg})`,
  //           borderTopRightRadius: '0.375rem',
  //           borderBottomRightRadius: '0.375rem',
  //         }}
  //       />

  //       <Card.Body className='p-0'>
  //         <div className='row g-0 w-100 h-100'>
  //           <div className={`${rowColumns.mainTableClass}`}>
  //             <div className='d-flex flex-column justify-content-between align-items-start w-100 h-100'>
  //               <div className='d-flex flex-column justify-content-start align-items-start w-100 h-100'>
  //                 {location.pathname !== '/instruction' && (
  //                   <Card.Header className='p-0 ps-2 pe-2 d-flex justify-content-start align-items-center odex-mh-60px' />
  //                 )}

  //                 <MainTable />
  //               </div>
  //             </div>
  //           </div>
  //           <Card className={`${rowColumns.documentMainViewClass}`}>
  //             <div style={{ height: '90vh', overflow: 'hidden' }}>
  //               {rowColumns.outletClass === 'd-none' && (
  //                 //<div className='bg-holder bg-card bg-card-blDetails odex-bldetails-img' />

  //                 <div
  //                   className='bg-holder bg-card bg-card-blDetails h-100'
  //                   style={{
  //                     backgroundImage: `url(${dataTableBgImgFoBlDetails})`,
  //                     borderTopRightRadius: '0.375rem',
  //                     borderBottomRightRadius: '0.375rem',
  //                   }}
  //                 />
  //               )}
  // <Card.Body className='p-0 d-flex flex-column'>
  //                 {blslider == 'blslider' ? (
  //                   <OblDetails blslider={blslider} />
  //                 ) : (
  //                   <>
  //                     {blslider != 'multiBl' && (
  //                       <Header
  //                         backNavRoute={
  //                           queryString.parse(window.location.search)?.goto ===
  //                           'release'
  //                             ? '/release-dashboard'
  //                             : '/home'
  //                         }
  //                         paymentStatus={paymentStatus}
  //                         instructionStatus={instructionStatusNew}
  //                         releaseStatus={releaseStatus}
  //                       />
  //                     )}
  //                   </>
  //               {blslider !== 'registrationmaster' && (
  //                 <div className='row g-0 flex-1 h-100'>
  //                   {rowColumns.outletClass !== 'd-none' && (
  //                     // <div className='bg-holder bg-card odex-bldetails-img-r' />
  //                     <div
  //                       className='bg-holder bg-card bg-card-blDetails h-100'
  //                       style={{
  //                         backgroundImage: `url(${dataTableBgImgFoBlDetails})`,
  //                         borderTopRightRadius: '0.375rem',
  //                         borderBottomRightRadius: '0.375rem',
  //                       }}
  //                     />
  //                   )}

  //                   {blslider !== 'registrationmaster' && (
  //                     <div
  //                       className={`${rowColumns.documentHistoryClass} h-100 zindex5 overflow-auto`}
  //                     >
  //                       <Outlet />
  //                     </div>
  //                   )}
  //                 </div>
  //               )}
  //             </div>
  //           </Card>
  //         </div>
  //       </Card.Body>
  //     </Card>
  //   );
};

export default RegistrationDetails;

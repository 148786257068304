import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DataTable from '../../common/components/DataTable';
import FilterView from '../../common/components/FilterView';
import { IDataTableColumn } from '../../common/types/dataTable';
import { AppDispatch, RootState } from '../../store';
import { useEffect, useRef, useState } from 'react';

import queryString from 'query-string';
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import OblDetails from '../../common/components/OblDetails';
import { AESDecrypt, AESEncrypt } from '../../../encrypt-util';
import {
  IConfigsDefinition,
  generateColumnConfig,
} from '../../utils/columnConfig';

import { getColumnViews } from '../../common/actions/dataTable';
import {
  updateDashbaordRefresh,
  updateParams,
  updateSortStateDataTable,
} from '../../common/components/DataTable/slices/DataTable';
import { getDashboardData } from '../dashboard/actions/dashboard';
import { clearMappingDashboard, updateCurrentPageMapping } from '../dashboard/slices/dashboardSlice';
import { updateDashbaordName } from '../../common/slices/navbarTop';

import moment from 'moment';
import DashboardContainer from '../../../components/common/DashboardContainer';
import useDataCountry from '../../../hooks/useDataCountry';
import { setMappingTopSearchFilter, toggleColumnSettingsPayDash } from './MappingSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import CustomerDetailsSlider from './MappingCustomerDetailsSlider';

const gridName = 'CUSTOMER_DASHBOARD'
const dashboardName = 'CUSTOMER_DASHBOARD'
const filterKey = 'mapingDashboard'


const MappingDashboard = () => {
  const { blNum } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });
  const refresh = useSelector((
    state:RootState)=> state.dataTable.refreshDashboard)
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageMapping
  );

  const data = useSelector(
    (state: RootState) => state.dashboard.mappingDashboardList
  );

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const grids = useSelector(
    (state: RootState) => state.dataTable.mappingGrids
  );

  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });
  const [currentState, setCurrentState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const sortState = useSelector((state:RootState) => state.dataTable.sortState);
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageMapping(page));
  };

  const topSearchState = useSelector(
    (state: RootState) => state.navbarTop.mappingTopSearchState
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.mappingDashboard.mappingTopSearch
  );

  const dataCountry = useDataCountry()
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();
    
    useEffect(() => {
      callback.current = effect;
    }, [effect]);
  
    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };
  
      const timer = setTimeout(handler, delay);
      
      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }

  useEffect(() => {
    const mappingDashboardDefinition =
      grids?.[0]?.columnConfigGrid || '';

    if (!mappingDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(mappingDashboardDefinition)),
        getParams,
        navigate
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
      console.log(columns)
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location]);

  useEffect(() => {
    dispatch(updateDashbaordName(gridName));
    dispatch(setMappingTopSearchFilter(''))
  }, []);

  const filterStateLocal = filterState?.[filterKey];
  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(',')
    ?.join?.(' ');

  
  useEffect(() => {
    if (location?.pathname && dataCountry && linerId) {
      dispatch(
        getColumnViews({ gridName: dashboardName, country: dataCountry, linerId : linerId })
      );
    }
    dispatch(updateParams({}));
  }, [dataCountry, linerId]);

  useEffect(() => {
    loadmappingDashboard(false);
  }, [currentPage]);

  useEffect(()=>{
    if (refresh) loadmappingDashboard(false)
    // server sometimes takes time to update the view so the delay
    setTimeout(()=>dispatch(updateDashbaordRefresh(false)),2000)
  }, [refresh])

  useEffect(() => {
    if(matchPath(location.pathname.toLowerCase(), '/mappingdashboard'))loadmappingDashboard(false);
  }, [location.pathname]);


  useEffect(() => {
    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids?.[0]?.gridId,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      setCurrentState({
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids?.[0]?.gridId,
      });
    }
  }, [filterStateLocal, location.search, grids]);

  useDebouncedEffect(() => {
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      loadmappingDashboard(true);
      setPreviousState(currentState);
    }
  }, [currentState, linerId], 700);

  useEffect(() => {
    loadmappingDashboard(true);
  }, [linerId, searchInputValue]);

  const loadmappingDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) setCurrentPage(1);
    if (!searchInputValue || searchInputValue.trim()==''){
     dispatch(clearMappingDashboard()) 
      return
    }

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};
          const currentDate = new Date();

          if (grids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange:any = {};

            const newdata = grids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.[filterKey]
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.dateRange;
            }
            if (filterApiState.invdt && timeFlag == 'day' && dateRange) {
              filterApiState.invdt[1] = moment()
              .subtract(Math.abs(dateRange.to), 'days')
              .endOf('day')
              .toISOString()
              filterApiState.invdt[0] = moment()
              .subtract(Math.abs(dateRange.from), 'days')
              .startOf('day')
              .toISOString();
            }
          }
          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            setTimeout(()=> {dispatch(
              getDashboardData({
                gridName: gridName,
                payload: {
                  fields: /*  SelectedViewFields?.fields || */ [],
                  filters:
                    !selectedTopSearchFilter ||
                    selectedTopSearchFilter?.where == '' ||
                    searchInputValue.trim() === ''
                      ? finalFilterApiState
                      : {},

                  size: 20,
                  sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['id desc'],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== '' &&
                  searchInputValue.trim() !== ''
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );},250)
            
          }
        }
      } else {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          profileDetails?.partnerType === 'liner'
        ) {
          setTimeout(()=>{dispatch(
            getDashboardData({
              gridName: gridName,
              payload: {
                fields: /* SelectedViewFields?.fields || */ [],
                filters:
                  selectedTopSearchFilter?.where === ''
                    ? topSearchState
                    : {},
                size: 20,
                sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['id desc'],
                page: filterUpdated ? 1 : currentPage,
              },
              dataCountry: dataCountry,
              partnerId:
                profileDetails.partnerType === 'customer'
                  ? profileDetails.partnerId
                  : null,
              where: grids?.[0]?.whereClause
                ? AESEncrypt(grids?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ''
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          );},250)
          
        }
      }
    } else {
      if (
        location.pathname.toLowerCase().includes('/mappingdashboard') &&
        location?.state?.from == undefined
      ) {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          profileDetails.partnerType === 'liner'
        ) {
          setTimeout(()=>{ dispatch(
            getDashboardData({
              gridName: gridName,
              payload: {
                fields: /* SelectedViewFields?.fields || */ [],
                filters:
                  selectedTopSearchFilter?.where == ''
                    ? {[selectedTopSearchFilter.id]:[searchInputValue]}
                    : {},
                size: 20,
                sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['id desc'],
                page: filterUpdated ? 1 : currentPage,
              },
              dataCountry: dataCountry,
              partnerId:
                profileDetails.partnerType === 'customer'
                  ? profileDetails.partnerId
                  : null,
              where: grids?.[0]?.whereClause
                ? AESEncrypt(grids?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ''
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          );},250)
        }
      }
    }
  };

  const showColumnSettings = useSelector(
    (state:RootState)=>state.mappingDashboard.showColumnSettings)

  const getParams = () => {
    return {
      blid: blNum ? AESDecrypt(blNum) : '',
      pmtmode: blNum ? AESDecrypt(blNum) : '',
    };
  };

  useEffect(() => {
    dispatch(updateParams({}));
    dispatch(
      updateSortStateDataTable({
        key: null,
      })
    );
  }, [dataCountry]);

  const sideWidth = Object.entries({
    [`/mappingdashboard`] : 0,
    [`/mappingdashboard/customerdetails/:custId`]: 10,
    [`/mappingdashboard/customerdetails/:custId/addcode`]:10,
    [`/mappingdashboard/customerdetails/:custId/edituser`]: 8,
    [`/mappingdashboard/customerdetails/:custId/edituser/:userId`]: 8,

  }).find(([path,_])=>matchPath(path, location.pathname.toLowerCase()))?.[1]??0

  const inner = ['edituser', 'addcode'].some((i)=>location.pathname.includes(i))

  return (
    <DashboardContainer
    sideWidth={sideWidth}
    inner={inner}
    innerOffset={58}
    middleChildren={
     <CustomerDetailsSlider disable={inner}/>
    }
    >
      <FilterView
        loadList={loadmappingDashboard}
        showUploadButton
        showColumnSettings={!!searchInputValue && searchInputValue.trim() !==''}
        dataLoading={false}
        toggleColumnSettings={toggleColumnSettingsPayDash}
        columnSettingsPersistKey='@odex/paydash/v1'
        uniqueFilterKey='filterKey'
        configViews={
          grids?.find?.((g) => g?.gridName === dashboardName)
            ?.configGroup || []
        }
        showRightDiv={!!matchPath(location.pathname.toLowerCase(), '/mappingdashboard')}
        dashboardName={dashboardName}
        data={data}
        gridDef={grids}
      />
      {(searchInputValue??'').trim() =='' && matchPath(location.pathname.toLowerCase(), '/mappingdashboard') && (
        <div className='flex-1 d-flex h-100 align-items-center justify-content-center'>
          <span
            className='f-1'
            style={{
              userSelect: 'none',
            }}
          >
            <FontAwesomeIcon icon={faXmarkCircle} /> Add Search Parameter In Top Filter
            <br />
            <br />
            <br />
            <br />
          </span>
        </div>
      )}
      {searchInputValue && searchInputValue.trim() !=='' &&(
        <DataTable
          data={data?.result || null}
          columns={columns}
          dataCountry={dataCountry || ''}
          sortable
          searchable={false}
          showColumnFilter
          selection={false}
          perPage={20}
          pagination
          numberPagination
          maxPage={parseInt(data?.maxPage || '100')}
          hasNext={data?.hasNext}
          currentPage={currentPage}
          gridId={grids?.[0]?.gridId || 0}
          onChangePageNumber={(pageNumber) =>
            setCurrentPage(pageNumber)
          }
          showExport={false}
          showColumnSettings={showColumnSettings}
          onHide={()=>{dispatch(toggleColumnSettingsPayDash(false));}}
          columnSettingsPersistKey='@odex/mapDash/v1'
          customSort={false}
          uniqueFilterKey='filterKey'
          staticHiddenCols={configs?.staticHiddenCols || []}
          defaultHiddenCols={[]}
          tableMinHeight={400}
          gridName= {dashboardName}
          dataLoading={false}
          configGroup={grids?.[0]?.configGroup || []}
        />
      )}          
    </DashboardContainer>
  )
};

export default MappingDashboard;

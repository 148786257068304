import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios, { axiosCacheInstance } from '../../../../axiosSetup';
import { AxiosError } from 'axios';

import { updateAgentPartnerId } from '../../blRequestInstruction/components/instructionSlice/AgentNominationSlice';
import {
  updateBLPartyList,
  updateInstructionTypes,
} from '../../blDetails/slices/documentHistorySlice';
import { toast } from 'react-toastify';
import {
  updatePickupLocation,
  updateSelectedDateDV,
} from '../../blRequestInstruction/components/instructionSlice/DemurrageSlice';
import { AESDecrypt } from '../../../../encrypt-util';
import { updateReturnSelectedDateDV } from '../../blRequestInstruction/components/instructionSlice/ReturnSlice';
import {
  updateReleaseTypeList,
  updateReturnTypeList,
} from '../../blRequestInstruction/components/instructionSlice/InvoiceChangeSlice';
import { updateDestuffingTpList } from '../../blRequestInstruction/components/instructionSlice/PickupSlice';
import { updateStandingInstructionTypes } from '../../standingInstruction/slices/standingInstructionTable';
import { getDateFormat, getDateTimeFormat } from '../../../../helpers/dateFormat';
import moment from 'moment';
import { RootState } from '../../../store';
// Enable while using mock data
// import axios from 'axios'

// export const getInvDashboardDefinition = createAsyncThunk(
//   'instruction/getInvDashboardDefinition',
//   async () => {
//     try {
//       const response = await Axios.get(
//         '/api/user-management/v1/usergrid/masterGrids?linerId=1&country=US'
//       );

//       return (
//         response.data?.list?.find?.((f: any) => f?.gridName === 'INV_DASHBOARD')
//           ?.columnConfig || []
//       );
//     } catch (error) {
//       return [];
//     }
//   }
// );

export const getInstructionList = createAsyncThunk(
  'instruction/getInstruction',
  async (filterData: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl/${filterData.blNum}/event?country=${filterData.country}`
      );
      const data = response.data.map((v: any) => ({
        blEventId: v?.blEventId || null,
        blId: v?.blId || null,
        document: v?.docType || null,
        documentNumber: v?.docNumber || null,
        docStatus: v?.docStatus || null,
        event: v?.event || null,
        time: v?.timestamp || null,
        ccy: v?.currency || null,
        amount: v?.amount || null,
        docId: v?.docId || null,
        instructionId: v?.instructionId || null,
        user: v?.user || null,
        invAccess: v?.invAccess || null,
        partnerId: v?.partnerId || null,
        docCategory: v?.docCategory || null
      }))
      data.sort((A:any,B:any)=>B.blEventId-A.blEventId)
      return {
        next: null,
        previous: null,
        list:data,
      };
    } catch (e) {
      const error = e as AxiosError;

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);
export const getCoreror = createAsyncThunk(
  'instruction/getCoreror',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/coreor/bl/${payload.blNum}?linerId=${payload?.selectedLinerId}`
      );

      return {
        next: null,
        previous: null,
        list: response.data.containerList.map((v: any) => ({
          ...v,
          containerNo: v?.containerNo || null,
          containerType: v?.containerType || null,
          containerSize: v?.containerSize || null,
          truckerCode: v?.truckerCode || null,
          releaseDate: v?.releaseDate || null,
          releaseStatus: v?.releaseStatus || null,
          lastFreeDate: v?.lastFreeDate || null,
          releasePin: v?.releasePin || null,
          releaseLoc: v?.releaseLoc || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const addDemurrage = createAsyncThunk(
  'instruction/addDemurrage',
  async (payload: any) => {
    try {
      const response = await Axios({
        url: `api/invoice-management/v1/dndInstruction/submitDemurrageInvoiceRequest`,
        method: 'POST',
        data: {
          blId: payload?.blId,
          requestedPartnerId: payload?.requestedPartnerId,
          customerEmailId: payload?.senderEmail,
          remarks: payload?.remarks,
          userRemarks: payload?.userRemarks,
          status: payload?.status,
          dndInstructionId: payload?.dndInstructionId,
          containerDetails:
            payload?.country === 'US'
              ? payload.list
                  .filter((c: any) => c?.pickupDate)
                  .map((v: any) => ({
                    containerPickupId: v?.containerPickupId || null,
                    cntNo: v?.containerNo || null,
                    pickupDate: v?.pickupDate || null,
                    truckerCode: v?.truckerCode || null,
                    truckerName: v?.truckerName || null,
                    truckerId: v?.truckerId || null,
                    pickupLocation: v?.pickupLocation || null,
                    internalCode: v?.internalCode || null,
                  }))
              : payload.list.map((v: any) => ({
                  containerPickupId: v?.containerPickupId || null,
                  cntNo: v?.containerNo || null,
                  pickupDate: v?.pickupDate || null,
                  truckerCode: v?.truckerCode || null,
                  truckerName: v?.truckerName || null,
                  truckerId: v?.truckerId || null,
                  pickupLocation: v?.pickupLocation || null,
                  internalCode: v?.internalCode || null,
                })),

          releaseType: payload?.releaseType,

          dndInstructionAtt:
            payload?.country === 'ZA' || payload?.country === 'KE' || payload?.country === 'JO'
              ? payload.docList
                  .filter((c: any) => c?.fileName)
                  .map((v: any) => ({
                    dndInstructionAttId: v?.dndInstructionAttId || null,
                    docId: v?.docId || null,
                    fileName: v?.fileName || null,
                    serverFilePath: v?.serverPath || null,
                    filePath: v?.filePath || null,
                    tempFileName: v?.tempFileName || null,
                  }))
              : [],
              groupLevel:payload?.groupLevel  
        },
      });

       toast.success(response?.data?.responseMsg??'Created successfully!');

      //if (payload?.docId) payload?.dispatch?.(getDemurrageView(payload.docId));

      payload?.dispatch?.(updateSelectedDateDV({}));

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const getDemurrage = createAsyncThunk(
  'instruction/getDemurrage',
  async (payload: any) => {
    try {
      const response = await Axios.post(
        `api/invoice-management/v1/dndInstruction/getNonReleaseContainerByBlId/${payload?.docId}?linerId=${payload.selectedLinerId}`
      );
      return {
        next: null,
        previous: null,
        list: response.data
          .filter((x: any) =>
            payload?.location === 'instruction'
              ? x?.releaseDate === null || x?.releaseDate === undefined
              : true
          )
          .map((v: any) => ({
            ...v,
            blContainerId: v?.blContainerId || null,
            containerNo: v?.containerNo || null,
            pickupDate: v?.pickupDate || null,
            truckerCode: v?.truckerCode || null,
            truckerName: v?.pickupTruckerName || null,
            truckerId: v?.pickupTruckerId || null,
            pickupLocation: v?.pickupLocation || null,
            internalCode: v?.internalCode || null,
            cntStatus: v?.status || null,
            requestedOn: v?.requestedOn || null,
            approvedRejectedOn: v?.approvedRejectedOn || null,
          })),
      };
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getDemurrageView = createAsyncThunk(
  'instruction/getDemurrageView',
  async (payload: any) => {
    try {
      const response = await Axios.post(
        `api/invoice-management/v1/dndInstruction/getDemurrageInvoiceRequest/${payload?.docId}?linerId=${payload.selectedLinerId}`
      );

      return {
        next: null,
        previous: null,
        data: {
          blId: response?.data?.blId,
          requestedPartnerId: response?.data?.requestedPartnerId,
          customerEmailId: response?.data?.customerEmailId,
          country: response?.data?.country,
          dndInstructionId: response?.data?.dndInstructionId || null,
          requestedPartnerNm: response?.data?.requestedPartnerNm,
          remarks: response?.data?.remarks,
          userRemarks: response?.data?.userRemarks,
          status: response?.data?.status || null,
          groupLevel: response?.data?.groupLevel??0,
          linerDocs: response?.data?.dndInstructionLinerAtt??[],
          checkList:response?.data?.checkList?.map((i:any)=>({
            docId: i.codeId,
            check: i.isVerify?i.isVerify == 'Y':null,
            linerId: i.actionBy,
            actionOn:i.actionOn,
            groupLevel:i.groupLevel,
            checkListId:i.checkListId,
            docType:i.docType,
            instructionId:i.instructionId,
            name:i.codeDesc,
            //linerId:i.linerId,
            remarks:i.remarks,
            isMandatory:i.isMandatory,
            checkListUpdated:  i.actionOn?moment(
              moment
                .utc(new Date(i.actionOn))
                .toDate()).format(getDateTimeFormat().toLocaleUpperCase()
            ):'' ,
           
          }))??[],
          list: response?.data?.containerDetails
            ? response?.data?.containerDetails.map((v: any) => ({
                ...v,
                blContainerId: v?.containerPickupId || null,
                containerNo: v?.cntNo || null,
                pickupDate: v?.pickupDate || null,
                // truckerCode: '',
                truckerCode: v?.truckerCode || null,
                truckerName: v?.truckerName || null,
                truckerId: v?.truckerId || null,
                pickupLocation: v?.pickupLocation || null,
                internalCode: v?.internalCode || null,
              }))
            : [],
          attList: response?.data?.dndInstructionAtt
            ? payload?.docList?.map((x: any) => {
                const value = response?.data?.dndInstructionAtt.find(
                  (f: any) => f?.docId === x?.id
                );

                return {
                  dndInstructionAttId: value?.dndInstructionAttId || null,
                  docId: x?.id,
                  filePath: value?.filePath || null,
                  fileName: value?.fileName || null,
                  m: x?.m,
                  isVerify:value?.isVerify == 'Y' || false,
                  actionOn: (value?.actionBy? 
                    moment(
                      moment
                        .utc(new Date(value?.actionOn))
                        .toDate()
                    ).format(getDateTimeFormat().toLocaleUpperCase())
                    :null) ?? null,
                  actionBy:value?.actionBy || null
                };
              })
            : [],
        },
      };
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);
export const getAttachmentsOnly = createAsyncThunk(
  'Instruction/demurrageAttachments',
  async (payload:any) =>{
    if(payload.id == null) return null
    try{
    const data = await Axios.post(
      `api/invoice-management/v1/dndInstruction/getDemurrageInvoiceRequest/${payload.id}?linerId=${payload.selectedLinerId}`
    ).then((axobj)=>{
      return axobj?.data?.dndInstructionAtt?.length > 0? axobj?.data?.dndInstructionAtt: null
    })
    return data
  }catch{
    return null
  }
  }
)

export const getUnpaidInvoice = createAsyncThunk(
  'instruction/getUnpaidData',
  async ({blid, country}:{blid:any, country: string})=>{
    try{
       const data = (await Axios.get(
        `api/invoice-management/v1/pop/customer/unpaidInvoices?blId=${blid}&&isCredit=N&&country=${country}`,
      ))?.data
      if(data?.statusCode) throw {message:`Error: Status code ${data?.statusCode}`}
      return data.map((item:any)=>({
          invno: item.invno??'',
          invid: item.invid??null, 
          ccy: item.invoiceaccess=='Y'?item.invcurr??'':'',
          amount:item.invoiceaccess=='Y'?item.invamt??null:null,
          invtype:item.invtype??'',
          invoiceAccess: item.invoiceaccess
      })).sort(function(a:any,b:any){return b.ccy.localeCompare(a.ccy); })

    } catch(e:any){
      console.error('Unpaid API Error: ',e.message)
      toast.error(e.message)
      return [{}]
    }
  }
)

export const getReleasePOPDetails = createAsyncThunk(
  'instruction/getReleasePOPDetails',
  async ({blid, country}:{blid:any, country:string})=>{
    try{
      const data = (await Axios.get(
        `api/invoice-management/v1/pop/liner/AllInvoices?blId=${blid}&&country=${country}`,
     ))?.data
     if(data?.statusCode) throw {message:`Error: Status code ${data?.statusCode}`}
      return data.map((item:any)=>({
        invid:item.invid??0,
        invno:item.invno??'',
        ccy:item.invcurr??'',
        amount:item.invamt??null,
        invtype:item.invtype??'',
        invref: item.pmtreference??'',
        invatt: item.filePath?.split('/')?.pop()?.substring(8)??'',
        invstatus: item.pmtstatus??'',
        hyperlink: item.filePath??'',
        invoicestatus: item.invstatus??null,
        pymtId: item.pmtid?? null
    })).sort(function(a:any,b:any){return b.invref.localeCompare(a.invref); })
    } catch(e:any){
      console.error('POP Liner API Error: ',e.message)
      return []
    }
  }
)

export const approveRejectDemurrage = createAsyncThunk(
  'instruction/approveDemurrage',
  async (payload: any) => {
    try {
      /*
      const response = await Axios.patch(
        //`/api/invoice-management/v1/carrierHaulage/${payload.carrierHaulageId}`,
        `/api/invoice-management/v1/dndInstruction/approveRejectDND${docId}`
      );
      return {
        next: null,
        previous: null,
        data: {
          blId: response.data.blId,
          requestedPartnerId: 82,
          customerEmailId: response.data.customerEmailId,
          dndInstructionId: docId,
          requestedPartnerNm: response.data.requestedPartnerNm,
          // customerEmailId: payload?.customerEmailId,
          list: response.data.containerDetails.map((v: any) => ({
            ...v,
            blContainerId: v?.containerPickupId || null,
            containerNo: v?.cntNo || null,
            pickupDate: v?.pickupDate || null,
            truckerCode: '',
            
          })),
        },
      };*/
      const response = await Axios({
        url: `api/invoice-management/v1/dndInstruction/approveRejectDND`,
        method: 'POST',
        data: {
          blId: payload?.blId,
          requestedPartnerId: payload?.requestedPartnerId,
          customerEmailId: payload?.senderEmail,
          dndInstructionId: payload?.dndInstructionId || null,
          requestedPartnerNm: payload?.requestedPartnerNm || null,
          remarks: payload?.remarks,
          status: payload?.status,
          containerDetails: [],
        },
      });

      //  toast.success('Created successfully!', {
      // style: {
      //  color: 'white',
      //  },
      //  });

      if (payload?.docId) payload?.dispatch?.(getDemurrageView(payload.docId));

      payload?.dispatch?.(updateSelectedDateDV({}));

      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error( error);
      return {};
    }
  }
);

export const getTrukerNameList = createAsyncThunk(
  'instruction/getTrukerNameList',
  async (payload: any) => {
    try {
      //?name=${payload?.name}&country=${payload?.country}`
      const response = await Axios.post(
        `api/invoice-management/v1/dndInstruction/getTruckerNameListByName`,
        {
          truckerName: payload?.name,
          country: payload?.country,
        }
      );
      if (response?.data && response?.data !== '') {
        return response?.data?.map((c: any) => {
          return {
            ...c,
            label: c?.truckerName,
            value: c?.truckerName,
          };
        });
      } else {
        return [];
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);


export const getCFSNameList = createAsyncThunk(
  'instruction/getCFSNameList',
  async (payload: any) => {
    try {
      //?name=${payload?.name}&country=${payload?.country}`
      const response = await Axios.post(
        `api/invoice-management/v1/dndInstruction/getCfsListByName`,
        {
          pickupLocation: payload?.name,
        }
      );
      if (response?.data && response?.data !== '') {
        return response?.data?.map((c: any) => {
          return {
            ...c,
            label: c?.cfsName,
            value: c?.cfsName,
          };
        });
      } else {
        return [];
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getOBLDetails = createAsyncThunk(
  'instruction/getOBLDetails',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/coreor/blDoc/${payload?.blNumber}?linerId=${payload?.selectedLinerId}`
      );
      /* response?.data?.blJourneyList?.sort((a: any, b: any) => {
        return a.blJourneyId - b.blJourneyId;
      }); */
      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const getOBLForOverStay = createAsyncThunk(
  'overstay/getOBLDetails',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/coreor/blDoc/${payload?.blNumber}?linerId=${payload?.selectedLinerId}`
      );
      response?.data?.blJourneyList?.sort((a: any, b: any) => {
        return a.blJourneyId - b.blJourneyId;
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const getCarrierHaulage = createAsyncThunk(
  'instruction/getOBLDetails',
  async (blNumber: any) => {
    try {
      const response = { data: '' };

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const addCarrierHaulage = createAsyncThunk(
  'instruction/addCarrierHaulage',
  async (payload: any) => {
    try {
      const response = await Axios({
        url: `https://d1.dev.odexglobal.com/api/invoice-management/v1/carrierHaulage`,
        method: 'POST',

        data: payload,
      });

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const getInvoiceChange = createAsyncThunk(
  'instruction/getInvoiceChange',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `api/invoice-management/v1/bp/${payload?.docId}?linerId=${payload?.selectedLinerId}`
      );
      return {
        taxId: response?.data?.taxId || null,
        blId: response?.data?.blId || null,
        masterPartnerId: response?.data?.masterPartnerId,
        standingInstructionId: 1,
        email: response?.data?.emailId || null,
        contactNo: response?.data?.contactNo || null,
        address: response?.data?.address || null,
        invChangegAccessList: response?.data?.invChangegAccessList || [],
        status: response?.data?.status,
        remarks: response?.data?.remarks,
        requestedpartnernm: response?.data?.requestedpartnernm || null,
        poReference: response?.data?.poReference || null,
        country: response?.data?.country || 'US',
        returnType: response?.data?.returnType || null,
        matchCodeList: response?.data?.matchCodeList || [],
        matchCode: response?.data?.matchCode,
        customerCode: response?.data?.customerCode,
        userRemarks: response?.data?.userRemarks
      };
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const fetchCompanyDetailsForAgentDelegation = createAsyncThunk(
  'agentNomination/fetchCompanyDetailsForAgentDelegation',
  async (state: any, { dispatch }) => {
    const csrfMeta =
      document.querySelector<HTMLMetaElement>("meta[name='_csrf']");
    try {
      const response = await Axios.post(
        `/api/user-management/v1/customer/findCustomerByTaxId?countryCode=${state.country}&taxId=${state.taxId}`,
        {
          'X-CSRF-TOKEN': csrfMeta?.content || '',
          'Content-Type': 'application/x-www-form-urlencoded',
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      dispatch(updateAgentPartnerId(response.data));
      if (!response.data) {
        toast.error(
          'Entered partner is not available on the ODeX, Kindly request Partner to get registered on the ODeX'
        );
      }

      //dispatch(updateCompanyName(response.data.data[0].company_name));
      //dispatch(updateAddress(response.data.data[0].full_address));
      return response.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
);

export const getInstructionTypes = createAsyncThunk(
  'pymtModedropdown/getInstructionTypes',
  async ({partnerCountry, linerId}: {partnerCountry:any, linerId:any}, { dispatch, getState }:any) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=INST_TYPE&linerId=${linerId}&countryCode=${partnerCountry}`,
        {
          cache: {
          ttl: 10 * 60 * 1000
          },
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      let index = 0;
      const instructionTypes = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
        index: index++,
      }));

      dispatch(updateInstructionTypes(instructionTypes));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getReportTypes = createAsyncThunk(
  'reportDashboard/getReportTypes',
  async ({partnerCountry, linerId}: any) => {

    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=REPORT_TYPE&linerId=${linerId}&countryCode=${partnerCountry}`,
        {
          cache: {
            ttl: 10 * 60 * 1000
          },
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      let index = 0;
      const reportType = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
        index: index++,
      }));
      return reportType
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
    }
  }
);

export const getInvoiceChangeTypes = createAsyncThunk(
  'invoiceChange/getInvoiceChangeTypes',
  async ({partnerCountry, linerId}: any) => {

    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=INV_CHANGE_TYPE&linerId=${linerId}&countryCode=${partnerCountry}`,
        {
          cache: {
            ttl: 10 * 60 * 1000
          },
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      const invoiceChangeType = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc
      }));
      return invoiceChangeType
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
    }
  }
);


export const getPartyListByBlId = createAsyncThunk(
  'agentDelegation/getPartyListByBlId',
  async (blNumber: any, { dispatch }) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl/agentDelegation/${AESDecrypt(blNumber)}`,
        {
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      dispatch(updateBLPartyList(response.data.list));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const fetchAddressSuggestions2: any = createAsyncThunk(
  'registrationCompany/fetchAddressSuggestions2',
  async (payload: any) => {
    try {
      if (payload?.text) {
        if (payload?.country !== null) {
          const response = await Axios.get(
            `/api/user-management/places/v0/indexes/explore.place.Esri/search/suggestions?text=${payload?.text}&filterCountries=${payload?.country}`
          );
          return response.data;
        } else {
          const response = await Axios.get(
            `/api/user-management/places/v0/indexes/explore.place.Esri/search/suggestions?text=${payload?.text}`
          );
          return response.data;
        }
      }
      return [];
    } catch (error) {
      console.error(error);

      return [];
    }
  }
);

/*----------------------------------------------------------
RETURN INSTRUCTION 
------------------------------------------------------------*/

export const getReturnView = createAsyncThunk(
  'instruction/getReturnView',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `api/invoice-management/v1/return/getReturnDetails?id=${payload?.docId}?linerId=${payload?.selectedLinerId}`
      );

      return {
        next: null,
        previous: null,
        data: {
          blId: response?.data?.blId,
          returnInstructionId: response?.data?.returnInstructionId,
          remarks: response?.data?.remarks,
          rejectionRemarks: response?.data?.rejectionRemarks,
          status: response?.data?.status || null,
          // customerEmailId: payload?.customerEmailId,
          list: response?.data?.containerDetails.map((v: any) => ({
            ...v,
            blContainerId: v?.containerReturnId || null,
            containerNo: v?.containerNo || null,
            pickupDate: v?.plannedReturnDate || null,
            // truckerCode: '',
            returnLocation: v?.returnLocation || '',
          })),
        },
      };
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getReturnContainer = createAsyncThunk(
  'instruction/getReturnContainer',
  async (payload: any) => {
    try {
      const response = await Axios.post(
        `api/invoice-management/v1/return/getNonReturnContainerByBlId/${payload?.docId}?linerId=${payload?.selectedLinerId}`
      );

      return {
        next: null,
        previous: null,
        list: response?.data?.map((v: any) => ({
          ...v,
          blContainerId: v?.blContainerId || null,
          containerNo: v?.containerNo || null,
          pickupDate: v?.pickupDate || null,
          returnLocation: v?.returnLocation || '',
        })),
      };
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const addReturnInstruction = createAsyncThunk(
  'instruction/addReturnInstruction',
  async (payload: any) => {
    try {
      const response = await Axios({
        url: `api/invoice-management/v1/return/saveReturnDetails`,
        method: 'POST',
        data: {
          blId: payload?.blId,
          remarks: payload?.remarks,
          rejectionRemarks: payload?.rejectionRemarks,
          containerDetailsForReturnInst: payload.list.map((v: any) => ({
            containerNo: v?.containerNo || null,
            plannedReturnDate: v?.pickupDate || null,
            returnLocation: v?.returnLocation || null,
          })),
        },
      });

      //  toast.success('Created successfully!', {
      // style: {
      //  color: 'white',
      //  },
      //  });

      if (payload?.docId) payload?.dispatch?.(getReturnView(payload.docId));

      payload?.dispatch?.(updateReturnSelectedDateDV({}));

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const approveRejectReturn = createAsyncThunk(
  'instruction/approveRejectReturn',
  async (payload: any) => {
    try {
      const response = await Axios({
        url: `api/invoice-management/v1/return/approveRejectReturn`,
        method: 'POST',
        data: {
          status: payload?.status,
          blId: payload?.blId,
          returnInstructionId: payload?.returnInstructionId,
          rejectionRemarks: payload?.rejectionRemarks,
        },
      });

      if (payload?.docId) payload?.dispatch?.(getReturnView(payload.docId));

      payload?.dispatch?.(updateReturnSelectedDateDV({}));

      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error( error);
      return {};
    }
  }
);

export const getReturnTypeList = createAsyncThunk(
  'invoiceChange/getReturnTypeList',
  async ({dataCountry, linerId}: {dataCountry:any, linerId:any}, { dispatch, getState }:any) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=RETURN_LOC&linerId=${linerId}&countryCode=${dataCountry}`,
        {
          cache: {
            ttl: 10 * 60 * 1000
          },
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );

      const returnTypeOptions = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));

      dispatch(updateReturnTypeList(returnTypeOptions));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getReleaseTypeList = createAsyncThunk(
  'invoiceChange/getReleaseTypeList',
  async ({partnerCountry, linerId}: {partnerCountry: any, linerId:any}, { dispatch, getState }:any) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=RELEASE_TYPE&linerId=${linerId}&countryCode=${partnerCountry}`,
        {
          cache: {
            ttl: 10 * 60 * 1000
          },
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );

      const releaseTypeOptions = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));

      dispatch(updateReleaseTypeList(releaseTypeOptions));
      dispatch(updatePickupLocation(releaseTypeOptions));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const extensionTypeList = createAsyncThunk(
  'overstay/extensionTypeList',
  async (partnerCountry: any, { dispatch }) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=EXTENSION_TYPE&linerId=1&countryCode=${partnerCountry}`,
      {
        cache: {
          ttl: 10 * 60 * 1000
        }
      });

      const extensionTypeOption = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));
      return extensionTypeOption

      

    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

//--------------------------------------------------------------------

export const savePickUpInstruction = createAsyncThunk(
  'pickUpInstruction/savePickUpInstruction',
  async (payload: any) => {
    try {
      const response = await Axios.post(
        '/api/invoice-management/v1/pickup/savePickUpDetails',
        {
          pickUpLoc: payload?.pickUpLoc,
          destuffingTp: payload?.destuffingTp,
          // returnLoc: payload?.returnLoc,
          userRemarks: payload?.userRemark,
          blId: payload?.blId,
        }
      );
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getPickUp = createAsyncThunk(
  'instruction/getPickUp',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `api/invoice-management/v1/pickup/viewPickupDetails/${payload.docId}?linerId=${payload?.selectedLinerId}`
      );

      return {
        next: null,
        previous: null,
        data: {
          blId: response?.data?.blId,
          returnInstructionId: response?.data?.returnInstructionId,
          userRemarks: response?.data?.userRemarks,
          rejectionRemarks: response?.data?.rejectionRemarks,
          status: response?.data?.status || null,
          //returnLoc: response?.data?.returnLoc,
          destuffingTp: response?.data?.destuffingTp,
          pickUpLoc: response?.data?.pickUpLoc,
          country: response?.data?.country,
          email: response?.data?.emailId,
        },
      };
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const approveRejectPickUp = createAsyncThunk(
  'instruction/approveRejectPickUp',
  async (payload: any) => {
    try {
      const response = await Axios({
        url: `api/invoice-management/v1/pickup/approveRejectPickUp`,
        method: 'POST',
        data: {
          status: payload?.status,
          rejectionRemarks: payload?.rejectRemarks,
          pickUpId: payload?.pickUpId,
          pickUpLoc: payload?.pickUpLoc,
          destuffingTp: payload?.destuffingTp,
          returnLoc: payload?.returnLoc,
          userRemarks: payload?.userRemark,
          emailId: payload?.emailId,
          blId: payload?.blId,
        },
      });

      //  toast.success('Created successfully!', {
      // style: {
      //  color: 'white',
      //  },
      //  });

      if (payload?.docId) payload?.dispatch?.(getDemurrageView(payload.docId));

      payload?.dispatch?.(updateSelectedDateDV({}));

      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error( error);
      return {};
    }
  }
);

export const getPickupList = createAsyncThunk(
  'instruction/getPickupList',
  async (payload: any) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=RELEASE_TYPE&linerId=1&countryCode=ZA`,
      {
        cache: {
        ttl: 10 * 60 * 1000
      }
      }
      );
      if (response?.data && response?.data !== '') {
        return response?.data?.map((c: any) => {
          return {
            ...c,
            id: c?.codeId,
            name: c?.codeDesc,
          };
        });
      } else {
        return [];
      }
    } catch (e) {}
  }
);

export const commonApproveReject = createAsyncThunk(
  'instruction/commonApproveReject',
  async (payload: any) => {
    try {
      const response = await Axios({
        url: `api/invoice-management/v1/common/approveRejectInstructions`,
        method: 'POST',
        data: {
          instructionId: payload?.instructionId || null,
          instructionType: payload?.instructionType || null,
          linerRemarks: payload?.linerRemarks || null,
          customerRemarks: payload?.customerRemarks || null,
          status: payload?.status || null,
          emailTo: payload?.emailTo || null,
          matchCode: payload?.matchCode || null,
          customerCode: payload?.customerCode || null,
          accessList: payload?.accessList || null,
          requestType: payload?.requestType || '',
          requestedPartnerId: payload?.requestedPartnerId || null,
          blId: payload?.blId || null,
          isVerify:payload?.isVerify??null,
          groupLevel:payload?.groupLevel??null,
          actionBy:payload?.actionBy??null,
          checkList:payload.checkList??null,
          demurrageInstLinerAttDtls: payload?.demurrageInstLinerAttDtls??null
         // actionOn:payload?.actionOn
       },
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      return {};
    }
  }
);

export const getDefoultPickuLocation = createAsyncThunk(
  'instruction/getDefoultPickuLocation',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/pickup/getPickUpDetails/${payload?.blId}?linerId=${payload?.selectedLinerId}`
      );
      return response.data.pickupLocation;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      return {};
    }
  }
);

export const getInstructionAgentTypes = createAsyncThunk(
  'pymtModedropdown/getInstructionAgentTypes',
  async ({partnerCountry, linerId}: any, { dispatch }:any) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=AGENT_TYPE&linerId=${linerId}&countryCode=${partnerCountry}`,
        {
          cache: {
            ttl: 10 * 60 * 1000
          },
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      let index = 0;
      const instructionTypes = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
        index: index++,
      }));

      dispatch(updateInstructionTypes(instructionTypes));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getInstructionDelegationTypes = createAsyncThunk(
  'pymtModedropdown/getInstructionDelegationTypes',
  async ({partnerCountry, linerId}: any, { dispatch}:any) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=DELEGATION_TYPE&linerId=${linerId}&countryCode=${partnerCountry}`,
        {
          cache: {
            ttl: 10 * 60 * 1000
          },
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      let index = 0;
      const instructionTypes = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
        index: index++,
      }));

      dispatch(updateStandingInstructionTypes(instructionTypes));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const saveSnooze = createAsyncThunk(
  'snooze/saveSnooze',
  async (payload: any) => {
    try {
        const response = await Axios.post(
        '/api/invoice-management/v1/bl/saveSnoozeDetails',
        {
          blEventId:payload.blEventId,
          snoozeStatus: payload?.snooze ? '2013' : '2014',
          snoozeDays: payload?.noOfDays,
          snoozeRemarks: payload?.userSnoozeRemark,
        //  snoozeDate:payload?.snoozeDate,
          snoozeEmailId:payload?.emailTo,
          snoozeSubject:payload?.subject,
          externalRemarks:payload?.externalRemarks,
          type:"3"
 }
      );
      return response  
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getSnooze= createAsyncThunk(
  'instruction/getSnooze',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `api/invoice-management/v1/bl/getBlDocDetails?blEventId=${payload.blEventId}`
      );

      return {
        next: null,
        previous: null,
        data: {
          blId: response?.data?.blId,
          blEventId: response?.data?.blEventId,
          docType: response?.data?.docType,
          docCategory: response?.data?.docCategory,
          eventId: response?.data?.eventId || null,
          //returnLoc: response?.data?.returnLoc,
          timestamp: response?.data?.timestamp,
          docId: response?.data?.docId,
          docStatus: response?.data?.docStatus,
          createdUser: response?.data?.createdUser,
          docStatusId: response?.data?.docStatusId,
          snoozeEmailId: response?.data?.snoozeEmailId,
          snoozeSubject: response?.data?.snoozeSubject,
          snoozeRemarks: response?.data?.snoozeRemarks,
          snoozeStatus: response?.data?.snoozeStatus,
          snoozeDays: response?.data?.snoozeDays,
          snoozeTimestamp: response?.data?.snoozeTimestamp,
          snoozeEndTimestamp: response?.data?.snoozeEndTimestamp,
          snoozedBy:response?.data?.snoozedBy,
          externalRemarks:response?.data?.externalRemarks
        },
      };
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const saveExtensionRequest = createAsyncThunk(
  'overstay/saveExtensionRequest',
  async (payload: any) => {
    try {
        const response = await Axios.post(
        '/api/invoice-management/v1/extensionRequest/submitExtensionRequest',
        {
          blId:payload.blId,
          extensionRequestId:isNaN(payload.extensionRequestId)?null:+payload.extensionRequestId,
          requestedPartnerId:payload.requestedPartnerId,
          containerDetails:payload.containerDetails,
          userRemarks:payload.userRemark,
          customerEmailId:payload.customerEmailId,
          remarks:payload.remarks,
          status:payload.status,
          extensionReason:payload.extensionReason,
          customerCode:payload.customerCode
        }
      );
      return response  
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getExtensionRequest= createAsyncThunk(
  'instruction/getExtensionRequest',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `api/invoice-management/v1/extensionRequest/getExtensionRequest/${payload?.docId}?linerId=${payload.selectedLinerId}`
      );
  
      return {
        
        data: {
          blId: response?.data?.blId,
          requestedPartnerId: response?.data?.requestedPartnerId,
          containerDetails: response?.data?.containerDetails?.map?.((item:any) => ({
            containerPickupId: item.containerPickupId,
            cntNo:item.cntNo,
            pickupLocation:item.pickupLocation,                  
            pickupDate:item.pickupDate ,
            truckerName: "",
            truckerId: "",
            deliveryLocationType:item.deliveryLocationType,
            currency: item.currency
          })),
          customerEmailId: response?.data?.customerEmailId,
          extensionRequestId: response?.data?.extensionRequestId,
          status: response?.data?.status,
          remarks: response?.data?.remarks,
          userRemarks: response?.data?.userRemarks,
          customerCode:response?.data?.customerCode
        },
      };
    } catch (e) {
      
      const error = e as AxiosError;
    
      console.error('Overstay', error);
      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const populateExtensionRequest= createAsyncThunk(
  'instruction/populateExtensionRequest',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `api/invoice-management/v1/extensionRequest/getExtensionRequest/${payload?.docId}?linerId=${payload.selectedLinerId}`
      );
  
      return {
        
        data: {
          blId: response?.data?.blId,
          requestedPartnerId: response?.data?.requestedPartnerId,
          containerDetails: response?.data?.containerDetails?.map?.((item:any) => ({
            containerPickupId: item.containerPickupId,
            cntNo:item.cntNo,
            pickupLocation:item.pickupLocation,                  
            pickupDate:item.pickupDate ,
            truckerName: "",
            truckerId: "",
            currency: item.currency??"",
            deliveryLocationType: item.deliveryLocationType??0
          })),
          customerEmailId: response?.data?.customerEmailId,
          extensionRequestId: response?.data?.extensionRequestId,
          status: response?.data?.status,
          remarks: response?.data?.remarks,
          userRemarks: response?.data?.userRemarks,
          customerCode:response?.data?.customerCode
        },
      };
    } catch (e) {
      
      const error = e as AxiosError;
    
      console.error('Overstay', error);
      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const deleveryLocTypeList = createAsyncThunk(
  'overstay/deleveryLocTypeList',
  async ({dataCountry, linerId}: any) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=DELIVERY_LOC_TYPE&linerId=${linerId}&countryCode=${dataCountry}`,
      {
        cache: {
          ttl: 10 * 60 * 1000
        }
      }
      );

      const deleveryLocTypeOption = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));
      return deleveryLocTypeOption

      

    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

/* export const getCheckList = createAsyncThunk(
  'instruction/getCheckList',
  async (payload: any) => {
    try {
      const response = await Axios.post(
        
        `/api/invoice-management/v1/common/attachmentConfig?countryCode=KE&instructionTy=8041&linerId=24118`
      );
 

      return response.data.map((item:any)=>({
   
        docId: item.docId,
        name:item.docName,
        check:null,
        linerId: "",
        checkListUpdated: "",
        remarks: "",
        isMandatory:item.isMandatory=='Y',
        groupLevel:item.companyType
      }))
    
      
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
); */


import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getRbacDetails,
  getUserGridsDefinition,
  getUserProfile,
} from '../actions/userDetails';

interface Profile {
  userId: string | number | null;
  partnerId: string | number | null;
  username: string | null;
  partnerName: string | null;
  email: string | null;
  partnerType: string | null;
  partnerCountry: string | null;
  pwdExpired: string | null;
  isTNCAccepted: string | null;
}

interface RBACDetails {
  gridPrivate: string;
  gridShared: string;
  gridSystem: string;
  blDashboard: string;
  invDashboard: string;
  createUpdatePayment: string;
  createUpdateCredit: string;
  useCredit: string;
  nominactionCUD: string;
  deliveryCUD: string;

  deliveryView: string;
  deliveryApproveReject: string;
  invoiceChangeCUD: string;
  invoiceChangeApproveReject: string;
  invoiceChangeView: string;
  demurrageCUD: string;
  demurrageView: string;
  demurrageApproveReject: string;
  customerMgmtCreate: string;
  customerMgmtView: string;
  customerMgmtApproveReject: string;
  userMgmtCreate: string;
  userMgmtView: string;
  userMgmtApproveReject: string;
  returnCntCUD: string;
  returnCntView: string;
  returnCntApproveReject: string;
  registrationMstDashboard: string;
  pickUpApproveReject: string;
  pickUpCUD: string;
  instructionDashboard: string;
  agentCreate: string;
  paymentDashboard: string;
  multiInstructCreate: string;
  agentNominationApproveReject: string;
  paymentApproveReject: string;
  standingInstDashboard: string;
  standaloneInvPymtCUD: string;
  bulkPymtCUD: string;
  paymentProofAttachment: string;
  paymentProofRequired: string;
  payDetailsDashboard: string;
  reportDashboard: string;
  allowInstructionAssignment: string;
  crrUnpaidHardStop: string;
  crrUnpaidSoftStop: string;
  saveAsDraft: string;
  snoozeCUD: string;
  showPlatformFees: string;
  overStayApproveReject: string;
  crrLevelOne:string;
  crrLevelTwo:string;
  crrLevelThree:string;
  crrLevelFour:string;
  crrLevelFive:string;
  receiptDashboard:string;
  mappingDashboard: string;

}

interface RbacDetailsList {
  list: RBACDetails[];
  next: string | null;
  previous: string | null;
}

interface IUserGridDefinition {
  //columnConfig: any[];
  columnConfigGrid: string;
  gridId: number;
  gridName: string | null;
  linerId: number;
}

interface UserDetails {
  profile: Profile;
  userGridDefinitions: IUserGridDefinition[];
  rbacDetails: RBACDetails;
  data: RbacDetailsList;
  linerDataCountry: string;
  countryList: any;
  linerGrpCountry: string;
  selectedLinerId: string;
}

const initialState: UserDetails = {
  profile: {
    userId: null,
    partnerId: null,
    username: null,
    partnerName: null,
    email: null,
    partnerType: null,
    partnerCountry: null,
    pwdExpired: null,
    isTNCAccepted: null,
  },
  userGridDefinitions: [],
  data: {
    list: [],
    next: null,
    previous: null,
  },
  rbacDetails: {
    gridPrivate: '',
    gridShared: '',
    gridSystem: '',
    blDashboard: '',
    invDashboard: '',
    createUpdatePayment: '',
    createUpdateCredit: '',
    useCredit: '',
    nominactionCUD: '',
    deliveryCUD: '',
    invoiceChangeCUD: '',
    bulkPymtCUD: '',
    invoiceChangeApproveReject: '',
    registrationMstDashboard: '',
    paymentApproveReject: '',
    invoiceChangeView: '',
    deliveryView: '',
    deliveryApproveReject: '',
    demurrageCUD: '',
    demurrageView: '',
    demurrageApproveReject: '',
    customerMgmtCreate: '',
    customerMgmtView: '',
    customerMgmtApproveReject: '',
    userMgmtCreate: '',
    userMgmtView: '',
    userMgmtApproveReject: '',
    returnCntCUD: '',
    returnCntView: '',
    returnCntApproveReject: '',
    pickUpCUD: '',
    pickUpApproveReject: '',
    instructionDashboard: '',
    agentCreate: '',
    paymentDashboard: '',
    multiInstructCreate: '',
    agentNominationApproveReject: '',
    standingInstDashboard: '',
    standaloneInvPymtCUD: '',
    paymentProofAttachment: '',
    paymentProofRequired: '',
    payDetailsDashboard: '',
    reportDashboard: '',
    allowInstructionAssignment: '',
    crrUnpaidHardStop: '',
    crrUnpaidSoftStop: '',
    saveAsDraft: '',
    snoozeCUD: '',
    overStayApproveReject:'',
    showPlatformFees:'',
    crrLevelOne:'',
    crrLevelTwo:'',
    crrLevelThree:'',
    crrLevelFour:'',
    crrLevelFive:'',
    receiptDashboard:'',
    mappingDashboard: ''
  },
  linerDataCountry: '',
  countryList: [],
  linerGrpCountry: '',
  selectedLinerId: '1',
};

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<Profile>) => {
      state.profile = action.payload;
    },
    updateRbacDetails: (state, action: PayloadAction<any>) => {
      state.rbacDetails.gridPrivate = action.payload.gridPrivate;
      state.rbacDetails.gridShared = action.payload.gridShared;
      state.rbacDetails.gridSystem = action.payload.gridSystem;
      state.rbacDetails.blDashboard = action.payload.blDashboard;
      state.rbacDetails.invDashboard = action.payload.invDashboard;
      state.rbacDetails.createUpdatePayment =
        action.payload.createUpdatePayment;
      state.rbacDetails.createUpdateCredit = action.payload.createUpdateCredit;
      state.rbacDetails.useCredit = action.payload.useCredit;
      state.rbacDetails.nominactionCUD = action.payload.nominactionCUD;
      state.rbacDetails.deliveryCUD = action.payload.deliveryCUD;
      state.rbacDetails.invoiceChangeCUD = action.payload.invoiceChangeCUD;

      state.rbacDetails.invoiceChangeApproveReject =
        action.payload.invoiceChangeApproveReject;
      state.rbacDetails.invoiceChangeView = action.payload.invoiceChangeView;
      state.rbacDetails.deliveryView = action.payload.deliveryView;
      state.rbacDetails.deliveryApproveReject =
        action.payload.deliveryApproveReject;
      state.rbacDetails.demurrageCUD = action.payload.demurrageCUD;
      state.rbacDetails.demurrageView = action.payload.demurrageView;
      state.rbacDetails.demurrageApproveReject =
        action.payload.demurrageApproveReject;
      state.rbacDetails.customerMgmtCreate = action.payload.customerMgmtCreate;
      state.rbacDetails.customerMgmtView = action.payload.customerMgmtView;
      state.rbacDetails.customerMgmtApproveReject =
        action.payload.customerMgmtApproveReject;
      state.rbacDetails.userMgmtCreate = action.payload.userMgmtCreate;
      state.rbacDetails.userMgmtView = action.payload.userMgmtView;
      state.rbacDetails.userMgmtApproveReject =
        action.payload.userMgmtApproveReject;
      state.rbacDetails.returnCntCUD = action.payload.returnCntCUD;
      state.rbacDetails.returnCntView = action.payload.returnCntView;
      state.rbacDetails.returnCntApproveReject =
        action.payload.returnCntApproveReject;
      state.rbacDetails.pickUpCUD = action.payload.pickUpCUD;
      state.rbacDetails.pickUpApproveReject =
        action.payload.pickUpApproveReject;
      state.rbacDetails.instructionDashboard =
        action.payload.instructionDashboard;
      state.rbacDetails.registrationMstDashboard =
        action.payload.registrationMstDashboard;
      state.rbacDetails.agentCreate = action?.payload?.agentCreate;
      state.rbacDetails.paymentDashboard = action?.payload?.paymentDashboard;
      state.rbacDetails.multiInstructCreate =
        action?.payload?.multiInstructCreate;
      state.rbacDetails.agentNominationApproveReject =
        action?.payload?.agentNominationApproveReject || '';
      state.rbacDetails.paymentApproveReject =
        action?.payload?.paymentApproveReject || '';
      state.rbacDetails.standingInstDashboard =
        action?.payload?.standingInstDashboard;
      state.rbacDetails.standaloneInvPymtCUD =
        action?.payload?.standaloneInvPymtCUD || '';
      state.rbacDetails.bulkPymtCUD = action?.payload?.bulkPymtCUD || '';
      state.rbacDetails.paymentProofAttachment =
        action.payload.paymentProofAttachment;
      state.rbacDetails.paymentProofRequired =
        action.payload.paymentProofRequired;
      state.rbacDetails.payDetailsDashboard = 
        action.payload.payDetailsDashboard || '';
      state.rbacDetails.reportDashboard =
        action.payload.reportDashboard || '';
        state.rbacDetails.allowInstructionAssignment = 
        action.payload.allowInstructionAssignment || '';
      state.rbacDetails.crrUnpaidHardStop = 
        action.payload.crrUnpaidHardStop || ''
      state.rbacDetails.crrUnpaidSoftStop = 
        action.payload.crrUnpaidSoftStop || '';
      state.rbacDetails.saveAsDraft = 
        action.payload.saveAsDraft || '';
      state.rbacDetails.snoozeCUD = 
        action.payload.snoozeCUD || '';
      state.rbacDetails.overStayApproveReject = 
        action.payload.overStayApproveReject || '';  
      state.rbacDetails.showPlatformFees =
      action.payload.showPlatformFees || '';
      state.rbacDetails.crrLevelOne =
      action.payload.crrLevelOne || '';
      state.rbacDetails.crrLevelTwo =
      action.payload.crrLevelTwo || '';
      state.rbacDetails.crrLevelThree =
      action.payload.crrLevelThree || '';
      state.rbacDetails.crrLevelFour =
      action.payload.crrLevelFour || '';
      state.rbacDetails.crrLevelFive =
      action.payload.crrLevelFive || '';
      state.rbacDetails.receiptDashboard =
      action.payload.receiptDashboard || '';
      state.rbacDetails.mappingDashboard = 
      action.payload.mappingDashboard
    },
    updateLinerDataCountry: (state, action: PayloadAction<any>) => {
      state.linerDataCountry = action.payload;
    },
    updateLinerCountryList: (state, action: PayloadAction<any>) => {
      state.countryList = action.payload;
    },
    updatelinerGrpCountry: (state, action: PayloadAction<any>) => {
      state.linerGrpCountry = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserProfile.pending, (state, action: any) => {
      //  state.dataLoading = true;
      //  state.profile = action?.payload?.data || {} ;
    });
    builder.addCase(getUserProfile.fulfilled, (state, action: any) => {
      //   state.dataLoading = false;

      state.profile = action?.payload?.data || {};
    });
    builder.addCase(getUserProfile.rejected, (state, action) => {
      // state.dataLoading = false;
    });
    builder.addCase(getUserGridsDefinition.fulfilled, (state, action) => {
      state.userGridDefinitions = action.payload;
    });
    builder.addCase(getRbacDetails.rejected, (state, action) => {
      // state.dataLoading = false;
    });
    builder.addCase(getRbacDetails.fulfilled, (state, action: any) => {
      state.data = action.payload;
    });
  },
});

export const {
  updateProfile,
  updateRbacDetails,
  updateLinerDataCountry,
  updateLinerCountryList,
  updatelinerGrpCountry,
} = userDetailsSlice.actions;

export default userDetailsSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import {
  updateBanks,
  updatePymtData,
  updatePymtModeOptions,
  updatesearchFiltter,
  paymentLoader,
} from '../slices/blPaymentSlice';
import axiosOdex, { axiosCacheInstance } from '../../../../axiosSetup';
import { toast } from 'react-toastify';
import { updatePymtRefNo } from '../../payment/paymentSlice';
import { AESDecrypt } from '../../../../encrypt-util';
import { StandaloneInvoiceDetails } from '../slices/standaloneInvoicePaymentSlice';
import { RootState } from '../../../store';
const formatNumber = (value: string): string => {
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) return value; // Return the original value if it's not a valid number

  const roundedValue = parseFloat(parsedValue.toFixed(2)); // Round to two decimal places
  const [integerPart, decimalPart] = roundedValue.toString().split('.');
  const formattedInteger = parseInt(integerPart, 10).toLocaleString();
  const formattedDecimal = decimalPart ? `.${decimalPart}` : '';
  return `${formattedInteger}${formattedDecimal}`;
};
export const getPaymentBanks = createAsyncThunk(
  'paymentbankdropdown/getPaymentBanks',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        '/api/user-management/v1/partners/getPartnerBankAccountDetails?accountCurrency='
      );
      /* const options=[{
      value:response.data.map((item:any)=>`${item.bankName}--Account ${item.accountName}`),text:response.data.map((item:any)=>`${item.bankName}--Account ${item.accountName}`)
     }] */
      const options = response.data.map((item: any) => ({
        value: item.mstAccountId,
        text: item.accountAlias,
        bankName: item.bankName,
        accountNo: item.accountNo,
      }));
      /*    const options = [
            { value: '', text: '--select Bank--' },
            { value: 'bankofAmerica', text: 'Bank of America -- Account 1578688 ' },
            { value: 'citiGroup', text: 'Citi Group -- Account 1578645 ' },
            { value: 'UsBankCrop', text: 'U.S.Bancrop -- Account 1578645' },
          ]; */
      dispatch(updateBanks(options));
      return options;
      // return {
      //   ...response.data,
      // };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);
      return error;

      // return {
      //   next: null,
      //   previous: null,
      //   list: [],
      // };
    }
  }
);

export const submitPaymentBanks = createAsyncThunk(
  '',
  async (state: any, { dispatch }) => {
    dispatch(paymentLoader(true));
    try {
      const response = await axiosOdex.post(
        '/api/invoice-management/v1/payment/submit',
        {
          ...state,
          //  linerId: state?.linerId,
        }
      );
      if (response?.data?.message) {
        toast.success(response.data.message, {
          theme: 'colored',
        });
      } else {
        toast.error('Something went wrong', {
          theme: 'colored',
        });
      }
    } catch (e) {
      const error = e as any;

      console.error('errorrrs', error.message);
      toast.error(`${Object.values(error?.response?.data?.errors)[0]}`, {
        theme: 'colored',
      });
      dispatch(paymentLoader(false));
      return error;
    }
  }
);

export const addInvoiceToExistingPayment = createAsyncThunk(
  '',
  async (state: any, { dispatch }) => {
    // debugger
    // dispatch(paymentLoader(true));
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/payment/addInvoiceToExistingPayment`,
        {
          ...state,
        }
      );
      if (response) {
        setTimeout(() => {
          toast.success(`${Object.values(response?.data)[0]}`, {
            theme: 'colored',
          });
        }, 1500);
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
    }
  }
);

export const checkDuplicateReferenceNo = createAsyncThunk(
  '',
  async (state: any, { dispatch }) => {
    // debugger
    // dispatch(paymentLoader(true));
    /*    try {
      const response = await axiosOdex.get(
        `/api/invoice-management/v1/payment/checkDuplicateReferenceNoExist?refNo=${state.inputValue}&currency=${state.selectedCcy}`
      );
      if (
        `${Object.values(response?.data)[0]}`.includes(
          'Duplicate Reference No Does Not Exist.'
        )
      ) {
   
        return true;
      } else if (
        `${Object.values(response?.data)[0]}`.includes(
          'Duplicate Reference No Exist for same Customer'
        )
      ) { */
    /*   toast.error(`${Object.values(response?.data)[0]}`, {
          theme: 'colored',
        }); */
    //  return Object.keys(response?.data)[0];
    //return response.data;
    //  }
    try {
      const response = await axiosOdex.get(
        `/api/invoice-management/v1/payment/checkDuplicateReferenceNoExist?refNo=${state.inputValue}&currency=${state.selectedCcy}`
      );
      if (
        `${Object.values(response?.data)[0]}`.includes('different Currency')
      ) {
        toast.error(`${Object.values(response?.data)[0]}`);
      } else {
        if (
          `${Object.values(response?.data)[0]}`.includes(
            'Payment Reference No. does Not exists.'
          )
        ) {
          /*   toast.success(`${Object.values(response?.data)[0]}`, {
            theme: 'colored',
          }); */
          return true;
        } else if (
          `${Object.values(response?.data)[0]}`.includes(
            'Payment Reference No. already exists.'
          )
        ) {
          toast.error(`${Object.values(response?.data)[0]}`, {
            theme: 'colored',
          });
          //  return Object.keys(response?.data)[0];
          // return response.data;
        } else if (
          `${Object.values(response?.data)[0]}`.includes(
            'Payment Reference No. already in use. Provide different Reference No.'
          )
        ) {
          toast.error(`${Object.values(response?.data)[0]}`, {
            theme: 'colored',
          });
        }
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
      dispatch(paymentLoader(false));
      // return false;
    }
  }
);

export const updatePaymentReferenceNo = createAsyncThunk(
  '',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/payment/updatePaymentReferenceNo?pmtId=${state.selectedPymtId}&newRefNO=${state.inputValue}&currency=${state.selectedCcy}&amount=${state.pymtAmt}`
      );
      if (response) {
        toast.success(`${Object.values(response?.data.message).join('')}`, {
          theme: 'colored',
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      // return false;
    }
  }
);

export const cancelPayment = createAsyncThunk(
  'payment/cancelPayment',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/payment/cancelPayment?pmtId=${state.pmtId}&currency=${state.currency}`
      );
      if (response) {
        toast.success(`${Object.values(response?.data)[0]}`, {
          theme: 'colored',
        });
        /*   setTimeout(() => {
        }, 2000); */
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      // return false;
    }
  }
);
export const rejectPayment = createAsyncThunk(
  'payment/rejectPayment',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/payment/rejectPayment?pmtId=${state.pmtId}&currency=${state.currency}&remarks=${state.rejectionMsg}`
      );
      if (response) {
        toast.success(`${Object.values(response?.data)[0]}`, {
          theme: 'colored',
        });
        /*      setTimeout(() => {
          window.location.href = '/payment';
        }, 2000); */
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      // return false;
    }
  }
);

export const acceptPayment = createAsyncThunk(
  'payment/acceptPayment',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        // `/api/invoice-management/v1/payment/approvePayment`,
        `/api/invoice-management/v1/payment/approvePaymentByLiner?pmtId=${state.pmtId}&bnkRefNo=${state.bnkRefNo}&currency=${state.currency}&amount=${state.amount}&linerId=${state.linerId}&country=${state.country}`
      );
      if (Object.entries(response?.data)[0] != null) {
        toast.success('Payment Approved successfully', {
          theme: 'colored',
        });
        /*    setTimeout(() => {
          window.location.href = '/payment';
        }, 2000); */
      } else {
        toast.error(`Entered Bank Reference number is not available`, {
          theme: 'colored',
        });
        /*    setTimeout(() => {
          window.location.href = '/payment';
        }, 2000); */
        // setTimeout(() => {
        //   if (window.location.pathname.includes('blsliderpayment')) {
        //     window.location.href = '/payment';
        //   } else {
        //     window.location.href = '/home';
        //   }
        // }, 3000);
      }
      // if (response) {
      //   toast.success(`${response?.data[0].message}`, {
      //     theme: 'colored',
      //   });
      //   setTimeout(() => {
      //     window.location.href = '/payment';
      //   }, 2000);
      // }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      // return false;
    }
  }
);
export const checkDuplicateReferenceNoByPymtId = createAsyncThunk(
  '',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.get(
        `/api/invoice-management/v1/payment/checkDuplicateReferenceNoExistByPmtId?refNo=${state.inputValue}&currency=${state.selectedCcy}&pmtId=${state.selectedPymtId}`
      );

      if (
        `${Object.values(response?.data)[0]}`.includes('different Currency')
      ) {
        toast.error(`${Object.values(response?.data)[0]}`);
        // return response.data;
      } else {
        if (
          `${Object.values(response?.data)[0]}`.includes(
            'Payment Reference No. does Not exists.'
          )
        ) {
          /*   toast.success(`${Object.values(response?.data)[0]}`, {
            theme: 'colored',
          }); */

          //  debugger

          return true;
        } else if (
          `${Object.values(response?.data)[0]}`.includes(
            'Payment Reference No. already exists.'
          ) ||
          `${Object.values(response?.data)[0]}`.includes(
            'Payment Reference No. already in use. Provide different Reference No.'
          )
        ) {
          toast.error(`${Object.values(response?.data)[0]}`, {
            theme: 'colored',
          });
          //  return Object.keys(response?.data)[0];
          // return response.data;
        }
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
      dispatch(paymentLoader(false));
      // return false;
    }
  }
);
export const getPymtIdByPmtRefNo = createAsyncThunk(
  '',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.get(
        `/api/invoice-management//v1/payment/${state.inputValue}?pmtMode=${state.paymentMode}`
      );
      return response;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);

      // return false;
    }
  }
);

export const submitPaymentAddFund = createAsyncThunk(
  'Payment/addFund',
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        '/api/invoice-management/v1/payment/addFund',
        {
          pmtReference: payload?.pmtReference,
          pmtMode: parseInt(payload?.pmtMode),
          currency: payload?.currency,
          pmtProofAtt: payload?.pmtProofAtt || '',
          payorBankName: payload?.payorBankName || '',
          payorAccountId: payload?.payorAccountId || '',
          pmtStatus: payload?.pmtStatus || '',
          pmtType: payload?.pmtType || '',
          pmtAmount: parseInt(payload?.pmtAmount) || null,
          linerAccountId: 1,
          linerId: payload.linerId,
        }
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error('Error: ', error);

      return { success: false, data: error.response?.data?.message || '' };
    }
  }
);

export const getPymtModeOptions = createAsyncThunk(
  'pymtModedropdown/getPymtModeOptions',
  async (payload: any, { dispatch }) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=PYMT_MODE&linerId=${payload.selectedLinerId}&countryCode=${payload.partnerCountry}`,
        {
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
          cache: {
            ttl: 10 * 60 * 1000,
          },
        }
      );

      const pymtModeOptions = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));

      dispatch(updatePymtModeOptions(pymtModeOptions));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getPymtDetailsByPymtNo = createAsyncThunk(
  'payment/getPymtDetailsByPymtNo',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.get(
        `/api/invoice-management/v1/payment/getPaymentById/${AESDecrypt(
          state.pymtId
        )}`
      );
      dispatch(updatePymtData(response.data));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getPymtRefNo = createAsyncThunk(
  'payment/PaymentRefNo',
  async (payload: any, { dispatch }) => {
    const csrfMeta =
      document.querySelector<HTMLMetaElement>("meta[name='_csrf']");
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/payment/generatePmtReferenceNoByCountry?countryCode=${payload?.partnerCountry}`,
        {
          'X-CSRF-TOKEN': csrfMeta?.content || '',
          'Content-Type': 'application/x-www-form-urlencoded',
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      dispatch(updatePymtRefNo(response.data));
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
      return error;
    }
  }
);

export const updateBankPymtRefNo = createAsyncThunk(
  'payment/updateBankPymtRefNo',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/payment/updatePaymentRefNo?pmtId=${state.pmtId}&newRefNO=${state.newRefNO}`,
        {
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      // toast.success(response.data[0].message, {
      //   theme: 'colored',
      // });
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
      return error;
    }
  }
);

export const editBankPymtRefNo = createAsyncThunk(
  'payment/editBankPymtRefNo',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/payment/editPayment`,
        state,
        {
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
        }
      );
      toast.success(response.data.message, {
        theme: 'colored',
      });
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
      return error;
    }
  }
);
export const getSearchFiltter = createAsyncThunk(
  'searchFiltter/getSearchFiltter',
  async (partnerCountry: any, { dispatch, getState }: any) => {
    const state: RootState = getState();
    try {
      const response = await axiosCacheInstance.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=SEARCH_FILTER&linerId=${state.navbarTop.linerId}&countryCode=${partnerCountry}`,
        {
          headers: {
            Authorization:
              'Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev',
          },
          cache: {
            ttl: 10 * 60 * 1000,
          },
        }
      );

      const searchFiltter = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));

      dispatch(updatesearchFiltter(searchFiltter));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const submitAutoPayment = createAsyncThunk(
  '',
  async (state: any, { dispatch }) => {
    dispatch(paymentLoader(true));
    try {
      const response = await axiosOdex.post(
        '/api/invoice-management/v1/payment/submitPayment',
        {
          ...state,
          //  linerId: state?.linerId,
        }
      );
      if (response?.data?.message) {
        toast.success(response.data.message, {
          theme: 'colored',
        });
      } else {
        toast.error('Something went wrong', {
          theme: 'colored',
        });
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
      dispatch(paymentLoader(false));
      return error;
    }
  }
);

export const validateStandaloneInvoiceForPayment = createAsyncThunk(
  'validateStandaloneInvoiceForPayment',
  async (state: StandaloneInvoiceDetails) => {
    try {
      const response = await axiosOdex.post(
        '/api/invoice-management/v1/invoice/validateInvoiceDetailsForPayment',
        {
          invoiceId: null,
          invoiceNo: state.invoiceNo || null,
          invoiceAmount: state.invoiceAmount || null,
          invoiceCurrency: state.invoiceCurrency || null,
        }
      );
      return {
        ...response?.data,
        rowNumber: state.rowNumber,
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
      return error;
    }
  }
);

export const addStandaloneInvoice = createAsyncThunk(
  'addStandaloneInvoice',
  async (state: StandaloneInvoiceDetails[]) => {
    try {
      const response = await axiosOdex.post(
        '/api/invoice-management/v1/invoice/addStandaloneInvoice',
        state.map((invoice: StandaloneInvoiceDetails) => {
          return {
            invoiceId: invoice.invoiceId,
            invoiceNo: invoice.invoiceNo,
            invoiceAmount: invoice.invoiceAmount,
            invoiceCurrency: invoice.invoiceCurrency,
          };
        })
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
      return error;
    }
  }
);

export const getCurrencyDDList = createAsyncThunk(
  'getCurrencyDDList',
  async () => {
    try {
      const response = await axiosOdex.get(
        '/api/user-management/v1/country/getCurrencyDDList'
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: 'colored',
      });
      return error;
    }
  }
);

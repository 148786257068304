import { useTranslation } from 'react-i18next';
import DataTable from '../../common/components/DataTable';
import { AppDispatch, RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { selectCustomerCode } from './MappingSlice';


const AddCustomerCodeList = ({disabled}:{disabled:boolean;}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>()
  const data = useSelector((state:RootState)=>state.mappingDashboard.codeSearchListData)
  const linerOptions = useSelector((state:RootState)=>state.registration.linerOptions)

  const columnConfig = [
    {
      accessor: 'selected',
      Header: '',
      width: 50,
      maxWidth: 50,
      minWIdth: 50,
      Cell: (rowData: any) => {
        const { selected, id } = rowData.row.original;
        return <Form.Check
        type='checkbox'
        checked={selected}
        disabled={disabled}
        onChange={(e)=>{
          dispatch(selectCustomerCode({id, val: e.target.checked}))
        }}
        className='form-check fs-0 mb-0 d-flex align-items-center'/>
      }
      

    },
    {
      accessor: 'name',
      Header: t('resource_2:companyname'),
      width: 180,
      maxWidth: 180,
    },
    {
      accessor: 'customerCode',
      Header: t('resource_1:customercode'),
      width: 120,
      maxWidth: 180,
    },
    {
      accessor: 'matchCode',
      Header: 'Match Code',
      width: 120,
      maxWidth: 180,
    },
    {
      accessor: 'taxId',
      Header: t('resource_2:taxId'),
      width: 120,
      maxWidth: 180,
    },
    {
      accessor: 'country',
      Header: t('Country'),
      width: 70,
      maxWidth: 80,
    },
    {
      accessor: 'address',
      Header: t('resource_1:address'),
      width: 220,
      maxWidth: 220,
    },
  ];

  return (
    <div className='col-lg-12 mt-2 mb-2 demmurageTable d-flex flex-column'>
      { data !== null &&
      <DataTable
        gridName='SEARCHCODELIST'
        columns={columnConfig}
        data={data}
        shortPrepend='qp_'
        perPage={100}
        sortable={false}
        searchable={false}
        showColumnFilter={false}
        selection={false}
        pagination={false}
        seekPagination={false}
        showExport={false}
        localFilter={true}
        uniqueFilterKey='userList'
        autoHeight={true}
        maxHeight='35vh'
        tableMinHeight={300}
        staticHiddenCols={[]}
      />
      }

    </div>
  );
};

export default AddCustomerCodeList;

import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Image,
} from 'react-bootstrap';
import infiniteLoop from '../../assets/img/animated-icons/infinite-loop.json';
import dataTableBgImg from '../../assets/img/illustrations/cornerRight-1.png';
import dataTableBgImgLeft from '../../../src/assets/img/illustrations/corner-1.png';

import { Link, useNavigate } from 'react-router-dom';
// import axiosInstance from '../../../../axiosSetup';
import LoginBanner1 from '../assets/images/loginbannerZA200624.png';
import LoginBanner2 from '../assets/images/loginBannerZA1.png';
// import { checkLoginAuth } from '../../../loginSlice';

import bg1 from '../../assets/images/landing-bg.jpeg';
import { useDispatch, useSelector } from 'react-redux';
// import { AppDispatch,RootState } from '../../store';

import { faFile } from '@fortawesome/free-regular-svg-icons';
import {
  faBagShopping,
  faCartPlus,
  faCheck,
  faLock,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoOne from '../../assets/images/logo.png';
import Axios from '../../axiosSetup';
import { AxiosError } from 'axios';
import { RootState } from '../store';
import axiosInstance from '../../axiosSetup';
import { checkLoginAuth } from '../features/registration/loginSlice';
import { getUserProfile } from '../common/actions/userDetails';
import PrivacyPolicy from '../features/registration/privacyPolicy';
import { useTranslation } from 'react-i18next';
import TermsAndConditions from '../features/registration/termsAndCondition';
import LoginForm from '../features/registration/LoginForm';
import { toast } from 'react-toastify';
import { HmacSHA3 } from 'crypto-js';
import AuthCardLayout from '../../layouts/AuthCardLayout';
import Lottie from 'lottie-react';

const Commonapp = () => {
  var resval = 'no';
  const navigate = useNavigate();

  const dispatch = useDispatch();
  var hasLabel = false;
  const selectedBls = useSelector((state: RootState) => state);

  const getToken = () => {
    const querySelector: (Element & { content: string }) | null =
      document.head.querySelector('[name=_csrf]');
    return querySelector ? querySelector.content : '';
  };
  const handleClick = (url: any) => {
    window.location.replace(process.env.REACT_APP_API_GATEWAY_HOST + url);
  };

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  interface AppOptions {
    name: string;
    desc: string;
    url: string;
    // Add other relevant fields if needed
  }
  /*  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(
        '/api/user-management/v1/users/getLoginUserDetails',
        {}
      );

      dispatch(checkLoginAuth({ success: 'true' }));
    } catch (error) {
      dispatch(checkLoginAuth({ success: 'false' }));

      console.error(error);
    }
  }; */
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //const registerUrl = `${process.env.REACT_APP_CLIENT_APP_HOST}/register`;
  const windowLoc = window.location.href.indexOf('.odexglobal.com');
  const windowurl = window.location.href.substring(0, windowLoc);
  const [forgotPassword, setForgotPassword] = useState(false);
  const registerUrl = `${process.env.REACT_APP_CLIENT_APP_HOST}/register`;
  const forgotPasswordUrl = `${process.env.REACT_APP_CLIENT_APP_HOST}/forgotPassword`;
  const [showPolicy, setShowPolicy] = useState(false);
  const handleClosePolicy = () => setShowPolicy(false);
  const handleShowPolicy = () => setShowPolicy(true);
  const [appOptions, setAppOptions] = useState<AppOptions[]>([]);
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [selectedApp, setSelectedApp] = useState('ODeX');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/app/appList`
        );
        setAppOptions(response.data);
        console.log('respBl', response);
        if (response.data.length == 0) {
//           toast.error('You do not have access!', {
//             position: toast.POSITION.TOP_CENTER,
//             theme: 'colored',
//             autoClose: 5000,
//           });

             navigate('/home');
//           window.location.href = '/home';
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEmail = async () => {
      try {
        const response = await Axios.post(
          'api/user-management/v1/users/getLoginUserDetails'
        );
        setUsername(response.data.username);
        setEmail(response.data.email);
        console.log('emailll', email);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    fetchEmail();
  }, []);

  if (appOptions.length == 1) {
    //navigate(appOptions[0].url);
    window.location.href = appOptions[0].url;
  }

  if(appOptions.length < 2){// loading screen
    return <div style={{width:'100vw', height:'100vh'}} className='
    d-flex align-items-center justify-content-center'>
      <div style={{width:'100vw', height:'100vh', background:'#ffffff', position: 'absolute', zIndex:0}} className='
    d-flex align-items-center justify-content-center'></div>
      
      <style>
        {`
        @keyframes fade{
          0%{
            opacity: 0;
          },
          66%{
            opacity: 0;
          },
          100%{
            opacity: 1;
          }
        }
        .fadeIn{
          animation: fade 3s;
        }
        `}
      </style>
      <Card className='d-flex align-items-center justify-content-center p-4 col-lg-5 fadeIn'>
      <Form.Label className='pb-0'>Welcome to Odex</Form.Label>
          <Lottie
            animationData={infiniteLoop}
            loop={true}
            style={{
              height: '20vh',
              width: '20vh',
              alignSelf: 'center',
              justifySelf: 'center',
            }}
          />
          
      </Card>    
    </div>
  }
  return (
    <>
      {appOptions.length === 2 && (
        <AuthCardLayout>
          {/* button animation starts here  */}

          {/* button animation ends here */}

          <style>
            {`
          .pc-checkout-button {
          background-color: #0484cc;
            color: white;
            transition: transform 0.3s ease, background-color 0.3s ease;
            position: relative;
            overflow: hidden;
          
          }

          .pc-checkout-button:hover {
            transform: scale(1.05);
            background-color:#f5822a;
            color: white;
          }

          .pc-checkout-button:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.2);
            transition: opacity 0.3s ease;
            opacity: 0;
            z-index: 0;
          }

          .pc-checkout-button:hover:before {
            opacity: 1;
          }

          .pc-checkout-button span {
            position: relative;
            z-index: 1;
          }
           
        `}
          </style>

          <Card
            className='  bg-card bg-holder'
            style={{
              backgroundImage: `url(${dataTableBgImgLeft})`,
              backgroundSize: 'cover',
              borderTopRightRadius: '1rem',
              borderBottomRightRadius: '0.375rem',
              paddingTop: '40px',
              backgroundPosition: '40px center',
              //height: '150  %',
              //width: '100%'
            }}
          >
            <img src={LogoOne} className='logoWidth pb-7 ' />

            {/* <Card.Body className='p-4 bodyHeight'>
                  {/* <LoginForm /> 
                </Card.Body> */}
            <Card.Body>
              <h3
                style={{ fontFamily: 'Arial, serif', textAlign: 'center' }}
                className='mb-3'
              >
                Welcome to ODeX
              </h3>
              <h6 className='text-center mb-5 '>
                <span>{email}</span>
              </h6>{' '}
              <p className='text-center mb-4'>
                Choose an application to continue
              </p>
              <Form.Group className='mb-1'>
                {hasLabel && (
                  <Form.Label htmlFor='floatingInput'>Email address</Form.Label>
                )}
                {/* <Form.Control
                      placeholder={email}
                      name='username'
                      type='email'
                      className='fs--1 inputBorder'
                      disabled // Add disabled prop to disable the input
                    /> */}
              </Form.Group>
              <Form.Group>
                <div className='d-flex flex-column align-items-center'>
                  {appOptions.map((item, index) => (
                    <Button
                      key={index}
                      type='button'
                      className='e-round-corner w-200 mb-2 pc-checkout-button'
                      style={{
                        fontSize: '16px',
                        padding: '10px',
                        marginBottom: '-15px',
                        borderRadius: '50px',
                        width: '42%',
                      }}
                      onClick={() => {
                        setSelectedApp(item.name);
                        handleClick(item.url);
                      }}
                    >
                      {item.desc}
                    </Button>
                  ))}
                </div>
              </Form.Group>
              {/* <div className='d-flex justify-content-center'>
                    <Button
                      type='button'
                      color='primary'
                      className='mt-2 me-2 w-100 btn-info'
                      onClick={() => handleClick(appOptions.find(option => option.name === selectedApp)?.url)}
                    >
                      Continue
                    </Button>
                  </div> */}
            </Card.Body>
            {/* <Card.Footer className='form-footer mb-3'>
                  <Row className='justify-content-between align-items-center'> */}
            {/*<Form.Check
                        type='checkbox'
                        id='rememberMe'
                        className='mb-0'>
                        <Form.Check.Input type='checkbox' name='remember' />
                        <Form.Check.Label className='mb-0 text-700'>
                          Remember me
                        </Form.Check.Label>
  </Form.Check>*/}
            {/* <Col xs='auto'>
                      <a className='fs--1 mb-0 link' href={forgotPasswordUrl}>
                        <span className='iconBody'>
                          <FontAwesomeIcon
                            className='icon fs--1'
                            icon={faLock}
                            size='lg'
                          />
                        </span>
                        Forgot Password?
                      </a>
                    </Col>
                    <Col xs='auto'>
                      <a className='fs--1 mb-0 link' href={registerUrl}>
                        <span className='iconBody'>
                          <FontAwesomeIcon
                            className='icon fs--1'
                            icon={faCheck}
                            size='lg'
                          />
                        </span>
                        Register Now
                      </a>
                    </Col>
                    <Col xs='auto'>
                      <Link
                        className='fs--1 mb-0 link'
                        to=''
                        onClick={handleShow}
                      >
                        <span className='iconBody'>
                          <FontAwesomeIcon
                            className='icon fs--1'
                            icon={faFile}
                            size='lg'
                          />
                        </span>
                        Terms & Conditions
                      </Link>
                    </Col>
                    <Col xs='auto'>
                      <Link
                        className='fs--1 mb-0 link'
                        to=''
                        onClick={handleShowPolicy}
                      >
                        <span className='iconBody'>
                          <FontAwesomeIcon
                            className='icon fs--1'
                            icon={faSearchPlus}
                            size='lg'
                          />
                        </span>
                        Privacy Policy
                      </Link>
                    </Col>
                    <Col xs='auto'>
                      <a className='fs--1 mb-0 link' href={registerUrl}>
                        <span className='iconBody'>
                          <FontAwesomeIcon
                            className='icon fs--0'
                            icon={faCartPlus}
                            size='lg'
                          />
                        </span>
                        <span>
                          {' '}
                          <span>Quick Pay</span>{' '}
                        </span>
                      </a>
                    </Col>
                  </Row>
                </Card.Footer> */}
            <div className='col-12 fs--1 copyWriteText mb-4'>
              &copy; 2018 - {new Date().getFullYear()} EA Technologies. All
              rights reserved
            </div>
          </Card>
          </AuthCardLayout>
      )}
    </>
  );
};
export default Commonapp;

import {
  faCheck,
  faLocation,
  faRedo,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import { useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.min.css';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import 'react-dropdown/style.css';

import { InstructionList } from '../../dashboard/types';
import {
  addCarrierHaulage,
  approveCarrierHaulage,
  getCarrierHaulage,
  rejectCarrierHaulage,
  rejectInstruction,
} from './actions/Instruction';
import { updateBlDetailsRowColumns } from '../../blDetails/slices/blDetailsTable';
import { toggleDocumentHistoryColumnShort } from '../../blDetails/slices/documentHistorySlice';
import { enableCarrierHaulage } from '../../standingInstruction/slices/standingInstructionTable';
import { useAsyncDebounce } from 'react-table';
import { fetchAddressSuggestions2 } from '../../dashboard/actions/instruction';
import { updateSelectedAddressResult } from './instructionSlice/CarrierHaulageSlice';
import {
  faCheckCircle,
  faCheckSquare,
  faTimesCircle,
  faTimesRectangle,
} from '@fortawesome/free-regular-svg-icons';
import { AESDecrypt } from '../../../../encrypt-util';
import { getInstructionList } from '../../dashboard/actions/instruction';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import i18next from 'i18next';
import { profile } from 'console';
import { commonApproveReject } from '../../dashboard/actions/instruction';
import { updateDashbaordRefresh, updateSortStateDataTable } from '../../../common/components/DataTable/slices/DataTable';
import { getDateTimeFormat } from '../../../../helpers/dateFormat';

import { useBackNavigation } from '../../../../hooks/BackNavigation';
import useDataCountry from '../../../../hooks/useDataCountry';
import SnoozedToButton from '../../instructionDashboard/components/SnoozedTo';

const CarrierHaulage = () => {
  const data = useSelector((state: RootState) => state.carrierHaulage.data);
  const carrierHaulageCreateMessage = useSelector(
    (state: RootState) => state.carrierHaulage.carrierHaulageCreateMessage
  );
  const onBack = useBackNavigation();
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const dataCountry = useDataCountry();

  const { t } = useTranslation();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { blNum, encrypt, encryptdoc, blId } = useParams();
  const decrptdoc = AESDecrypt(encryptdoc);
  const decrptins = AESDecrypt(encrypt);
  const docType = decrptins;

  const docId = location.pathname.split('/')[4];
  const rowColumns = useSelector(
    (state: RootState) => state.blDetailsTable.rowColumns
  );
  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  );
  const instructionIdurl = useSelector(
    (state: RootState) => state.documentHistory.instructionIdurl
  );

  /*  const dataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  ); */
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  const blEventId_docInsDashboard:any = useSelector(
    (state: any) => state.dashboard.instructionDashboardList.result.filter((Item:any)=> Item.bl_id == AESDecrypt(blNum) )[0]?.bl_event_id
  );

  const disabledPath = [
    {
      path: `/bl/:blNum/:encrypt/:encryptdoc`,
      condition: location.pathname.split('/')[3] !== 'instruction',
    },
    {
      path: `/instruction/bl/:blNum/:encrypt/:encryptdoc`,
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    {
      path: `/payment/bl/:blNum/:encrypt/:encryptdoc`,
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    { path: `/instruction/:blNum/:encrypt/:encryptdoc` },
  ].some(({ path, condition }: { path: string; condition?: boolean }) => {
    return (
      (condition ?? true) &&
      matchPath({ path, caseSensitive: true, end: true }, location.pathname)
    );
  });

  useEffect(() => {
    if (decrptdoc && selectedLinerId) {
      dispatch(
        getCarrierHaulage({
          decrptdoc,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      );
    }
  }, [decrptdoc, selectedLinerId]); //[decrptdoc]

  const _onCloseForm = () => {
    function extractValuesFromUrl(url: any) {
      const params = new URLSearchParams(url);
      const sortParam = params.get('sort');

      if (sortParam) {
        const arr: any = sortParam.split('%2C');
        return arr;
      } else {
        return [];
      }
    }

    if (decrptins == '801') {
      dispatch(toggleDocumentHistoryColumnShort(false));
      setTimeout(() => {
        onBack();
        if (selectedRouter === 'instruction' && window.location.search) {
          const splitedval = extractValuesFromUrl(
            window.location.search
          )[0].split(',');
          setTimeout(() => {
            dispatch(
              updateSortStateDataTable({
                key: splitedval[0],
                value: splitedval[1],
              })
            );
          }, 100);
        }
      }, 500);
    } else {
      dispatch(enableCarrierHaulage(false));
    }
  };

  const [deliveryOption, setDeliveryOption] = useState('');
  const [status, setStatus] = useState('');
  const [warehouseName, setWarehouseName] = useState('');
  //const [customerCode, setCustomerCode] = useState('');
  const [refPoNo, setReferencePoNumber] = useState('');
  const [address, setAddress] = useState('');
  const [contactName, setContactName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [remark, setRemark] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [rejectionremark, setRejectionremark] = useState('');
  const [rejectionstatus, setrejectionstatus] = useState('');
  const [carrierHaulageId, setCarrierHaulageId] = useState('');
  const userName = profileDetails?.partnerType;
  const [placeId, setPlaceId] = useState('');
  const [deliveryDateTime, setDeliveryDateTime] = useState('');
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [country, setCountry] = useState('');
  const [state, setState] = useState<any>({
    rejectionRemark: '',
    remark: '',
    senderName: userName,
    deliveryOption: '',
    warehouseName: '',
    address: '', //Delivery Address//streetNumber:'', //streetAddress:'', //suitNumber:'',//zipcode:''
    city: null,
    cityInput: '',
    //Appointment Contact Information
    refPoNo: '',
    contactName: '',
    telephone: '',
    telephone2: '',
    emailID: '',
    additionalEmailID: '',
    carrierHaulageId: '',
    areaCode: '',
    placeId: '',
  });
  const selectedDataCarrier = useSelector(
    (state: RootState) => state.carrierHaulage.data
  );
  const setInitialValue = () => {
    if (decrptdoc) {
      // const selectedDataCarrier = data?.list?.find?.(
      //   (i: any) => i?.blId === blId
      // );
      setDeliveryOption(selectedDataCarrier?.deliveryOption || '');

      setWarehouseName(selectedDataCarrier?.warehouseName || '');
      setStatus(selectedDataCarrier?.status || '');
      // setCustomerCode(selectedDataCarrier?.customerCode || '');
      setReferencePoNumber(selectedDataCarrier?.refPoNo || '');
      setAddress(selectedDataCarrier?.address || '');
      setContactName(selectedDataCarrier?.contactPerson || '');
      setEmail(selectedDataCarrier?.emailId || '');
      setContactNo(selectedDataCarrier?.contactNo || '');
      setRemark(selectedDataCarrier?.remarks || '');
      setRejectionremark(selectedDataCarrier?.rejectionRemarks || '');
      setrejectionstatus(selectedDataCarrier?.status || '');
      setCarrierHaulageId(selectedDataCarrier?.carrierHaulageId || '');
      setAreaCode(selectedDataCarrier?.areaCode || '');
      setDeliveryDateTime(selectedDataCarrier?.deliveryDate || '');
      if (selectedDataCarrier?.deliveryDate) {
        setDeliveryDate(
          new Date(selectedDataCarrier?.deliveryDate) || new Date()
        );
      }

      setCountry(
        selectedDataCarrier?.country || profileDetails.partnerCountry || ''
      );
    }
  };
  useEffect(() => {
    setInitialValue();
  }, [location.pathname, data]);

  useEffect(() => {
    setCountry(profileDetails.partnerCountry || '');
  }, [location.pathname]);

  const resetForm = () => {
    setDeliveryOption('');
    setWarehouseName('');
    //setCustomerCode('');
    setReferencePoNumber('');
    setAddress('');
    setContactName('');
    setEmail('');
    setContactNo('');
    setRemark('');
    setRejectionremark('');
    setAreaCode('');
    setPlaceId('');
    setDeliveryDateTime('');
  };

  useEffect(() => {
    // if( matchPth || window.localStorage.getItem('@odex/activeuser') === 'liner'){

    if (decrptdoc) {
      setState({
        decrptdoc: decrptdoc,
        deliveryOption: selectedDataCarrier?.deliveryOption,
        warehouseName: selectedDataCarrier?.warehouseName,
        // customerCode: selectedDataCarrier?.customerCode,
        contactPerson: selectedDataCarrier?.contactPerson,
        emailId: selectedDataCarrier?.emailId,
        contactNo: selectedDataCarrier?.contactNo,
        address: selectedDataCarrier?.address,
        status: selectedDataCarrier?.status,
        remark: selectedDataCarrier?.remarks,
        rejectionremark: selectedDataCarrier?.rejectionRemarks,
        carrierHaulageId: selectedDataCarrier?.carrierHaulageId,
        areaCode: selectedDataCarrier?.areaCode,
        refPoNo: selectedDataCarrier?.refPoNo,
        deliveryDate: selectedDataCarrier?.deliveryDate,
      });
      setCountry(selectedDataCarrier?.country);
    }
  }, [location.pathname, data, blId]);

  const disableFields =
    profileDetails?.partnerType === 'liner' ||
    (!!disabledPath && profileDetails?.partnerType !== 'liner');

  const onApproveReject = (status: string, type: string) => {
    if (status && type) {
      if (
        status === 'R' &&
        (rejectionremark === '' || rejectionremark == null)
      ) {
        toast.dark(t('resource_message:remarksMandatory'));
      } else {
        dispatch(
          commonApproveReject({
            instructionId: state?.carrierHaulageId || null,
            instructionType: type || null,
            linerRemarks: rejectionremark || '',
            customerRemarks: state?.remark || null,
            status: status || null,
            emailTo: data?.emailId || null,
          })
        )
          .unwrap()
          .then((response: any) => {
            toast.success(response?.responseMsg);
            dispatch(updateDashbaordRefresh(true))
          })
          .catch((error: any) => {
            toast.error(error);
          });

        //update instruction list and redirect to previous page
        dispatch(
          getInstructionList({
            blNum: AESDecrypt(blNum),
            country: dataCountry || /*  profileDetails.partnerCountry || */ '',
          })
        );
        
        onBack();
      }
    }
  };

  type FormValues = {
    deliveryOption: string;
    warehouseName: string;
    //customerCode: string;
    refPoNo: string;
    address: string;
    contactName: string;
    email: string;
    contactNo: string;
    remark: string;
    rejectionRemark: string;
    areaCode: string;
    placeId: string;
    deliveryDateTime: any;
  };

  const validationSchema = Yup.object().shape({
    //deliveryOption: Yup.string().required('Delivery Option Name is required'),
    warehouseName: Yup.string().required('Warehouse Name is required'),
    address: Yup.string().required('Address is required'),
    contactName: Yup.string().required('Contact Name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    contactNo: Yup.string().required(' Contact No is required'),

    refPoNo:
      (country != 'ZA' && country != 'KE' && country != 'JO')
        ? Yup.string().required('Reference Po Number is required')
        : Yup.string().notRequired(),

    //placeId: Yup.string().required('Address is Not Valid'),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      'You need to accept the terms and conditions'
    ),
  });

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(
    async (payload: any) => {
      try {
        /* if (placeId != '') { */
        payload.address = address;
        var countryCode = 'USA';
        if (profileDetails?.partnerCountry === 'US') {
          countryCode = 'USA';
        } else if (profileDetails?.partnerCountry === 'SG') {
          countryCode = 'SGP';
        } else if (
          profileDetails?.partnerCountry === 'ZA' ||
          profileDetails?.partnerCountry === 'ZAF'
        ) {
          countryCode = 'ZAF';
        } else if(
          profileDetails?.partnerCountry === 'KE' ||
          profileDetails?.partnerCountry === 'KEN'
        ){
          countryCode = 'KEN'
        }else if(profileDetails.partnerCountry == 'JO'){
          countryCode = 'JOR'
        }
        dispatch(
          addCarrierHaulage({
            ...payload,
            //  blId: blList.list.find((v) => v?.number === blNum)?.id || null,
            blId: AESDecrypt(blNum),
            placeId: placeId,
            country: countryCode,
            deliveryDate: deliveryDate,
            linerId: linerId,
          })
        );

        // toast.dark(carrierHaulageCreateMessage);
        dispatch(
          getInstructionList({
            blNum: AESDecrypt(blNum),
            country: dataCountry || profileDetails.partnerCountry || '',
          })
        );
        reset();
        onBack();
        /* }  else {
          toast.error('Please select a valid Address from the suggesstion');
        } */
      } catch (err: any) {
        console.error(err.message);
      }
    },
    (error) => {}
  );

  const _onAddressChange = useAsyncDebounce((value: string) => {
    var countryCode = 'USA';
    if (profileDetails?.partnerCountry === 'US') {
      countryCode = 'USA';
    } else if (profileDetails?.partnerCountry === 'SG') {
      countryCode = 'SGP';
    } else if (
      profileDetails?.partnerCountry === 'ZA' ||
      profileDetails?.partnerCountry === 'ZAF'
    ) {
      countryCode = 'ZAF';
    } else if (profileDetails?.partnerCountry === 'KE') {
      countryCode = 'KEN';
    }else if(profileDetails.partnerCountry == 'JO'){
      countryCode = 'JOR'
    }
    dispatch(
      fetchAddressSuggestions2({
        text: value,
        country: countryCode,
      })
    );
  }, 200);

  const selectedAddressResult = useSelector(
    (state: RootState) => state.carrierHaulage.selectedAddressResult
  );

  const isAddressSuggesstionLoading = useSelector(
    (state: RootState) => state.carrierHaulage.isAddressSuggesstionsLoading
  );

  const registrationAddressSuggesstion = useSelector(
    (state: RootState) => state.carrierHaulage.registrationAddressSuggesstion
  );

  const rbac = useSelector((state: RootState) => state.userProfile);

  useEffect(() => {
    const userSelectedCountry = [
      {
        loginId: profileDetails.email,
        country:
          country || linerDataCountry || profileDetails.partnerCountry || '',
      },
    ];
    window.localStorage.setItem(
      '@user-selected-datacountry',
      JSON.stringify(userSelectedCountry)
    );
    i18next.changeLanguage(
      country || linerDataCountry || profileDetails.partnerCountry || ''
    );
  }, [country]);
  return (
    <Card>
      <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={_onCloseForm}
        />
        <h2 className='fs-0 text-primary mb-1'>
          {t('resource_3:deleveryOrderEntry')}
        </h2>
      </Card.Header>
      <Card.Body>
        <div className='container'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              {t('resource_message:carrierHaulageInstruction') != '' && (
                <div className='col-lg-12 mb-1'>
                  <Form.Label style={{ color: '#f5822a' }}>
                    {t('resource_message:carrierHaulageInstruction')}
                  </Form.Label>
                </div>
              )}
              {(country != 'ZA' && country != 'KE' && country != 'JO') && (
                <div className='col-lg-12'>
                  <div className='col-lg-6 mb-3'>
                    <Form.Group controlId='carrierHaulage.deliveryOption'>
                      <Form.Label className='fs--1'>
                        <span className='mandatory'>*</span>
                        {t('resource_2:deliveryOption')}
                      </Form.Label>
                      {disableFields ? (
                        <Form.Control
                          size='sm'
                          value={deliveryOption}
                          disabled={disableFields}
                        />
                      ) : (
                        <>
                          <Form.Select
                            {...register('deliveryOption')}
                            className={`fs--1 form-control ${
                              errors.deliveryOption ? 'is-invalid' : ''
                            }`}
                            value={deliveryOption}
                            onChange={(e) => setDeliveryOption(e.target.value)}
                          >
                            <option value=''>Select</option>
                            <option>Live Unload</option>
                            <option>Drop & Pick</option>
                          </Form.Select>
                          <div className='invalid-feedback'>
                            {errors.deliveryOption?.message?.toString()}
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </div>
                </div>
              )}
              <div className='col-lg-6 mb-3'>
                <Form.Group
                  controlId='carrierHaulage.warehouseName'
                  className='mb-1'
                >
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_2:warehouseName')}
                  </Form.Label>
                  <Form.Control
                    {...register('warehouseName')}
                    className={`fs--1 form-control ${
                      errors.warehouseName ? 'is-invalid' : ''
                    }`}
                    type='text'
                    maxLength={100}
                    onChange={(e) => setWarehouseName(e.target.value)}
                    disabled={disableFields}
                    value={warehouseName}
                  />
                  <div className='invalid-feedback'>
                    {errors.warehouseName?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='col-lg-6 mb-3'>
                <Form.Group controlId='carrierHaulage.refPoNo'>
                  <Form.Label className='fs--1'>
                    {country == 'US' ? (
                      <span className='mandatory'>*</span>
                    ) : (
                      <></>
                    )}
                    {t('resource_3:referencePoNumber')}
                  </Form.Label>
                  <Form.Control
                    {...register('refPoNo')}
                    className={`fs--1 form-control ${
                      errors.refPoNo ? 'is-invalid' : ''
                    }`}
                    type='text'
                    maxLength={30}
                    value={refPoNo}
                    onChange={(e) => setReferencePoNumber(e.target.value)}
                    disabled={disableFields}
                  />
                  <div className='invalid-feedback'>
                    {errors.refPoNo?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              {/*
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='carrierHaulage.linersCustomerCode'>
                    <Form.Label className='fs--1'>
                      {' '}
                      {t('resource_3:linersCustomerCode')}
                    </Form.Label>
                    <Form.Control
                      {...register('customerCode')}
                      type='text'
                      value={customerCode}
                      onChange={(e) => setCustomerCode(e.target.value)}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </div>*/}
              <div className='col-lg-12 mb-3'>
                <Form.Group
                  style={{ position: 'relative' }}
                  className='mb-1'
                  controlId='carrierHaulage.address'
                >
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_1:address')}
                  </Form.Label>
                  <Form.Control
                    {...register('address')}
                    className={`fs--1 form-control ${
                      errors.address ? 'is-invalid' : ''
                    }`}
                    as='textarea'
                    maxLength={200}
                    onChange={({ target }) => {
                      setAddress(target.value);
                      _onAddressChange(target.value);
                      dispatch(updateSelectedAddressResult(''));
                      if (target.value == '') {
                        setPlaceId('');
                      }
                    }}
                    disabled={disableFields}
                    value={address}
                  />
                  <div className='invalid-feedback'>
                    {errors.address?.message?.toString()}
                  </div>
                  {address &&
                  !selectedAddressResult &&
                  !isAddressSuggesstionLoading &&
                  !placeId &&
                  !disableFields &&
                  registrationAddressSuggesstion?.length > 0 ? (
                    <div
                      style={{ position: 'absolute' }}
                      className='w-100 rounded border bg-white  odex-registration-address d-flex flex-column justify-content-start align-items-start'
                    >
                      {registrationAddressSuggesstion.map((v: any) => (
                        <div
                          className='p-2 d-flex flex-row justify-content-start align-items-center fs--1 cursor-pointer'
                          key={v.placeId}
                          onClick={() => {
                            _onAddressChange(v.text);
                            dispatch(updateSelectedAddressResult(v.text));
                            setAddress(v.text);
                            setPlaceId(v.placeId);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faLocation}
                            className='text-primary me-2'
                          />

                          <span>{v.text}</span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </div>
              <div className='col-lg-3 mb-3'>
                <Form.Group controlId='carrierHaulage.contactName'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_2:contactName')}
                  </Form.Label>
                  <Form.Control
                    {...register('contactName')}
                    className={`fs--1 form-control ${
                      errors.contactName ? 'is-invalid' : ''
                    }`}
                    type='text'
                    maxLength={100}
                    onChange={(e) => setContactName(e.target.value)}
                    size='sm'
                    disabled={disableFields}
                    value={contactName}
                  />
                  <div className='invalid-feedback'>
                    {errors.contactName?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='col-lg-3 mb-3'>
                <Form.Group controlId='carrierHaulage.email'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_2:emailAddress')}
                  </Form.Label>
                  <Form.Control
                    {...register('email')}
                    className={`fs--1 form-control ${
                      errors.email ? 'is-invalid' : ''
                    }`}
                    type='email'
                    maxLength={100}
                    onChange={(e) => setEmail(e.target.value)}
                    size='sm'
                    disabled={disableFields}
                    value={email}
                  />
                  <div className='invalid-feedback'>
                    {errors.email?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='col-lg-3 mb-3'>
                <Form.Group controlId='carrierHaulage.contactNo'>
                  <Form.Label>
                    <span className='mandatory'>*</span>
                    {t('resource_2:phoneNumber')}
                  </Form.Label>
                  <InputGroup>
                    <div className='col-12'>
                      <Form.Control
                        size='sm'
                        {...register('contactNo')}
                        className={`fs--1 form-control ${
                          errors.contactNo ? 'is-invalid' : ''
                        }`}
                        type='text'
                        maxLength={30}
                        onChange={(e) => setContactNo(e.target.value)}
                        value={contactNo}
                        disabled={disableFields}
                      />
                      <div className='invalid-feedback'>
                        {errors.contactNo?.message?.toString()}
                      </div>
                    </div>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className='col-lg-3 mb-3'>
                <Form.Group controlId='carrierHaulage.areaCode'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:zipCode')}
                  </Form.Label>
                  <Form.Control
                    {...register('areaCode')}
                    className={`fs--1 form-control ${
                      errors.areaCode ? 'is-invalid' : ''
                    }`}
                    type='text'
                    maxLength={50}
                    onChange={(e) => setAreaCode(e.target.value)}
                    size='sm'
                    disabled={disableFields}
                    value={areaCode}
                  />
                  <div className='invalid-feedback'>
                    {errors.areaCode?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              {(country === 'ZA' || profileDetails.partnerCountry === 'ZA' ||
                country === 'KE' || profileDetails.partnerCountry === 'KE' ||
                country === 'JO' || profileDetails.partnerCountry === 'JO'
              ) && (
                <div className='col-lg-3 mb-3'>
                  <Form.Group controlId='blReqIns.input14'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      Delivery Date & Time
                    </Form.Label>
                    <DatePicker
                      showTimeSelect
                      selected={deliveryDate}
                      {...register('deliveryDateTime')}
                      onChange={(date) => {
                        setDeliveryDate(date || new Date());
                        setDeliveryDateTime(
                          moment(date + ' UTC').format('dd/MM/yyyy')
                        );
                      }}
                      className='form-control form-control-sm'
                      placeholderText='Select Date & Time'
                      timeIntervals={5}
                      dateFormat={getDateTimeFormat()}
                      fixedHeight
                      disabled={disableFields}
                      minDate={new Date()}
                    />
                  </Form.Group>
                </div>
              )}
              {profileDetails?.partnerType === 'liner' ? (
                <div className='col-lg-12 mb-3'>
                  <Form.Group controlId='carrierHaulage.remark'>
                    <Form.Label className='fs--1'>
                      {t('resource_1:remark')}
                    </Form.Label>
                    <Form.Control
                      {...register('remark')}
                      as='textarea'
                      className='form-control'
                      disabled={disableFields}
                      maxLength={200}
                      onChange={
                        (e) => setRemark(e.target.value)

                        /*   setState({ ...state, remark: e.target.value }) */
                      }
                      value={remark}
                    />
                  </Form.Group>

                  <Form.Group controlId='carrierHaulage.rejectionRemark'>
                    <Form.Label className='fs--1'>
                      {' '}
                      {t('resource_2:rejectionRemark')}
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      className='fs--1 form-control'
                      disabled={data?.status !== 'Submitted' && disableFields}
                      value={rejectionremark}
                      onChange={(e) =>
                        // setState({ ...state, rejectionRemark: e.target.value })
                        {
                          setRejectionremark(e.target.value);
                          setState((prevState: any) => ({
                            ...prevState,
                            rejectionremark: e.target.value, // Update this with the new value
                          }));
                        }
                      }
                    />
                  </Form.Group>
                </div>
              ) : (
                <div className='col-lg-12 mb-3'>
                  <Form.Group controlId='carrierHaulage.remark'>
                    <Form.Label className='fs--1' /*  value={state.remark} */>
                      {' '}
                      {t('resource_1:remark')}
                    </Form.Label>
                    <Form.Control
                      {...register('remark')}
                      as='textarea'
                      className='fs--1 form-control'
                      maxLength={200}
                      disabled={disableFields}
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                    />
                  </Form.Group>
                  {status == 'Rejected' && (
                    <Form.Group controlId='carrierHaulage.rejectionRemark'>
                      <Form.Label className='fs--1'>
                        {' '}
                        {t('resource_2:rejectionRemark')}
                      </Form.Label>
                      <Form.Control
                        as='textarea'
                        className='fs--1 form-control'
                        disabled={disableFields}
                        value={rejectionremark}
                      />
                    </Form.Group>
                  )}
                </div>
              )}
            </div>
            {disableFields && (
              <div className='col-lg-5 mb-3'>
                <Form.Group controlId='carrierHaulage.status'>
                  <Form.Label className='fs--1'>
                    {t('resource_1:status')}&nbsp;
                  </Form.Label>
                  <Form.Control
                    className={'fs--1 form-control'}
                    type='text'
                    value={status}
                    disabled={true}
                  />
                </Form.Group>
              </div>
            )}

            <div className='d-flex justify-content-center align-items-center mt-4'>
              {dataCountry === country &&
              rbac.rbacDetails.deliveryApproveReject == 'Y'
                ? data?.status === 'Submitted' && (
                    <div className='d-flex justify-content-center align-items-center'>
                      <Button
                        disabled={
                          data?.status === 'Completed' ||
                          data?.status === 'Rejected'
                            ? disableFields
                            : false
                        }
                        onClick={(e) => {
                          onApproveReject('A', 'CARRIER_HAULAGE');
                        }}
                        variant='success'
                        className='me-2 approve'
                        size='sm'
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className='me-2 approve'
                        />
                        {t('resource_1:approve')}
                      </Button>

                      <Button
                        disabled={
                          data?.status === 'Completed' ||
                          data?.status === 'Rejected'
                            ? disableFields
                            : false
                        }
                        onClick={(e) => {
                          onApproveReject('R', 'CARRIER_HAULAGE');
                        }}
                        variant='danger reject'
                        size='sm'
                        className='me-2'
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className='me-2 reject'
                        />
                        {t('resource_1:reject')}
                      </Button>
                      {rbac.rbacDetails.snoozeCUD === 'Y' && !location.pathname.includes('/bl')? <SnoozedToButton    blNum={AESDecrypt(blNum)} blEventId={blEventId_docInsDashboard}  /> :""  }
                    </div>
                  )
                : !disabledPath && (
                    <div className='d-flex justify-content-center align-items-center'>
                      <Button
                        className='me-2'
                        type='submit'
                        variant='falcon-primary'
                        size='sm'
                      >
                        <FontAwesomeIcon icon={faSave} className='me-2' />
                        {t('resource_1:submit')}
                      </Button>

                      <Button
                        className='me-2'
                        variant='falcon-danger'
                        size='sm'
                        onClick={resetForm}
                      >
                        <FontAwesomeIcon icon={faRedo} className='me-2' />
                        {t('resource_1:reset')}
                      </Button>
                    </div>
                  )}
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CarrierHaulage;

/**
 * Editable partner details table to be shown on registration
 */

import { Button, Form } from "react-bootstrap";
import DataTable from "../../common/components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchLinerNameByCountry, getRegistrationDocuments } from "./actions/Registration";
import { addDocumentTableRow, removeDocumentTableRow, setDocumentTableRow } from "./registraionSlice";
import UploadThing from "../../../components/common/UploadThing";
import DatePicker from 'react-datepicker';
import { getDateFormat } from "../../../helpers/dateFormat";


export default function RegistrationDocumentTable({ country, isDisabled, companyType }: any) {

    const { t } = useTranslation()
    const data = useSelector((state: RootState) => state.registration.documentTable)
    const dispatch = useDispatch<AppDispatch>()
    const documentOptions = useSelector((state: RootState) => state.registration.documentOptions)
    const linerId = useSelector((state:RootState)=>state.navbarTop.linerId)


    useEffect(() => {
        if (country && companyType) {
            dispatch(getRegistrationDocuments({ country, companyType }))
        }
    }, [country, companyType])

    const tableColumnConfig = [
        {
            accessor: 'docId',
            Header: 'Document Name',
            width: 180,
            maxWidth: 180,
            Cell: (rowData: any) => {
                const { docId, m, rowNumber } = rowData.row.original;
                if (m.attachment) return <Form.Select className={`fs--1 form-control`} disabled={true}>
                    <option>{documentOptions.find((i: any) => i.value == docId)?.text ?? ''}</option>
                </Form.Select>
                return <Form.Select className='fs--1 form-control' value={docId} disabled={isDisabled} 
                onChange={(e) => {
                    dispatch(setDocumentTableRow({
                        rowNumber,
                        docId: e.target.value
                    }))
                }}>
                    {documentOptions.map((i: any) => <option value={i.value} key={i.value}>
                        {i.text}
                    </option>)}
                </Form.Select>
            }
        },
        {
            accessor: 'number',
            Header: 'Document No',
            width: 180,
            maxWidth: 200,
            Cell: (rowData: any) => {
                const { number, rowNumber, error } = rowData.row.original;
                return (
                    <input
                        type='text'
                        className={`fs--1 form-control ${error.number?'is-invalid':''}`}
                        defaultValue={number || ''}
                        disabled={isDisabled}
                        onBlur={(e) => {
                            dispatch(setDocumentTableRow(
                                {
                                    rowNumber,
                                    number: e.target.value.trim()
                                }
                            ))
                        }}
                    />
                );

            },
        },
        {
            accessor: 'issueDate',
            Header: 'Issue Date',
            width: 150,
            maxWidth: 180,
            Cell: (rowData: any) => {
                const { issueDate, rowNumber, error } = rowData.row.original;

                return (
                    <DatePicker
                        selected={issueDate || null}
                        portalId='root-portal'
                        onChange={(date) => {
                            dispatch(setDocumentTableRow({
                                rowNumber,
                                issueDate: date
                            }))

                        }}
                        className={`fs--2 form-control ${error.issueDate?'is-invalid':''}`}
                        timeIntervals={5}
                        dateFormat={getDateFormat()}
                        isClearable={true}
                        popperProps={{
                            strategy: 'fixed',
                        }}
                        disabled={
                            isDisabled
                        }
                        maxDate={new Date()}
                    />
                );

            },
        },
        {
            accessor: 'validityDate',
            Header: 'Validity Date',
            width: 150,
            maxWidth: 180,
            Cell: (rowData: any) => {
                const { validityDate, rowNumber, m, error } = rowData.row.original;

                return (
                    <DatePicker
                        selected={validityDate || null}
                        portalId='root-portal'
                        onChange={(date) => {
                            dispatch(setDocumentTableRow({
                                rowNumber,
                                validityDate: date
                            }))

                        }}
                        className={`fs--2 form-control ${error.validityDate?'is-invalid':''}`}
                        timeIntervals={5}
                        dateFormat={getDateFormat()}
                        isClearable={true}
                        popperProps={{
                            strategy: 'fixed',
                        }}
                        disabled={
                            isDisabled || !m.validityDate
                        }
                        minDate={new Date()}
                    />
                );

            },
        },
        {
            accessor: 'attachmentName',
            Header: 'Attachment',
            width: 250,
            maxWidth: 260,
            Cell: (rowData: any) => {
                const { rowNumber, attachmentName, loading, regPartnerDocumentId, error} = rowData.row.original;
                if (isDisabled) return <div className="h-100 d-flex align-items-center"><span 
                    className="fs--1"
                    style={{color:'#2c7be5', cursor:'pointer'}}
                    onClick={()=>{
                        window.open(`/api/user-management/v1/customer/openDocument?id=${regPartnerDocumentId}&moduleTp=DOCUMENT&linerId=${linerId}`)
                    }}
                >{attachmentName}</span></div>
                return (
                    <UploadThing
                        name={attachmentName}
                        invalid={error.attachment}
                        loading={loading}
                        fileType={["PDF"]}
                        labelWidth={'150px'}
                        fileLimit={5}
                        clear={() => {
                            dispatch(setDocumentTableRow(
                                {
                                    rowNumber,
                                    attachmentName: '',
                                    serverPath: null,
                                    serverName: null,
                                    loading: 0
                                }
                            ))
                        }}
                        pending={(loading) => {
                            dispatch(setDocumentTableRow(
                                {
                                    rowNumber,
                                    loading: loading
                                }
                            ))

                        }}
                        fulfilled={(name, serverPath, serverName) => {
                            dispatch(setDocumentTableRow(
                                {
                                    rowNumber,
                                    attachmentName: name,
                                    serverPath: serverPath,
                                    serverName: serverName,
                                    loading: 100
                                }
                            ))
                        }}
                        selected={(name) => {
                            dispatch(setDocumentTableRow(
                                {
                                    rowNumber,
                                    attachmentName: name
                                }
                            ))
                        }}
                        failed={() => {
                            dispatch(setDocumentTableRow(
                                {
                                    rowNumber,
                                    loading: -1
                                }
                            ))
                        }}
                    />
                );
            },
        },
        {
            accessor: 'm',
            Header: '',
            width: 40,
            maxWidth: 40,
            Cell: (rowData: any) => {
                const { m, rowNumber } = rowData.row.original;
                if (m.attachment) return <></>
                return (
                    <Button
                        variant='falcon-warning'
                        style={{ background: 'transparent', border: 'none', boxShadow: 'none', padding: '0px' }}
                        onClick={() => {
                            dispatch(removeDocumentTableRow({rowNumber}))
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} size='sm' />
                    </Button>
                );
            },
            disableSort: true,
        }
    ];
    if(documentOptions.length < 1 && (!isDisabled || data.length < 1))return <></>
    return <div className='col-lg-12 mt-2 mb-2 demmurageTable d-flex flex-column'>
        {!isDisabled && <Button
            variant='falcon-primary'
            style={{
                width: '120px',
                marginRight: '10px',
                marginBottom: '10px',
            }}
            size='sm'
            onClick={() => {
                dispatch(addDocumentTableRow())
            }}>
            <div className='d-flex'>
                <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
                <span>Document</span>
            </div>
        </Button>}
        <DataTable
            gridName='REG_DOCUMENT_TABLE'
            columns={tableColumnConfig}
            data={data}
            shortPrepend='reg_dt'
            perPage={100}
            sortable={true}
            searchable={false}
            showColumnFilter={false}
            selection={false}
            pagination={false}
            seekPagination={false}
            showExport={false}
            localFilter={true}
            uniqueFilterKey='documentTable'
            autoHeight={true}
            tableMinHeight={300}
            staticHiddenCols={['rowNumber',isDisabled?'m':'']}
        />
    </div>
}
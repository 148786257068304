/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';

import TableComponent from './TableComponent';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

//todo bllist
import { getDashboardData } from '../actions/dashboard';
import queryString from 'query-string';
import { getColumnViews } from '../../../common/actions/dataTable';
import { updateDashbaordRefresh, updateSortStateDataTable } from '../../../common/components/DataTable/slices/DataTable';
import {
  updateCurrentPageBlDashboard,
  updatePrveDependenciesBlDashboard,
} from '../slices/dashboardSlice';
import { updateParams } from '../../../common/components/DataTable/slices/DataTable';
import { AESEncrypt } from '../../../../encrypt-util';
import { updateDashbaordName } from '../../../common/slices/navbarTop';
import moment from 'moment';

const MainTable = () => {
  // Constants

  const DASHBOAD_NAME = 'BL_DASHBOARD';
  const FILTER_KEY = 'mainDashboard';

  const location = useLocation();

  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPage
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const refresh = useSelector((
    state:RootState)=> state.dataTable.refreshDashboard)

  const dispatch = useDispatch<AppDispatch>();
  const isInitialRender = useRef(true);
  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );

  const filterStatesort = useSelector(
    (state: RootState) => state.dataTable.sortState
  );

  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const grids = useSelector((state: RootState) => state.dataTable.grids);
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );

  const filterStateLocal = filterState?.mainDashboard;

  const blTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.blTopSearchState
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry =
    storedCountry ?? linerDataCountry ?? profileDetails.partnerCountry ?? null;

  useEffect(() => {
    if ( dataCountry && linerId) {
      setTimeout(()=>{
        dispatch(
          getColumnViews({ gridName: 'BL_DASHBOARD', country: dataCountry, linerId: linerId })
        );
      },250)
      
    }
    dispatch(updateParams({}));
    /*    dispatch(
      updateSortStateDataTable({
        key: null,
      })
    ); */
  }, [dataCountry, grids, linerId]);

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageBlDashboard(page));
  };

  const prevLocation = useSelector(
    (state: RootState) => state.dashboard.prevDependencies.prevLocation
  );

  const prevFilterState = useSelector(
    (state: RootState) => state.dashboard.prevDependencies.prevFilterState
  );

  const prevDataCountry = useSelector(
    (state: RootState) => state.dashboard.prevDependencies.prevDataCountry
  );
  const sortState = useSelector((state:RootState) => state.dataTable.sortState);
  const prevPartnerType = useSelector(
    (state: RootState) => state.dashboard.prevDependencies.prevPartnerType
  );
  const prevPage = useSelector(
    (state: RootState) => state.dashboard.prevDependencies.prevPage
  );
  const [timeState, settimeState] = useState('');

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();
    
    useEffect(() => {
      callback.current = effect;
    }, [effect]);
  
    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };
  
      const timer = setTimeout(handler, delay);
      
      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }

  const setPrevLocation = (loc: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: 'prevLocation',
        value: loc,
      })
    );
  };

  const setPrevFilterState = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: 'prevFilterState',
        value: state,
      })
    );
  };

  const setPrevDataCountry = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: 'prevDataCountry',
        value: state,
      })
    );
  };

  const setPrevPage = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: 'prevPage',
        value: state,
      })
    );
  };

  const setPrevPartnerType = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: 'prevPartnerType',
        value: state,
      })
    );
  };
  useEffect(()=>{
    if (refresh) loadBlDashboard(false)
    // server sometimes takes time to update the view so the delay
    setTimeout(()=>dispatch(updateDashbaordRefresh(false)),2000)
  }, [refresh])
  
  /**
   *
   * @param fields Array of string
   * @param filters Object with filter data
   * @param sorts Arry of string
   * @param page Number
   * @param where Where condition for backend
   * @param parameter Object
   */

  const _getDashboardData = (fields: string[], filters: any, page: number) => {
    const sort = (queryString.parse(location.search)?.sort as string)
      ?.split?.(',')
      ?.join?.(' ');
      
    const sorts = sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['lastupdateddate desc'];
    
    const payload = {
      fields,
      filters,
      size: 20,
      sorts,
      page,
    };

    const partnerId =
      profileDetails.partnerType === 'customer'
        ? profileDetails.partnerId
        : null;

    const where = grids?.[0]?.whereClause
      ? AESEncrypt(grids?.[0]?.whereClause)
      : null;

    const parameter = selectedTopSearchFilter?.where
      ? {
          searchParameter: searchInputValue,
          searchWhere: selectedTopSearchFilter?.where,
        }
      : {};

    dispatch(
      getDashboardData({
        gridName: DASHBOAD_NAME,
        payload,
        partnerId,
        where,
        dataCountry,
        parameter,
      })
    );

    if (grids[0]) {
      const newdata = grids[0].configGroup?.find(
        (c: any) => c.userGridId == selectedConfigId?.['mainDashboard']
      )?.columnConfigGrid;
      if (newdata) {
        settimeState(
          JSON.parse(newdata).find(
            (item: any) => item.accessor == 'lastupdateddate'
          )?.filterState
        );
      }
    }
  };

  useEffect(() => {
    if (currentPage && currentPage !== prevPage) {
      setPrevPage(currentPage);
      loadBlDashboard(false);
    }
  }, [currentPage]);

  // useEffect(() => {
  //   if (
  //     (profileDetails.partnerType === 'liner' && grids) ||
  //     profileDetails.partnerType !== 'liner'
  //   ) {
  //     if (
  //       prevLocation !== location.search ||
  //       JSON.stringify(prevFilterState) !== JSON.stringify(filterStateLocal)
  //     ) {
  //       loadBlDashboard(true);
  //       setPrevLocation(location.search);
  //       setPrevFilterState(JSON.stringify(filterStateLocal));
  //     }
  //   }
  // }, [filterStateLocal, location.search, grids, linerId]);

  useDebouncedEffect(() => {
    if (
      (profileDetails.partnerType === 'liner' && grids) ||
      profileDetails.partnerType !== 'liner'
    ) {
      if (
        prevLocation !== location.search ||
        JSON.stringify(prevFilterState) !== JSON.stringify(filterStateLocal)
      ) {
        loadBlDashboard(true);
        setPrevLocation(location.search);
        setPrevFilterState(JSON.stringify(filterStateLocal));
      }
    }
  }, [filterStateLocal, location.search, grids, linerId], 700);



  useEffect(() => {
    if (
      (profileDetails.partnerType === 'liner' && grids?.[0]?.whereClause) ||
      profileDetails.partnerType !== 'liner'
    ) {
      if (dataCountry && dataCountry !== prevDataCountry) {
        setPrevDataCountry(dataCountry);
        loadBlDashboard(true);
      } else if (
        profileDetails.partnerType &&
        profileDetails.partnerType !== prevPartnerType
      ) {
        setPrevPartnerType(profileDetails.partnerType);
        loadBlDashboard(true);
      } else if (
        linerId
      ) {
        loadBlDashboard(true);
      }
      // else if (grids) {
      //   loadBlDashboard(true);
      // }
    }
  }, [dataCountry, profileDetails.partnerType, grids, linerId]);
  useEffect(() => {
    if (
      location.pathname === '/home' &&
      (blTopSearchState === '' ||
        JSON.stringify(blTopSearchState).length === 0 ||
        '{}')
    ) {
      loadBlDashboard(false);
    }
  }, []);

  const loadBlDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) {
      setCurrentPage(1);
    }

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }

        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = 
          (selectedTopSearchFilter?.id && searchInputValue.trim() != '' && 
          Object.keys(blTopSearchState).length != 0)?
          {[selectedTopSearchFilter?.id] : [searchInputValue.trim()]}:{};
          const currentDate = new Date();
          if (grids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange:any = {};

            const newdata = grids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.['mainDashboard']
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'lastupdateddate'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'lastupdateddate'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'lastupdateddate'
              )?.dateRange;
            }

            if (filterApiState.lastupdateddate && timeFlag == 'day' && dateRange){
              filterApiState.lastupdateddate[1] = moment()
              .subtract(Math.abs(dateRange.to), 'days')
              .endOf('day')
              .toISOString()
              // const newDate = new Date(new Date(currentDate.toISOString()).getTime() - (timeDiff * 24 * 60 * 60 * 1000));
              filterApiState.lastupdateddate[0] = moment()
              .subtract(Math.abs(dateRange.from), 'days')
              .startOf('day')
              .toISOString()

            }
          }

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }

          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            const filters = !selectedTopSearchFilter?.where
              ? finalFilterApiState
              : {};
            // _getDashboardData([], filters, filterUpdated ? 1 : currentPage);
            setTimeout(() => {
              _getDashboardData([], filters, filterUpdated ? 1 : currentPage);
            }, 250);
          }
        }
      // } else {
      //   if (
      //     (profileDetails.partnerType === 'customer' &&
      //       profileDetails.partnerId &&
      //       grids?.[0]?.whereClause) ||
      //     profileDetails.partnerType === 'liner'
      //   ) {
      //     const filters = !selectedTopSearchFilter?.where
      //       ? blTopSearchState
      //       : {};
      //       setTimeout(() => {_getDashboardData([], filters, filterUpdated ? 1 : currentPage);},250)
          
      //   }
      }
    } else if (
      !location.pathname.includes('blslider/') &&
      !location.pathname.includes('bl/') &&
      location.pathname.includes('/home') &&
      ((profileDetails.partnerType === 'customer' &&
        profileDetails.partnerId &&
        grids?.[0]?.whereClause) ||
        profileDetails.partnerType === 'liner')
    ) {
      const filters = !selectedTopSearchFilter?.where ? blTopSearchState : {};
      setTimeout(()=> {_getDashboardData([], filters, filterUpdated ? 1 : currentPage);},250)
      
    }
  };

  return (
    <TableComponent
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      dataCountry={dataCountry || 'US'}
    />
  );
};

export default MainTable;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateCompanyName,
  updateTax,
  updateAddress,
  updateCountryOptions,
  updateLinerOptions,
} from '../registraionSlice';
import axios, { AxiosError } from 'axios';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axiosInstance, { axiosCache, axiosCacheInstance } from '../../../../axiosSetup';

const csrfMeta = document.querySelector<HTMLMetaElement>("meta[name='_csrf']");
// import axios from '../../../../axiosSetup';

/* export interface RegistrationData {
  company_name: string;
  address_details:string;
  state: string;
  country_code: string;
  request_id:string;
  source:string;
  company_number:string
} */
/*  export interface RegistrationData {
 country: string;
  state: string;
  company_name:string

  reg_number: string;
  current_status:string;
  address:string;
} */

export interface RegistrationData {
  country: string;
  state: string;
  company_name: string;

  reg_number: string;
  current_status: string;
  address: string;
  full_address: string;
}
export const addRegistrationDetails = createAsyncThunk(
  'registration/addEin',
  async (state: any) => {
    const response = await axios({
      url: `https://places.geo.us-east-1.amazonaws.com/places/v0/indexes/explore.place.Esri/search/suggestions`,
      method: 'POST',

      headers: {
        AccessKey: 'AKIARSXGUNDQFA7LKMV6',
        SecretKey: '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',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
      },
    });
    return {
      // next: null,
      // previous: null,
      list: response.data,
      // list: data,
    };
  }
);
export const addRegistrationDetailsCompany = createAsyncThunk(
  'registrationCompany/addCompanyRegistration',
  async (state: any) => {
    const response = await axios({
      url: `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/users/kyb/company/search`,
      method: 'POST',

      data: state,
    });

    return {
      // next: null,
      // previous: null,
      list: response.data.data, // list: data,
    };
  }
);

export const fetchAddressSuggestions: any = createAsyncThunk(
  'registrationCompany/fetchAddressSuggestions',
  async (payload: any) => {
    try {
      if (payload?.text) {
        if (payload?.country !== null) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/places/v0/indexes/explore.place.Esri/search/suggestions?text=${payload?.text}&filterCountries=${payload?.country}`
          );
          return response.data;
        } else {
          const response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/places/v0/indexes/explore.place.Esri/search/suggestions?text=${payload?.text}`
          );
          return response.data;
        }
      }
      return [];
    } catch (error) {
      console.error(error);

      return [];
    }
  }
);

export const fetchCompanyDetailsForVerification = createAsyncThunk(
  'registrationCompany/companyNameVerification',
  async (state: any, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/users/kyb/customer/details`,
        {
          // ...state
          // method: 'POST',
          companyNumber: state.company_number,
          countryCode: state.country_code,
        }
      );
      dispatch(updateTax(response.data));

      dispatch(updateCompanyName(response.data.data[0].company_name));
      dispatch(updateAddress(response.data.data[0].full_address));
      return response.data;
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);

export const getCountryList = createAsyncThunk(
  'registrationCompany/getCountryList',
  async (state: any, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/country/getCountryDetailsByOdexActive?odexActive=Y`
      );
      const options = response.data.map((item: any) => ({
        value: item.countryShortCode,
        text: item.countryName,
        full: item.countryIsoCode
      }));
      options.sort((a:any,b:any)=>a.text > b.text?1:-1)
      dispatch(updateCountryOptions(options));
      return options;
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);

export const fetchLinerNameByCountry = createAsyncThunk(
  'registration/fetchLinerName',
  async (payload: any, { dispatch }) => {
    try {
      const response = await axios.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=ACTIVE_LINERS&countryCode=${payload.country_code}`,
      );
      const options = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));
      if(options?.length == 0) console.warn(`No Liner options found for country ${payload.country_code}`)
      dispatch(updateLinerOptions(options?.length == 0?
    [{
      value:1,
      text:'Hapag Lloyd'
    }]:options));
      return options;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
);

export const registerCustomerAndUser = createAsyncThunk(
  'registration/add',
  async (payload: any, {getState}:any) => {
    const state:RootState = getState()
    try {
      let partnerData:any = [];
        partnerData = state.registration.partnerTable.filter((i)=>i?.customerCode !== '' && i?.customerCode !== null).map((i)=>({
          linerId: i.liner,
          mapCode: i.customerCode,
          fileName: i.attachmentName,
          serverFilePath: i.serverPath,
          tempFileName: i.serverName
        }))

      // upload validation
      if(
        (state.registration.taxDocument.loading > 0 && state.registration.taxDocument.loading != 100) ||
        (state.registration.documentTable.findIndex((i)=>i.loading > 0 && i.loading !=100)!==-1) ||
        (state.registration.partnerTable.findIndex((i)=>i.loading > 0 && i.loading !=100)!==-1)
      )
      {
        toast.error('pending file upload')
        return
      }
      
      // error checking
      if(state.registration.taxDocument.error ||
        state.registration.documentTable.findIndex((i)=>Object.values(i.error).includes(true))!== -1 ||
        state.registration.partnerTable.findIndex((i)=>Object.values(i.error).includes(true))!== -1
      ){
        return
      }

      const response = await axios({
        method: 'post',
        url: `/api/user-management/v1/customer/registerCustomerAndUser`,
        headers: {
          'X-CSRF-TOKEN':
            csrfMeta && csrfMeta?.content !== null ? csrfMeta.content : '',
        },
        data: {
          countryCode: payload?.country.toString().substring(0, 2),
          customerTaxId: payload?.taxID,
          customerName: payload?.companyName,
          customerAddress: payload?.address,
          userName: payload?.userName,
          email: payload?.email.toLowerCase(),
          contactNo: payload?.phoneNumber,
          language: 'EN_US',
          customerMapping: partnerData,
          isTNCAccepted:
            payload?.acceptTerms !== undefined &&
            payload?.acceptTerms !== null &&
            payload?.acceptTerms === true
              ? 'Y'
              : 'N',
          taxFileName: state.registration.taxDocument.name,
          taxServerFilePath: state.registration.taxDocument.serverPath,
          taxTempFileName: state.registration.taxDocument.serverName,
          companyIdList: payload.companyType,
          customerDocumentInputs: state.registration.documentTable.map(
            (item)=>({
              docId: item.docId,
              docNo: item.number,
              issueDate: item.issueDate,
              validTillDate: item.validityDate,
              fileName: item.attachmentName,
              serverFilePath: item.serverPath,
              tempFileName: item.serverName
          }))
        },



      });

      // const response = await axios.post(
      //   `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/registerCustomerAndUser`,
      //   {
      //     countryCode: payload?.country.toString().substring(0, 2),
      //     customerTaxId: payload?.taxID,
      //     customerName: payload?.companyName,
      //     customerAddress: payload?.address,
      //     userName: payload?.userName,
      //     email: payload?.email,
      //     contactNo: payload?.phoneNumber,
      //     language: 'EN_US',
      //     customerMapping: data,
      //   },

      //   { 'X-CSRF-TOKEN': csrfMeta?.content !== null ? csrfMeta.content : '' }
      // );

      return response;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
);
export const getRegistrationDetails = createAsyncThunk(
  'registraion/getRegistrationDetails',
  async (decrptrequestId: any) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_GATEWAY_HOST
        }/api/user-management/v1/customer/getRegistrationDetails?requestId=${
          Object.values(decrptrequestId)[0]
        }`
      );
      return response;
      // return { list: response.data };
    } catch (error) {
      return {};
    }
  }
);

export const approveRejectRegistration = createAsyncThunk(
  'instruction/approveRejectReturn',
  async (payload: any, {getState}:any) => {
    const state:RootState = getState()
    try {
      // const response = await axios({
      //   url: `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/approveRejectRegistrationDetails?requestType=${payload.requestType}`,
      //   method: 'POST',
      //   data: {
      //     payload
      //   },
      // });

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/approveRejectRegistrationDetails?requestType=${payload.requestType}`,
        {
          ...payload,
          customerMapping: state.registration.partnerTable.filter((i)=>i?.customerCode !== '' && i?.customerCode !== null).map((i)=>({
            linerId: i.liner,
            mapCode: i.customerCode,
            fileName: i.attachmentName,
            serverFilePath: i.serverPath,
            tempFileName: i.serverName
          })),
          customerDocumentInputs: state.registration.documentTable.map(
            (item)=>({
              docId: item.docId,
              docNo: item.number,
              issueDate: item.issueDate,
              validTillDate: item.validityDate,
              fileName: item.attachmentName,
              serverFilePath: item.serverPath,
              tempFileName: item.serverName
          })),
          taxFileName: state.registration.taxDocument.name,
          taxServerFilePath: state.registration.taxDocument.serverPath,
          taxTempFileName: state.registration.taxDocument.serverName,
        }
      );

      if (response.data.responseMsg) toast.success(response.data.responseMsg);
      /*      setTimeout(() => {
        window.location.href = '/registrationmaster';
      }, 1000); */
      // return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error( error);
      toast.dark('Error');

      // return {};
    }
  }
);

export const getCompanyTypes = createAsyncThunk(
  'registration/getCompanyTypes',
  async (payload: any) => {
    try {
      const response = await axios.get( 
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=COMPANY_TYPE&linerId=1&countryCode=${payload.partnerCountry}`,
      );

      const options = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));

      return options;
    } catch (e) {
      const error = e as AxiosError;
      return null;
    }
  }
);

export const getRegistrationDocuments = createAsyncThunk(
  'registration/getRegistrationDocuments',
  
  async (payload:{country:string, companyType:Array<string>})=>{
    if(payload.companyType.length < 1) return []
    try{
      const response = await axios.post( 
      `${
        process.env.REACT_APP_API_GATEWAY_HOST
      }/api/invoice-management/v1/common/attachmentConfig?countryCode=${payload.country}&instructionTy=300`
      ,payload.companyType
    );
    if(response.data.message)throw Error(response.data.message);
    return response.data
    }catch (e){
      return []
    }
  }
)

export const getRegistrationTaxDocConfig = createAsyncThunk(
  'registration/getRegistrationTaxDocConfig',
  
  async (payload:{country:string})=>{
    try{
      const response = await axios.post( 
      `${
        process.env.REACT_APP_API_GATEWAY_HOST
      }/api/invoice-management/v1/common/attachmentConfig?countryCode=${payload.country}&instructionTy=302`
    );
    if(response.data.message)throw Error(response.data.message);
    return response.data?.[0]?.isMandatory??'Y'
    }catch (e){
      return []
    }
  }
)

export const getRegistrationPartnerDocConfig = createAsyncThunk(
  'registration/getRegistrationPartnerDocConfig',
  
  async (payload:{country:string, liner:any})=>{
    try{
      const response = await axiosCache.post( 
      `${
        process.env.REACT_APP_API_GATEWAY_HOST
      }/api/invoice-management/v1/common/attachmentConfig?countryCode=${payload.country}&instructionTy=301&linerId=${payload.liner}`
    , undefined,{
      cache:{
        ttl: 10 *60*1000
      }
    });
    if(response.data.message)throw Error(response.data.message);
    
    return response.data?.[0]?.isMandatory??'Y'
    }catch (e){
      return []
    }
  }
)
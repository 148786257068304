import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../../axiosSetup";
import { AxiosError } from "axios";
import { RootState } from "../../store";

export const getUserProfile = createAsyncThunk(
  "userDetails/getUserProfile",
  async () => {
    try {
      const response = await Axios.post(
        "api/user-management/v1/users/getLoginUserDetails"
      );

      return {
        status: response.status,
        data: response.data,
      };
    } catch (e) {
      const error = e as AxiosError;

      return {
        status: error.status,
        data: error.response?.data,
      };
    }
  }
);

export const getUserGridsDefinition = createAsyncThunk(
  "userDetails/getUserGridsDefinition",
  async (partnerCountry: any, {getState}:any) => {
    const state:RootState =getState()
    try {
      const response = await Axios.get(
        `/api/user-management/v1/usergrid/masterGrids?linerId=${state.navbarTop.linerId}&country=${partnerCountry}`
      );

      return response.data.list;
    } catch (error) {
      return [];
    }
  }
);

export const getRbacDetails = createAsyncThunk(
  "userDetails/getRbacDetails",
  async () => {
    try {
      const response = await Axios.get(
        "/api/user-management/v1/usergrid/fetchRbacGroups"
      );
      return { response };
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const getAppConfig = createAsyncThunk(
  "userDetails/getAppConfig",
  async (key: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/common/getAppValue?key=${key}`
      );
      return { response };
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const updateCookie = createAsyncThunk(
  "userDetails/updateCookie",
  async (params: any) => {
    try {
      const response = Axios.post(
        "api/invoice-management/v1/view/params",{
          ...params
        }
      );
      

    } catch (e) {
      const error = e as AxiosError;

      return {
        status: error.status,
        data: error.response?.data,
      };
    }
  }
);

import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useEffect, useState, useTransition } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateAmountMode } from '../blPayment/slices/blPaymentSlice';
import { useDispatch } from 'react-redux';
import { StandaloneInvoiceDetails } from './slices/standaloneInvoicePaymentSlice';
import { getCurrencyDDList } from './actions/payments';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import useDataCountry from '../../../hooks/useDataCountry';

const PayableAmount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const selectedBls = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsMultiPay
  );
  const dataCountry =useDataCountry()
  const location = useLocation();
  const matchPth = matchPath(
    {
      path: '/bl/:blNum/payment',
      caseSensitive: true,
      end: true,
    },
    location.pathname
  )||
  matchPath(
    {
      path: 'instruction/bl/:blNum/payment',
      caseSensitive: true,
      end: true,
    },
    location.pathname
  )||
  matchPath(
    {
      path: 'payment/bl/:blNum/payment',
      caseSensitive: true,
      end: true,
    },
    location.pathname
  );
  const disableFields =
    profileDetails?.partnerType === 'liner' ||
    (matchPth && profileDetails?.partnerType !== 'liner')
      ? true
      : false;
  const [selectedCcy, setSelectedCcy] = useState('usd');

  const selectedDocHistory: any = useSelector(
    (state: RootState) => state.documentHistory.data
  );
  const selectedAmount = useSelector(
    (state: RootState) => state.multiBlPayment.selectedAmountAll
  );
  const standaloneInvoiceList = useSelector(
      (state: RootState)=>state.standaloneInvoice.data
  );
  const unpaidData = useSelector(
    (state:RootState)=>state.invoiceUnpaid.data.filter((item)=>!!item.ccy && item.ccy!== '')
  )
  
  const newStandaloneInvoiceList:any = standaloneInvoiceList.map((invoice: StandaloneInvoiceDetails)=>{
    return {
      amount: invoice.invoiceAmount,
      id: invoice.invoiceNo,
      invID: invoice.invoiceId,
      invcurr: invoice.invoiceCurrency,
      ccy: invoice.invoiceCurrency,
    }
  })

  const newUnpaidData:any = unpaidData.map((invoice: any)=>({
      amount: invoice.amount,
      id: invoice.invno,
      invID: invoice.invid,
      invcurr: invoice.ccy,
      ccy: invoice.ccy
  })).filter(
    (item)=> 
      item.invcurr === (unpaidData.find((item)=> item.ccy!='USD')?.ccy??'USD')
  )

  const filteredselectedAmount = 
  matchPath("/payment/addInvoice/invoicePayment",location.pathname)
  ?newStandaloneInvoiceList
  : location.pathname.includes('unpaid')
  ? newUnpaidData
  :selectedAmount.filter(
    (item: { invcurr: any,id:any,localamount:any }) => item?.invcurr === selectedAmount[0]?.invcurr && ((item?.id?.substring(0,2)=='CD') == (selectedAmount[0]?.id.substring(0,2)=='CD') && 
    (isNaN(item.localamount)==isNaN(selectedAmount[0].localamount)) )
  );
  const [amount, setAmount] = useState('');

  const usdPayableAmount =
    window.location.pathname === '/payment/new' 
    || window.location.pathname === '/payment/addInvoice/invoicePayment'
    || location.pathname.includes('unpaid')
      ? filteredselectedAmount.reduce((accumulator: any, currentObj: any) => {
          const amountAsNumber: number = parseFloat(currentObj.amount);
          if (!isNaN(amountAsNumber)) {
            return accumulator + amountAsNumber;
          }
          return accumulator;
        }, 0)
      : selectedDocHistory
          .filter((v: any) => v.ccy === 'USD')
          .map((i: any) => (i.docStatus !== 'Y' ? Number(i.amount) : Number(0)))
          .reduce((partialSum: any, a: any) => partialSum + a, 0);

  const zarPayableAmount =
    window.location.pathname === '/payment/new' 
    || window.location.pathname === '/payment/addInvoice/invoicePayment'
    || location.pathname.includes('unpaid')
      ? selectedBls
          ?.filter((v:any) => v.ccy === 'ZAR')
          .map((i:any) => Number(i.amount))
          .reduce((partialSum:any, a:any) => partialSum + a, 0)
      : selectedDocHistory
          .filter((v: any) => v.ccy === 'ZAR')
          .map((i: any) => Number(i.amount))
          .reduce((partialSum: any, a: any) => partialSum + a, 0);   

    const kesPayableAmount =
    window.location.pathname === '/payment/new' 
    || window.location.pathname === '/payment/addInvoice/invoicePayment'
    || location.pathname.includes('unpaid')
      ? filteredselectedAmount.map((i:any) => Number(i.localamount))
          .reduce((partialSum:any, a:any) => partialSum + a, 0)
      : selectedDocHistory
          .filter((v: any) => v.ccy === 'KES')
          .map((i: any) => Number(i.localamt))
          .reduce((partialSum: any, a: any) => partialSum + a, 0);       

    

          const formatNumber = (stringValue: string): string => {
            const numericValue = parseFloat(stringValue);
          
            if (isNaN(numericValue)) {
              return 'Invalid Number';
            }
          
            const trimmedValue = numericValue.toFixed(2);
          
            const numberWithCommas = trimmedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          
            return numberWithCommas;
          };
  
    useEffect(() => {
    setAmount(
      formatNumber(filteredselectedAmount.reduce((a:any,b:any)=>+a+b.amount,0))
    );

    
    
    dispatch(
      updateAmountMode(
        filteredselectedAmount.reduce((a:any,b:any)=>+a+b.amount,0).toFixed(2).toString()
        
      )
    );
  }, [selectedCcy, usdPayableAmount, zarPayableAmount,kesPayableAmount]);

  useEffect(() => {
    if(!countryDDList || countryDDList.length < 1)dispatch(getCurrencyDDList())
     setSelectedCcy(filteredselectedAmount?.[0]?.invcurr)
  }, []);

 

  const rbac = useSelector((state: RootState) => state.userProfile);

  const countryDDList = useSelector(
    (state: RootState) => state.standaloneInvoice.countryDDList
  );

  const amountMap:any = {
    'USD': formatNumber(usdPayableAmount),
    'KES': formatNumber(kesPayableAmount),
    'ZA' : formatNumber(zarPayableAmount)
  }
  
  return (
    <Form.Group as={Row} controlId='payableAmount.input'>
      <Form.Label column lg={5} className='fs--1'>
        {t('resource_2:payableAmount')}
      </Form.Label>
      <Col lg={6}>
        <InputGroup>
            {
                   
            dataCountry==='KE' &&  !isNaN(kesPayableAmount)?
            <div className='d-flex py-1'>
              {countryDDList.map((item:any)=>  <div className="pe-5"> 
                <p className="text-600 fs--1 fw-medium mb-0">{item.currencyCode}</p> <h4 className="text-800 mb-0">{amountMap?.[item.currencyCode]??''} </h4>
              </div>)}
           
          </div> : <>
          <Col lg={5}> 
          <Form.Select
            value={selectedCcy}
            onChange={(e) => setSelectedCcy(e.target.value)}
            disabled={true}
          >
            <option value={filteredselectedAmount?.[0]?.invcurr}>
              {filteredselectedAmount?.[0]?.invcurr}
            </option> 
          </Form.Select>
        </Col>
        <Col lg={7}>
          <Form.Control
            aria-label={t('resource_2:payableAmount')}
            aria-describedby='payable-amount'
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
              dispatch(updateAmountMode(e.target.value));
            }}
            disabled={true}
          />
        </Col></>
              
          }
               
         
        </InputGroup>
      </Col>
      <Col lg={1}>
        {/*<Button variant="secondary" disabled={disableFields}>
          <FontAwesomeIcon icon={faSave} className="me-1" />
          <span>{t("resource_1:pay")}</span>
        </Button>
  */}
      </Col>
    </Form.Group>
  );
};

export default PayableAmount;

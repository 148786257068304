/* eslint-disable react-hooks/exhaustive-deps */
import { faCircle, faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowDown,
  faArrowUp,
  faCheckCircle,
  faFilter,
  faGear,
  faPlus,
  faRedo,
  faSave,
  faTimes,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Form, ListGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  UpdateEnableEditRemoveShared,
  UpdateEnableEditRemoveSystem,
  updateSelectedConfigId,
  updateSelectedShortcutPreview,
} from './slices/DataTable';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import SoftBadge from '../../../../components/common/SoftBadge';
import { deleteColumnViewSettings } from '../../actions/dataTable';
import { updateParams } from './slices/DataTable';
import { updateSortStateDataTable } from './slices/DataTable';
import RegistrationMasterDetails from '../../../features/registrationMaster/registrationMasterDetails';
import useReportGridName from '../../../features/reportDashboard/useReportGridName';
const swapIndex = (arr, from, to) => {
  const columns = [...arr];

  const element = columns[from];

  columns.splice(from, 1);
  columns.splice(to, 0, element);

  return columns;
};

const ColumnVisibilitySettings = (props) => {
  const { t } = useTranslation();
  const rbac = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const enableEditRemoveSystem = useSelector(
    (state) => state.dataTable.enableEditRemoveSystem
  );
  const enableEditRemoveShared = useSelector(
    (state) => state.dataTable.enableEditRemoveShared
  );
  const linerId = useSelector((state) => state.navbarTop.linerId);
  const isLoadingConfigUpdate = useSelector(
    (state) => state.dataTable.isLoadingConfigUpdate
  );
  const grids = useSelector((state) => state.dataTable.grids);
  const invoiceGrids = useSelector((state) => state.dataTable.invoiceGrids);

  const paymentFundGrids = useSelector((state)=> state.dataTable.paymentFundGrids)
  const paymentGrids = useSelector((state)=> state.dataTable.paymentGrids)
  const instructionsGrids = useSelector(
    (state) => state.dataTable.instructionsGrids
  );
  const reportGrids = useSelector((state)=>state.dataTable.reportGrids)
  const profileDetails = useSelector((state) => state.userProfile.profile);
  const userGrids = useSelector((state) => state.dataTable.userGrids);
  const standingInstructiongrids = useSelector(
    (state) => state.dataTable.standingInstructionGrids
  );
  const registrationMasterGrid = useSelector(
    (state) => state.dataTable.registrationMasterGrid
  );
  const [removeShortcut, setRemoveShortcut] = useState(null);
  const [showConfigForm, toggleConfigForm] = useState(false);
  const [editConfig, setEditConfig] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  /*  const[enableEditRemove,setEnableEditRemove]=useState(false) */
  const selectedConfigId = useSelector(
    (state) => state.dataTable.selectedConfigId
  );

  const onSetHiddenCols = (accessor, canHide) => () => {
    if (canHide) {
      if (props.hiddenCols.includes(accessor)) {
        props.setHiddenCols(props.hiddenCols.filter((i) => i !== accessor));
      } else {
        props.setHiddenCols([...props.hiddenCols, accessor]);
      }
    }
  };
  const [sortParams, setsortParams] = useState('');
  const {reportGridFilterKey} = useReportGridName();
  useEffect(() => {
    
    if (location.search) {
      dispatch(
        updateSelectedShortcutPreview(
          queryString.parse(location.search)?.view || ''
        )
      );
      setsortParams(queryString.parse(location.search)?.sort?.split?.(','));
    } else {
      // setsortParams('')
    }
  }, [location]);
  useEffect(() => {
    if (shortcutName != '') {
      if (grids) {
        if (grids[0].configGroup.length > 0) {
          const filteredVal = grids[0].configGroup.filter(
            (item) => item.columnConfigName == shortcutName
          );
          
          dispatch(
            updateSelectedConfigId({
              ...selectedConfigId,
              ['mainDashboard']: filteredVal[0]?.userGridId,
            })
          );
          localStorage.setItem(
            `selectedConfigId_${grids[0]?.gridName}`,
            JSON.stringify(filteredVal[0]?.userGridId)
          );
          setShortcutName('');
        }
      }
    }
  }, [grids]);

  useEffect(() => {
    if (shortcutName != '') {
      if (invoiceGrids) {
        if (invoiceGrids[0].configGroup.length > 0) {
          const filteredVal = invoiceGrids[0].configGroup.filter(
            (item) => item.columnConfigName == shortcutName
          );
          dispatch(
            updateSelectedConfigId({
              ...selectedConfigId,
              ['paymentOne']: filteredVal[0]?.userGridId,
            })
          );
          localStorage.setItem(
            `selectedConfigId_${invoiceGrids[0]?.gridName}`,
            JSON.stringify(filteredVal[0]?.userGridId)
          );
          setShortcutName('');
        }
      }
    }
  }, [invoiceGrids]);

  useEffect(() => {
    if (shortcutName != '') {
      if (reportGrids) {
        if (reportGrids[0].configGroup.length > 0) {
          const filteredVal = reportGrids[0].configGroup.filter(
            (item) => item.columnConfigName == shortcutName
          );
          dispatch(
            updateSelectedConfigId({
              ...selectedConfigId,
              [reportGridFilterKey]: filteredVal[0]?.userGridId,
            })
          );
          localStorage.setItem(
            `selectedConfigId_${reportGrids[0]?.gridName}`,
            JSON.stringify(filteredVal[0]?.userGridId)
          );
          setShortcutName('');
        }
      }
    }
  }, [reportGrids]);

  useEffect(() => {
    if (shortcutName != '') {
      if (paymentFundGrids) {
        if (paymentFundGrids[0].configGroup.length > 0) {
          const filteredVal = paymentFundGrids[0].configGroup.filter(
            (item) => item.columnConfigName == shortcutName
          );
          dispatch(
            updateSelectedConfigId({
              ...selectedConfigId,
              ['paymentFund']: filteredVal[0]?.userGridId,
            })
          );
          localStorage.setItem(
            `selectedConfigId_${paymentFundGrids[0]?.gridName}`,
            JSON.stringify(filteredVal[0]?.userGridId)
          );
          setShortcutName('');
        }
      }
    }
  }, [paymentFundGrids]);

  useEffect(() => {
    if (shortcutName != '') {
      if (standingInstructiongrids) {
        if (standingInstructiongrids[0].configGroup.length > 0) {
          const filteredVal = standingInstructiongrids[0].configGroup.filter(
            (item) => item.columnConfigName == shortcutName
          );
          dispatch(
            updateSelectedConfigId({
              ...selectedConfigId,
              ['standingInst']: filteredVal[0]?.userGridId,
            })
          );
          localStorage.setItem(
            `selectedConfigId_${standingInstructiongrids[0]?.gridName}`,
            JSON.stringify(filteredVal[0]?.userGridId)
          );
          setShortcutName('');
        }
      }
    }
  }, [standingInstructiongrids]);


  useEffect(() => {
    if (shortcutName != '') {
      if (paymentGrids) {
        if (paymentGrids[0].configGroup.length > 0) {
          const filteredVal = paymentGrids[0].configGroup.filter(
            (item) => item.columnConfigName == shortcutName
          );
          dispatch(
            updateSelectedConfigId({
              ...selectedConfigId,
              ['paymentTwo']: filteredVal[0]?.userGridId,
            })
          );
          localStorage.setItem(
            `selectedConfigId_${paymentGrids[0]?.gridName}`,
            JSON.stringify(filteredVal[0]?.userGridId)
          );
          setShortcutName('');
        }
      }
    }
  }, [paymentGrids]);

  useEffect(() => {
    if (shortcutName != '') {
      if (userGrids) {
        if (userGrids[0].configGroup.length > 0) {
          const filteredVal = userGrids[0].configGroup.filter(
            (item) => item.columnConfigName == shortcutName
          );
          dispatch(
            updateSelectedConfigId({
              ...selectedConfigId,
              ['userDetails']: filteredVal[0]?.userGridId,
            })
          );
          localStorage.setItem(
            `selectedConfigId_${userGrids[0]?.gridName}`,
            JSON.stringify(filteredVal[0]?.userGridId)
          );
          setShortcutName('');
        }
      }
    }
  }, [userGrids]);

  useEffect(() => {
    if (shortcutName != '') {
      if (instructionsGrids) {
        if (instructionsGrids[0].configGroup.length > 0) {
          const filteredVal = instructionsGrids[0].configGroup.filter(
            (item) => item.columnConfigName == shortcutName
          );
          dispatch(
            updateSelectedConfigId({
              ...selectedConfigId,
              ['instDashboard']: filteredVal[0]?.userGridId,
            })
          );
          localStorage.setItem(
            `selectedConfigId_INSTRUCTION_DASHBOARD`,
            JSON.stringify(filteredVal[0]?.userGridId)
          );
          setShortcutName('');
        }
      }
    }
  }, [instructionsGrids]);

  useEffect(() => {
    if (shortcutName != '') {
      if (registrationMasterGrid) {
        if (registrationMasterGrid[0].configGroup.length > 0) {
          const filteredVal = registrationMasterGrid[0].configGroup.filter(
            (item) => item.columnConfigName == shortcutName
          );
          dispatch(
            updateSelectedConfigId({
              ...selectedConfigId,
              ['adminDashboard']: filteredVal[0]?.userGridId,
            })
          );
          localStorage.setItem(
            `selectedConfigId_${registrationMasterGrid[0]?.gridName}`,
            JSON.stringify(filteredVal[0]?.userGridId)
          );
          setShortcutName('');
        }
      }
    }
  }, [registrationMasterGrid]);
  const [shortcutName, setShortcutName] = useState('');

  const [shortcutType, setShortcutType] = useState('private');
  const [saveFilterSettings, setSaveFilterSettings] = useState('yes');

  const [shortcuts, setShortcuts] = useState([]);

  const getFilterVisibilityState = (state) => {
    if (state) {
      switch (typeof state) {
        case 'string':
          return state.length > 0 ? state.trim() : '';
        case 'number':
          return state !== 0 ? state.toString() : '';
        case 'object':
          return state?.length > 0
            ? state?.join?.(',')
            : state?.from
            ? `From: ${state.from} | To: ${state?.to || ' '}`
            : '';
        default:
          return '';
      }
    } else {
      return '';
    }
  };

  useEffect(() => {
    const s = Array.from(
      new Set(
        JSON.parse(
          window.localStorage.getItem(
            `${props.columnSettingsPersistKey}/config`
          )
        )?.map?.((i) => ({
          key: i,
          value: JSON.parse(window.localStorage.getItem(i))?.name,
          type: JSON.parse(window.localStorage.getItem(i))?.type,
        }))
      )
    );

    if (s) {
      setShortcuts(s);
    }
  }, [window.localStorage]);
  useEffect(() => {
    dispatch(
      updateSelectedConfigId({
        ...selectedConfigId,
        //  [props.uniqueFilterKey]: null,
      })
    );
  }, []);
  const _removeShortcut = () => {
    window.localStorage.removeItem(removeShortcut.key);

    const localShortcuts = window.localStorage.getItem(
      `${props.columnSettingsPersistKey}/config`
    );

    window.localStorage.setItem(
      `${props.columnSettingsPersistKey}/config`,
      JSON.stringify(
        JSON.parse(localShortcuts).filter((k) => k !== removeShortcut.key)
      )
    );

    setShortcuts(shortcuts.filter((i) => i.key !== removeShortcut.key));

    setRemoveShortcut(null);

    navigate(location.pathname);
  };

  useEffect(() => {
    if (editConfig) {
      const editConfigValue = props?.configViews?.find?.(
        (c) => c.userGridId == selectedConfigId?.[props?.uniqueFilterKey || '']
      );

      setShortcutName(editConfigValue?.columnConfigName || '');
      setShortcutType(editConfigValue?.type || 'private');
    } else {
      setShortcutName('');
      setShortcutType('private');
    }
  }, [editConfig]);

  const _validateShortcutName = () => {
    const editConfigValue = props?.configViews?.find?.(
      (c) => c.userGridId === selectedConfigId?.[props?.uniqueFilterKey || '']
    );

    if (editConfig) {
      return props?.configViews
        ?.filter?.(
          (c) =>
            c?.type?.toLowerCase() === shortcutType &&
            editConfigValue?.columnConfigName !== c?.columnConfigName
        )
        ?.find?.(
          (s) =>
            s?.columnConfigName?.toLowerCase?.() ===
            shortcutName?.toLowerCase?.()
        )?.columnConfigName;
    }
    return props?.configViews
      ?.filter?.((c) => c?.type?.toLowerCase() === shortcutType)
      ?.find?.(
        (s) =>
          s?.columnConfigName?.toLowerCase?.() === shortcutName?.toLowerCase?.()
      )?.columnConfigName;
  };

  return (
    <>
      <Modal show={removeShortcut !== null} size='lg'>
        <Modal.Body className='p-2 d-flex flex-column justify-content-center align-items-center'>
          <p>
            Are you sure to remove the shortcut '{removeShortcut?.value || ''}'?
          </p>

          <div className='d-flex justify-content-center align-items-center mt-3'>
            <Button
              size='sm'
              variant='falcon-primary'
              className='me-2'
              onClick={() => setRemoveShortcut(null)}
            >
              No
            </Button>
            <Button
              size='sm'
              variant='falcon-danger'
              onClick={() => _removeShortcut()}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={props.show} size={'lg'} {...props}>
        <Modal.Header>
          <div className='d-flex justify-content-between align-items-center container-fluid'>
            <Modal.Title id='column-visibility-settings-modal-title-vcenter pb-1'>
              <FontAwesomeIcon icon={faGear} className='me-1 fs-0' />
              <span className='fs-0'>Column</span>
            </Modal.Title>

            <div className='d-flex justify-content-end align-items-center'>
              <FontAwesomeIcon
                icon={faRedo}
                className='fs--1 me-3 cursor-pointer'
                onClick={() => props?.onResetSettings?.()}
              />

              <FontAwesomeIcon
                icon={faTimes}
                className='fs-1 cursor-pointer'
                onClick={() => props?.onHide?.()}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className={'col-6 overflow-auto vh-75'}>
              {props.cols.map((i, index) => (
                <div key={index}>
                  {props?.columns?.find?.((v) => v?.accessor === i)?.Header &&
                  !props?.columns?.find?.((v) => v?.accessor === i)?.hide ? (
                    <div
                      className={`d-flex justify-content-between mb-2 fs-0 ${
                        props?.columns?.find?.((v) => v?.accessor === i)?.fixed
                          ? ''
                          : 'cursor-pointer'
                      } m-0`}
                    >
                      <>
                        <div>
                          {' '}
                          <FontAwesomeIcon
                            icon={
                              props.hiddenCols.includes(i)
                                ? faCircle
                                : faCheckCircle
                            }
                            className={`me-2 ${
                              props?.columns?.find?.((v) => v?.accessor === i)
                                ?.fixed
                                ? 'odex-text-grey-light'
                                : 'text-primary'
                            }`}
                            onClick={onSetHiddenCols(
                              i,
                              !props?.columns?.find?.((v) => v?.accessor === i)
                                ?.fixed
                            )}
                          />
                          <span className='fs--1 fw-bold'>
                            {props?.columns?.find?.((v) => v?.accessor === i)
                              ?.isIconHeader
                              ? (
                                  <>
                                    {
                                      props?.columns?.find?.(
                                        (v) => v?.accessor === i
                                      )?.Header
                                    }
                                  </>
                                ) || ''
                              : t(
                                  props?.columns?.find?.(
                                    (v) => v?.accessor === i
                                  )?.Header || ''
                                )}
                          </span>
                        </div>

                        {getFilterVisibilityState(
                          props?.filterState?.[props?.uniqueFilterKey]?.[i]
                        ) && (
                          <FontAwesomeIcon
                            icon={faFilter}
                            className={'text-primary ms-3'}
                            size='xs'
                            data-toggle='tooltip'
                            title={getFilterVisibilityState(
                              props?.filterState?.[props?.uniqueFilterKey]?.[i]
                            )}
                          />
                        )}

                        {(Array.isArray(sortParams) && i == sortParams[0]) || (i == props?.sortState?.key) ? (
                          <FontAwesomeIcon
                            icon={
                              sortParams[1] ? (sortParams[1] == 'asc' ? faCaretUp : faCaretDown) : (props?.sortState?.value == 'asc' ? faCaretUp : faCaretDown)
                            }
                            className={'text-primary ms-3'}
                            size='xs'
                            // data-toggle='tooltip'
                            // title={getFilterVisibilityState(
                            //   props?.filterState?.[props?.uniqueFilterKey]?.[i]
                            // )}
                          />
                        ) : (
                          ''
                        )}
                        <div className='d-flex justify-content-center align-items-center'>
                          <Button
                            variant='falcon-primary'
                            size='sm'
                            className='me-3'
                            onClick={() => {
                              const columns = swapIndex(
                                props.cols,
                                index,
                                index - 1
                              );
                              props.setColumns(columns);
                            }}
                            onDoubleClick={() => {
                              const columns = swapIndex(props.cols, index, 0);
                              props.setColumns(columns);
                            }}
                            disabled={
                              index === 0 ||
                              props?.columns?.find?.((v) => v?.accessor === i)
                                ?.fixed
                            }
                          >
                            <FontAwesomeIcon icon={faArrowUp} />
                          </Button>

                          <Button
                            variant='falcon-primary'
                            size='sm'
                            disabled={
                              props.cols.length - 1 === index ||
                              props?.columns?.find?.((v) => v?.accessor === i)
                                ?.fixed
                            }
                            onClick={() => {
                              const columns = swapIndex(
                                props.cols,
                                index,
                                index + 1
                              );
                              props.setColumns(columns);
                            }}
                            onDoubleClick={() => {
                              const columns = swapIndex(
                                props.cols,
                                index,
                                props.cols.length - 1
                              );
                              props.setColumns(columns);
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowDown} />
                          </Button>
                        </div>
                      </>
                    </div>
                  ) : null}
                </div>
              ))}
              {/* </ListGroup> */}
            </div>
            <div className={'col-6 p-2'}>
              {showConfigForm || editConfig ? (
                <div className='container'>
                  <h1 className='fs-0  text-center'>
                    {' '}
                    {editConfig ? t('resource_1:edit') : 'New View'}
                  </h1>
                  <div className='row mt-3'>
                    <div className='col-lg-12'>
                      <Form.Group id='createConfig.name'>
                        <Form.Control
                          placeholder='Enter shortcut name'
                          size='sm'
                          value={shortcutName}
                          disabled={editConfig}
                          onChange={(e) => setShortcutName(e.target.value)}
                          className={
                            _validateShortcutName() && !editConfig
                              ? 'border-danger'
                              : ''
                          }
                        />
                      </Form.Group>

                      {_validateShortcutName() && !editConfig && (
                        <span className='fs--1 text-danger'>
                          Name is already taken.
                        </span>
                      )}

                      <Form.Group id='createConfig.type' className='mt-2'>
                        <Form.Label>Type</Form.Label>
                        <div>
                          {rbac.rbacDetails.gridPrivate === 'Y' ? (
                            <Form.Check
                              inline
                              size='sm'
                              label='Private'
                              name='type'
                              type='radio'
                              id='createConfig.type.private'
                              checked={shortcutType === 'private'}
                              onClick={() => setShortcutType('private')}
                            />
                          ) : (
                            ''
                          )}

                          {rbac.rbacDetails.gridShared === 'Y' ? (
                            <Form.Check
                              inline
                              size='sm'
                              label='Shared'
                              name='type'
                              type='radio'
                              id='createConfig.type.shared'
                              checked={shortcutType === 'shared'}
                              onClick={() => setShortcutType('shared')}
                            />
                          ) : (
                            ''
                          )}
                          {rbac.rbacDetails.gridSystem === 'Y' ? (
                            <Form.Check
                              inline
                              size='sm'
                              label='System'
                              name='type'
                              type='radio'
                              id='createConfig.type.system'
                              checked={shortcutType === 'system'}
                              onClick={() => setShortcutType('system')}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </Form.Group>
                    </div>
                    <div className='col-lg-8 mt-3'>
                      <div className='row'>
                        <div className='col-6'>
                          <Button
                            className='w-100 col-6'
                            variant='falcon-danger'
                            size='sm'
                            onClick={() => {
                              toggleConfigForm(false);
                              setEditConfig(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} className='me-2' />
                            Cancel
                          </Button>
                        </div>
                        <div className='col-6'>
                          <Button
                            className='w-100'
                            variant='falcon-primary'
                            size='sm'
                            onClick={() => {
                              // dispatch(
                              //   updateSortStateDataTable({
                              //     key: null,
                              //   })
                              // );
                              navigate(location.pathname);
                              setTimeout(() => {
                                setShortcuts([
                                  ...shortcuts,
                                  {
                                    key: `${
                                      props.columnSettingsPersistKey
                                    }/${shortcutName
                                      .split(' ')
                                      .join('-')
                                      .toLowerCase()}`,
                                    value: shortcutName,
                                  },
                                ]);
                                props?.onSaveSettings?.(
                                  {
                                    name: shortcutName,
                                    type: shortcutType,
                                    saveFilter: saveFilterSettings,
                                  },
                                  toggleConfigForm,
                                  editConfig
                                );
                                
                                setTimeout(() => {
                                  setEditConfig(false);
                                }, 1000);
                              }, 100);
                            }}
                            disabled={
                              shortcutName.length <= 0 ||
                              (_validateShortcutName() && !editConfig)
                            }
                          >
                            {isLoadingConfigUpdate ? (
                              <div
                                className='spinner-border spinner-border-sm text-primary'
                                role='status'
                              >
                                <span className='sr-only'>Loading...</span>
                              </div>
                            ) : (
                              <FontAwesomeIcon icon={faSave} className='me-1' />
                            )}
                            {editConfig ? 'Update' : 'Save'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='d-flex justify-content-between align-items-center w-100'>
                    <span className='fs-0 fw-bold'>
                      {t('resource_1:views')}
                    </span>
                    {rbac.rbacDetails.gridPrivate === 'Y' ||
                    rbac.rbacDetails.gridShared === 'Y' ? (
                      <div className='d-flex justify-content-end align-items-center'>
                        <>
                          <Button
                            variant='primary'
                            size='sm'
                            className='btn-falcon-primary'
                            onClick={() => toggleConfigForm(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} className='me-2' />
                            New
                          </Button>

                          <Button
                            variant='falcon-primary'
                            className='ms-2 btn-falcon-primary'
                            size='sm'
                            disabled={
                              !selectedConfigId?.[props?.uniqueFilterKey] ||
                              enableEditRemoveSystem ||
                              enableEditRemoveShared
                            }
                            /*  disabled={
                              !selectedConfigId?.[props?.uniqueFilterKey] 
                             
                            } */
                            onClick={() => setEditConfig(true)}
                          >
                            <FontAwesomeIcon icon={faEdit} className='me-2' />
                            Edit
                          </Button>
                          <Button
                            variant='falcon-danger'
                            className='ms-2 btn-falcon-primary'
                            size='sm'
                            disabled={
                              !selectedConfigId?.[props?.uniqueFilterKey] ||
                              enableEditRemoveSystem ||
                              enableEditRemoveShared
                            }
                            onClick={() => setShowRemoveDialog(true)}
                          >
                            <FontAwesomeIcon icon={faTimes} className='me-2' />
                            Remove
                          </Button>
                        </>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  {showRemoveDialog &&
                    selectedConfigId?.[props?.uniqueFilterKey] && (
                      <div className='mt-3 rounded bg-200 p-2'>
                        <span className='fs--1'>
                          Are you sure to remove selected config?
                        </span>

                        <div className=' mt-2 w-100 d-flex justify-content-end align-items-center'>
                          <Button
                            variant='falcon-secondary'
                            size='sm'
                            className='ms-2'
                            onClick={() => {
                              setShowRemoveDialog(false);
                            }}
                          >
                            No
                          </Button>

                          <Button
                            variant='falcon-danger'
                            size='sm'
                            onClick={() => {
                              navigate(`${location.pathname}`);
                              setShowRemoveDialog(false);
                              dispatch(
                                deleteColumnViewSettings({
                                  id: selectedConfigId?.[
                                    props?.uniqueFilterKey
                                  ],
                                  gridName: props?.gridName || '',
                                  linerId:
                                    linerId !== undefined || ''
                                      ? linerId
                                      : profileDetails.linerId,
                                  dispatch,
                                  country: props?.dataCountry || 'US',
                                })
                              );
                              setTimeout(() => {
                                dispatch(
                                  updateSortStateDataTable({
                                    key: null,
                                  })
                                );
                              }, 500);

                              dispatch(
                                updateSelectedConfigId({
                                  ...selectedConfigId,
                                  [props.uniqueFilterKey]: null,
                                })
                              );
                            }}
                          >
                            Yes
                          </Button>
                        </div>
                      </div>
                    )}
                  {/* <hr /> */}

                  <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                    {['system', 'private', 'shared'].map((typ) => (
                      <>
                        <SoftBadge
                          bg={typ === 'private' ? 'primary' : 'success'}
                          className='mb-2 mt-4'
                        >
                          <h1 className='fs--1 fw-bold m-0'>
                            {typ.toUpperCase()}
                          </h1>
                        </SoftBadge>
                        <div className='odex-h-150px w-100 overflow-auto border rounded'>
                          <ListGroup as='ul' variant='flush'>
                            {props?.configViews
                              ?.filter?.((v) => v.type?.toLowerCase() === typ)
                              .map((s) => (
                                <ListGroup.Item
                                  as='li'
                                  variant={
                                    s.userGridId ==
                                    selectedConfigId?.[props.uniqueFilterKey]
                                      ? 'primary'
                                      : 'light'
                                  }
                                  key={s?.userGridId}
                                  className='fs--1 fw-bold cursor-pointer'
                                  onClick={() => {
                                    localStorage.setItem(
                                      `selectedConfigId_${props.gridName}`,
                                      JSON.stringify(s.userGridId)
                                    );

                                    dispatch(
                                      UpdateEnableEditRemoveSystem(
                                        s.type === 'system' &&
                                          rbac.rbacDetails.gridSystem !== 'Y'
                                          ? true
                                          : false
                                      )
                                    );
                                    dispatch(
                                      UpdateEnableEditRemoveShared(
                                        s.type === 'shared' &&
                                          rbac.rbacDetails.gridShared !== 'Y'
                                          ? true
                                          : false
                                      )
                                    );
                                    if (
                                      selectedConfigId?.[
                                        props.uniqueFilterKey
                                      ] === s?.userGridId
                                    ) {
                                      dispatch(
                                        updateSelectedConfigId({
                                          ...selectedConfigId,
                                          [props.uniqueFilterKey]: null,
                                        })
                                      );
                                    } else {
                                      const newdata =
                                        location.pathname.includes(
                                          'instruction'
                                        )
                                          ? instructionsGrids[0]?.configGroup?.find(
                                              (c) =>
                                                c.userGridId == s.userGridId
                                            )?.columnConfigGrid
                                          : location.pathname.includes('paymentDashboard')
                                          ? paymentGrids[0]?.configGroup?.find(
                                            (c)=> c.userGridId == s.userGridId
                                            )?.columnConfigGrid
                                          : location.pathname.includes(
                                              'payment'
                                            )
                                          ? invoiceGrids[0]?.configGroup?.find(
                                              (c) =>
                                                c.userGridId == s.userGridId
                                            )?.columnConfigGrid
                                          : location.pathname.includes(
                                              'report'
                                            )
                                          ? reportGrids[0]?.configGroup?.find(
                                              (c) =>
                                                c.userGridId == s.userGridId
                                            )?.columnConfigGrid
                                          : location.pathname.includes('users')
                                          ? userGrids[0]?.configGroup?.find(
                                              (c) =>
                                                c.userGridId == s.userGridId
                                            )?.columnConfigGrid
                                          : location.pathname.includes(
                                              'standing'
                                            )
                                          ? standingInstructiongrids[0]?.configGroup?.find(
                                              (c) =>
                                                c.userGridId == s.userGridId
                                            )?.columnConfigGrid
                                          : location.pathname.includes(
                                              'registrationmaster'
                                            )
                                          ? registrationMasterGrid[0]?.configGroup?.find(
                                              (c) =>
                                                c.userGridId == s.userGridId
                                            )?.columnConfigGrid
                                          : grids[0]?.configGroup?.find(
                                              (c) =>
                                                c.userGridId == s.userGridId
                                            )?.columnConfigGrid;

                                      if (newdata) {
                                        if (JSON.parse(newdata)[0].sorts) {
                                          const input = `${
                                            JSON.parse(newdata)[0].sorts
                                          }`;
                                          const [firstWord, lastWord] =
                                            input.match(/\w+/g) || [];
                                          dispatch(
                                            updateSortStateDataTable({
                                              key: firstWord,
                                              value: lastWord,
                                            })
                                          );
                                          dispatch(
                                            updateParams({
                                              key: firstWord,
                                              value: lastWord,
                                            })
                                          );
                                          const queryParams = queryString.parse(
                                            location.search
                                          );
                                          const sortString = {
                                            sort: `${firstWord},${lastWord}`,
                                            seek: undefined,
                                            direction: undefined,
                                          };

                                          navigate(
                                            `${
                                              location.pathname
                                            }?${queryString.stringify({
                                              ...queryParams,
                                              ...sortString,
                                            })}`
                                          );
                                        } else {
                                          dispatch(
                                            updateSortStateDataTable({
                                              key: null,
                                            })
                                          );
                                          navigate(`${location.pathname}`);
                                        }
                                      }
                                      dispatch(
                                        updateSelectedConfigId({
                                          ...selectedConfigId,
                                          [props.uniqueFilterKey]:
                                            s?.userGridId,
                                        })
                                      );
                                    }
                                  }}
                                >
                                  {s?.columnConfigName || ''}
                                </ListGroup.Item>
                              ))}
                          </ListGroup>
                        </div>
                      </>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ColumnVisibilitySettings;
